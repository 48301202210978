// Homepage style

.home-main{
    // background: url(../assets/images/home-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover !important;
    min-height: 100vh;
    display: flex;
    position: relative;
    flex-direction: column;
}
.home-wrp{
    display: flex;
    flex: 1 1 0%;
    // background: rgba(0, 0, 0, 0.5);
    background-size: cover;
    min-height: 100vh;
}
.home-content{
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    flex-direction: column;
    @media only screen and (max-width: 767px) {
        justify-content: start;
    }
    .login-btn{
        position: absolute;
        right: 30px;
        top: 30px;
        @media only screen and (max-width: 767px) {
            position: relative;
            right: auto;
            top: auto;
        }
    }
}
.login-btn{
    @media only screen and (max-width: 767px) {
        // position: inherit;
        margin: 20px;
        margin-left: auto;
    }
    .primary-btn{
        // font-size: 19px;
        &:hover{
            background-color: var(--theme-primary-color);
            color: $theme-white-color;
            svg{
                fill: $theme-white-color;
            }
        }
        @media only screen and (max-width: 767px) {
            font-size: 14px;
            font-weight: 500;
            padding: 8px 15px;
            svg{
                width: 15px;
                height: 16px;
            }
        }
        &.only-svg{
            height: 50px;
            width: 50px;
            @include border-radius(100%);
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            @media only screen and (max-width: 767px) {
                height: 38px;
                width: 38px;
            }
            svg{
                margin: 0;
            }
        }
    }
}
.shop-page-header{
    .login-btn{
        @media only screen and (max-width: 767px) {
            margin: 0px;
        }
    }
    .shop-profile-img{
        .primary-btn{
            @media only screen and (max-width: 1023px) {
                @include border-radius(20px);
                padding: 10px 10px;
                min-width: 68px;
            }
            span{
                @media only screen and (max-width: 1023px) {
                    display: none;
                }
            }
        }
    }
}
.home-content-main{
    background-color: $theme-white-bg-color;
    @include border-radius(53px);
    text-align: center;
    min-width: 502px;
    margin-top: 20px;
    margin-bottom: 20px;
    @media only screen and (max-width: 767px) {
        margin-top: 0;
        @include border-radius(40px);
        min-width: 345px;
        max-width: 345px;
        // max-height: 400px;
        // overflow-y: auto;
        // overflow-x: hidden;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
            width: 0;
        }
    }
    &.delivery-add-section{
        text-align: left;
    }
}
.home-title{
    border-bottom: 2px solid $theme-border-color;
    padding: 60px 80px 40px;
    @media screen and (max-width:1601px){
        padding: 30px 30px 20px;
    }
    h4{
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 10px;
    }
    h1{
        color: $theme-black-color;
        font-size: 65px;
        font-weight: 500;
        margin-bottom: 0;
        @media screen and (max-width:1601px){
            font-size: 30px;
        }
    }
}
.home-cont-mid{
    padding: 40px;
    display: flex;
    justify-content: center;
    @media screen and (max-width:1601px){
        padding: 20px;
    }
    .primary-btn{
        margin: auto;
        margin-bottom: 10px;
        min-width: 306px;
        font-size: 20px;
        min-height: 71px;
        @include border-radius(25px);
        @media screen and (max-width:767px){
            font-size: 18px;
        }
        &.secondary-btn{
            // margin-bottom: 0;
        }
    }
    a{
        margin-top: 35px;
        display: inline-block;
        text-decoration: underline;
        font-size: 20px;
        font-weight: 600;
        @media screen and (max-width:1601px){
            margin-top: 6px;
        }
        @media screen and (max-width:767px){
            font-size: 16px;
        }
    }
}
.home-cont-footer{
    border-top: 2px solid $theme-border-color;
    padding: 17px 20px;
    text-align: center;
}
.h-c-footer-img{
    span{
        color: var(--theme-secondary-color);
        font-size: 12px;
        font-weight: 600;
        svg{
            fill: var(--theme-primary-color);
            margin-left: 5px;
        }
    }
}
.delivery-area-link{
    a{
        display: inline-block;
        color: $theme-white-color;
        font-size: 16px;
        font-weight: 500;
        text-decoration: underline;
        margin-top: 25px;
        margin-bottom: 25px;
    }
}
.home-footer{
    background-color: $theme-white-bg-color;
    padding: 14px 30px;
    // position: sticky;
    // top: 100%;
    // left: 0;
    width: 100%;
    @media screen and (max-width:767px){
        padding: 14px 20px;
    }
}
.email-btn{
    .primary-btn.secondary-btn{
        font-size: 16px;
        font-weight: 500;
        padding: 6px 15px;
        @media screen and (max-width:767px){
            font-size: 14px;
            padding: 6px 5px;
        }
        span{
            height: 28px;
            width: 28px;
            @include border-radius(100%);
            text-align: center;
            background-color: var(--theme-primary-color);
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            @media screen and (max-width:767px){
                height: 16px;
                width: 16px;
                margin-right: 5px;
                svg{
                    width: 10px;
                    height: 10px;
                }
            }
            svg{
                fill: $theme-white-color;
                margin: 0;
            }
        }
        &:hover{
            background-color: var(--theme-third-color);
            color: var(--theme-primary-color);
            // span{
            //     background-color: var(--theme-third-color);
            //     svg{
            //         fill: var(--theme-primary-color);
            //     }
            // }
        }
    }
}
.open-time-btn{
    position: absolute;
    bottom: 94px;
    right: 17px;
    z-index: 9999;
    // &:hover{
    //     .timetable-list{
    //         display: inline-flex !important;
    //     }
    // }
    @media only screen and (max-width: 767px) {
        top: 20px;
        left: 20px;
        bottom: auto;
        right: auto;
        display: flex;
        flex-direction: column;
    }
}
.open-t-b-w{
    background-color: $theme-white-bg-color;
    @include border-radius(20px);
    padding: 7px 15px;
    cursor: pointer;
    max-width: 250px;
    @media only screen and (max-width: 767px) {
        margin-right: auto !important;
        margin-left: 0 !important;
    }
    .open-heading{
        margin-left: 10px;
        font-size: 16px;
        font-weight: 700;
        margin-right: 20px;
        color: $theme-black-color;
        @media only screen and (max-width: 767px) {
            margin-right: 10px;
        }
        &.red{
            color: var(--theme-primary-color);
        }
    }
}
.light-btn{
    height: 15px;
    width: 15px;
    background-color: $theme-green-color;
    @include border-radius(100%);
    display: flex;
    &.red{
        background-color: $theme-red-color;
    }
}
.time-list{
    max-width: 125px;
    text-align: right;

    span{
        font-size: 14px;
        color: $theme-gray-color1;
        font-weight: 500;
    }
}
.open-t-b-w{
    .time-list{
        @media only screen and (max-width: 767px) {
            display: none;
        }
    }
}
.timetable-list{
    background-color: $theme-white-bg-color;
    @include border-radius(20px);
    width: 270px;
    flex-direction: column;
    margin-bottom: 15px;
    box-shadow: 0px 4px 44px -10px rgba(0, 0, 0, 0.15);
    // display: none !important;
    @media only screen and (max-width: 767px) {
        order: 2;
        margin-bottom: 0;
        margin-top: 15px;
    }
    ul{
        margin: 0;
        padding: 0;
    }
    li{
        margin-bottom: 0;
        border-top: 2px solid $theme-border-color;
        padding: 6px 10px 6px 24px;
        min-height: 55px;
        &:first-child{
            border-top: none;
        }
        &.active{
            .days-name {
                span{
                    color: $theme-black-color;
                }
            }
            .days-time .time-list span{
                color: $theme-black-color;
            }
        }
    }
}
.timetable-close{
    margin: 10px;
    cursor: pointer;
    span{
        background-color: var(--theme-third-color);
        height: 30px;
        width: 30px;
        @include border-radius(100%);
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
            fill: var(--theme-primary-color);
        }
    }
}
.days-name{
    display: flex;
    align-items: center;
    .light-btn{
        margin-left: 10px;
        height: 8px;
        width: 8px;
    }
    span{
        font-size: 16px;
        font-weight: 500;
        color: $theme-gray-color1;
    }
}
.days-time{
    text-align: right;
    .time-list{
        max-width: 145px;
        span{
            font-size: 16px;
            font-weight: 500;
        }
    }
}
.discount-offer{
    background-color: $theme-light-yellow-color;
    padding: 7px 20px;
    text-align: center;
    p{
        color: $theme-yello-color2;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 5px;
        @media screen and (max-width:1601px){
            line-height: 16px;
        }
    }
}
.close-time{
    background-color: var(--theme-third-color);
    padding: 10px 20px;
    text-align: center;
    p{
        color: var(--theme-primary-color);
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 0;
        @media only screen and (max-width: 767px) {
            font-size: 16px;
        }
    }
}
.pre-order-text{
    margin-bottom: 15px;
    color: var(--theme-primary-color);
    font-size: 16px;
    font-weight: 600;
}
.see-menu-title{
    text-align: center;
    max-width: 285px;
    margin: 0 auto;
    span{
        color: var(--theme-primary-color);
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 0;
    }
    h3{
        color: var(--theme-primary-color);
        font-size: 20px;
        font-weight: 600;
    }
    p{
        font-size: 16px;
        font-weight: 500;
        color: $theme-gray-color1;
    }
}
.see-menu-popup{
    .modal-footer{
        .primary-btn{
            min-width: 230px;
            min-height: 60px;
        }
    }
}
.ScopeOfDelivery-popup{
    .modal-content {
        padding: 55px 25px 25px 25px;
    }
    .modal-body-wrp {
        margin-top: 10px;
    }
}
.modal-header{
    h2{
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 10px;
    }
}
.deli-map{
    text-align: center;
    img{
        @include border-radius(34px);
    }
}
.return-btn{
    padding: 30px;
    .primary-btn{
        span{
            padding-top: 1px;
        }
    }
    @media only screen and (max-width: 767px) {
        padding: 20px;
        .primary-btn{
            font-size: 16px;
        }
    }
}
.deli-address{
    padding: 0 30px 30px;
    @media only screen and (max-width: 767px) {
        padding: 0 20px 20px;
    }
    h3{
        font-size: 16px;
        margin-bottom: 10px;
    }
}
.deli-area-link{
    margin-top: 10px;
}
.deli-add-top{
    border-bottom: 2px solid $theme-border-color;
    padding-bottom: 15px;
    .add-control{
        .form-control{
            &.error{
                border: 3px solid $theme-red-color2;
            }
        }
    }
}
.deli-add-bottom{
    margin-top: 20px;
    .toggle-large-btn{
        margin: 0;
    }
    &.click-collect{
        text-align: center;
        .toggle-large-btn{
            margin: 0 auto;
        }
        .hour-time-section{
            justify-content: center;
        }
    }
}


.toggle-large-btn{
    max-width: 336px;
    margin: 0 auto;
}
.t-b-l{
    background-color: var(--theme-third-color);
    @include border-radius(107px);
    padding: 8px;
    .secondary-btn{
        border-color: transparent;
        background-color: transparent;
        // margin-left: 10px;
    }
    .primary-btn{
        padding: 5px 5px;
        width: 100%;
        font-size: 16px;
        @media only screen and (max-width: 767px) {
            font-size: 14px;
        }
        &.first{
            margin-left: 0;
        }
        &.second{
            margin-left: 10px;
        }
        &:hover{
            background-color: var(--theme-primary-color);
            color: $theme-white-color;
        }
    }
}
.order-deli-time{
    text-align: center;
    margin-top: 23px;
    p{
        color: $theme-gray-color2;
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 0;
        span{
            display: block;
            margin-top: 5px;
        }
    }
}
.delivery-add-section{
    .home-cont-footer{
        border: none;
        box-shadow: 0px -6px 44px rgba(0, 0, 0, 0.1);
        padding: 30px;
        @media only screen and (max-width: 767px) {
            padding: 20px;
        }
        @media only screen and (max-width: 1599px) {
            padding: 20px 30px;
        }
        .primary-btn{
            min-height: 83px;
            font-size: 24px;
            width: 100%;
            @include border-radius(25px);
            @media only screen and (max-width: 767px) {
                min-height: auto;
                font-size: 20px;
                @media only screen and (max-width: 1599px) {
                    min-height: 73px;
                }
            }
        }
    }
}
.pre-order-only{
    max-width: 350px;
    p{
        background-color: $theme-red-color4;
        text-align: center;
        padding: 7px 10px;
        line-height: 1.5;
        color: $theme-red-color;
        font-size: 12px;
        font-weight: 500;
        @include border-radius(6px);
        margin-bottom: 0;
        margin-top: 10px;
    }
}
.hour-time-section{
    margin-top: 23px;
}
.date-dropdown{
    label{
        font-size: 14px;
        font-weight: 600;
        color: var(--theme-primary-color);
    }
    &.hour-dropdown{
        margin-left: 20px;
    }
}
.d-dropdown{
    background-color: $theme-dropdown-bg;
    color: $theme-black-color;
    @include border-radius(10px);
    font-size: 16px;
    padding: 10px 18px;
    @media only screen and (max-width: 767px) {
        font-size: 14px;
    }
    svg{
        stroke: $theme-black-color;
    }
    &:hover{
        background-color: $theme-dropdown-bg;
        color: $theme-black-color;
        svg{
            fill: $theme-dropdown-bg;
        }
    }
    &.active{
        svg{
            transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
        }
    }
}
.d-wrp{
    // background-color: $theme-gray-color3;
    @include border-radius(10px);
    position: relative;
    .primary-btn{
        &.active{
            @include border-radius(10px 10px 0 0);
        }
    }
}
.dropdown-menu-list{
    padding: 0;
    margin: 0;
    text-align: center;
    background-color: $theme-gray-color3;
    @include border-radius(0 0 10px 10px);
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 9;
    animation: growDown 300ms ease-in-out forwards;
    transform-origin: top center;
    li{
        border-top: 2px solid $theme-border-color2;
        padding: 7px 20px;
        margin-bottom: 0;
        font-size: 16px;
        font-weight: 500;
        color: $theme-black-color;
        cursor: pointer;
        @media only screen and (max-width: 767px) {
            font-size: 14px;
        }
        &:first-child{
            border-top: none;
        }
    }
}
.add-control{
    position: relative;
    .form-control{
        padding-right: 60px;
        &.active{
            @include border-radius(18px 18px 0 0);
            &:focus, &:active{
                box-shadow: none;
                border: none;
                background-color: $theme-form-field-bg;
            }
        }

    }
    .form-control-svg{
        position: absolute;
        top: 50%;
        right: 26px;
        margin-top: -13px;
        cursor: pointer;
        svg{
            stroke: $theme-black-color;
            @media only screen and (max-width: 1023px) {
                height: 10px;
                width: 20px;
            }
        }
    }
    &.active{
        .form-control-svg{
            svg{
                transform: rotate(180deg);
                -webkit-transform: rotate(180deg);
                -moz-transform: rotate(180deg);
            }
        }
    }
    &.shop-add-control{
        .form-control{
            min-width: 314px;
            padding-left: 40px;
            // @include border-radius(30px);
            min-height: 51px;
            &.error{
                background-color: rgba(255, 3, 3, 0.1);
                color: $theme-red-color;
            }
        }
        .form-control-svg{
            &.location{
                right: auto;
                left: 20px;
                svg{
                    transform: rotate(0deg);
                    -webkit-transform: rotate(0deg);
                    -moz-transform: rotate(0deg);
                }
            }
        }
        &.error{
            .form-control-svg{
                svg{
                    // fill: var(--theme-primary-color);
                    stroke: $theme-red-color;
                }
                &.location{
                    svg{
                        fill: $theme-red-color;
                    }
                }
            }
        }
        // &.active{
        //     .form-control{
        //         @include border-radius(30px 30px 0 0);
        //     }
        // }
    }
}
.e-s-p-link{
    margin-top: 15px;
}
.valider-btn{
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    .primary-btn{
        min-width: 185px;
    }
}
.notdeliver-popup{
    .see-menu-title{
        max-width: 100%;
        border-bottom: 2px solid $theme-border-color;
    }
    .popup-footer-btn{
        .primary-btn{
            min-height: 60px;
            min-width: 321px;
        }
    }
}
.send-address{
    margin-top: 15px;
    text-align: center;
    p{
        font-size: 16px;
        font-weight: 600;
        color: var(--theme-primary-color);
        max-width: 257px;
        margin: 0 auto 20px;
        line-height: 19px;
    }
    .form-control{
        max-width: 321px;
        margin: 0 auto;
    }
}


// Account popup style

.form-label{
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    color: var(--theme-primary-color);
    display: block;
    @media only screen and (max-width: 1279px) {
        font-size: 18px;
    }
}
.cart-content-wrp-p{
    background-color: $theme-white-bg-color;
    @include border-radius(50px);
    .add-edit-section{
        text-align: center;
    }
}
.login-form{
    .form-label{
        font-size: 16px;
        font-weight: 500;
        @media only screen and (max-width: 767px) {
            font-size: 16px;
        }
    }
    .form-control{
        // font-weight: bold;
        font-size: 16px;
        @media only screen and (max-width: 767px) {
            font-size: 14px;
        }
        &::-webkit-input-placeholder{
            color: $theme-gray-color4;
        }
        &::-moz-placeholder{
            color: $theme-gray-color4;
        }
        &::-ms-input-placeholder{
            color: $theme-gray-color4;
        }
        &.error{
            background-color: $theme-alert-bg;
            color: $theme-red-color;
                border: 2px solid $theme-red-color2;
            &::-webkit-input-placeholder{
                color: rgba(255, 191, 191, 1);
            }
            &::-moz-placeholder{
                color: rgba(255, 191, 191, 1);
            }
            &::-ms-input-placeholder{
                color: rgba(255, 191, 191, 1);
            }
        }
    }
    .red-color{
        .pass-show-icon{
            margin-top: -19px;
            @media screen and (max-width:1279px){
                margin-top: -13px;
            }
        }
    }
}
.account-popup{
    max-width: 550px;
    .modal-content{
        // padding: 30px 60px;
        padding: 0;
        @media screen and (max-width:767px){
            // padding: 20px 20px;
            padding: 20px;
        }
    }
    .modal-body-wrp{
        margin-top: 0;
    }
    .toggle-large-btn{
        margin: 20px auto 0;
    }
    .t-l-b-wrp{
        padding: 80px 20px 0;
        // border-bottom: 1px solid $theme-border-color;
        @media screen and (max-width:767px){
            padding: 30px 0 0;
        }
    }
    .l-f-wrp{
        padding: 30px 30px;
        @media screen and (max-width:767px){
            padding: 20px 0px;
        }
    }
    .social-text{
        margin-top: 10px;
    }
}
.a-p-login-btn{
    border-bottom: 2px solid $theme-border-color;
    padding-bottom: 20px;
    margin-bottom: 20px;
    .primary-btn{
        min-width: 306px;
        font-size: 20px;
        min-height: 60px;
        &.disabled{
            background-color: #D1D1D1;
            color: $theme-white-color;
            cursor: not-allowed;
            pointer-events: none;
        }
        @media screen and (max-width:767px){
            max-width: 100%;
            font-size: 18px;
            min-height: auto;
        }
    }
}
.telephone{
    position: relative;
    .phone-code{
        // position: absolute;
        // top: 46px;
        // left: 36px;
        // border-right: 1px solid $theme-gray-color4;
        font-weight: 600;

        background-color: $theme-form-field-bg;
        min-height: 50px;
        @include border-radius(18px);
        display: flex;
        align-items: center;
        cursor: pointer;
        @media screen and (max-width:1279px){
            // top: 43px;
            min-height: 47px;
            // padding: 5px 20px;
        }
        img{
            margin-left: 10px;
            margin-right: 10px;
            margin-top: -3px;
        }
        svg{
            stroke: $theme-black-color;
            margin-left: auto;
        }
        .d-dropdown{
            background-color: transparent;
            padding: 10px 24px;
            display: flex;
            align-items: center;
            flex: 1;
            @media screen and (max-width:1279px){
                padding: 5px 20px;
            }
        }
        .dropdown-menu-list{
            background-color: #efefef;
            text-align: left;
            li{
                border: none;
                padding: 7px 24px;
                @media screen and (max-width:567px){
                    padding: 7px 7px;
                    text-align: center;
                }
            }
        }
        &.active{
            @include border-radius(18px 18px 0 0);
            svg{
                -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
            }
        }
    }
    .form-label{
        opacity: 0;
    }
    .form-control{
        padding-left: 70px;
    }
}
.pass-show-icon{
    position: absolute;
	right: 30px;
	top: 50%;
    margin-top: -3px;
    @media only screen and (max-width: 1279px) {
        margin-top: 2px;
    }
    span{
        height: 38px;
        width: 38px;
        display: block;
        background-color: transparent;
        @include border-radius(100%);
        padding: 5px;
        // margin-top: -5.5px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid rgba(0, 0, 0, 0.05);
        @media only screen and (max-width: 1279px) {
            height: 28px;
            width: 28px;
            padding: 3px;
        }
        svg{
            fill: $theme-black-color;
            height: 22px;
            width: 22px;
        }
        cursor: pointer;
        &.active{
            background-color: var(--theme-primary-color);;
            svg{
                fill: $theme-white-color;
            }
        }
    }

}
.social-box{
    background-color: $theme-white-bg-color;
    @include border-radius(25px);
    box-shadow: 0px 4px 30px -10px rgba(0, 0, 0, 0.15);
    padding: 20px 10px;
    flex-direction: column;
    @media only screen and (max-width: 767px) {
        padding: 10px 10px;
    }
}
.social-text{
    margin-top: 20px;
    @media only screen and (max-width: 767px) {
        margin-top: 10px;
    }
    p{
        font-size: 14px;
        color: $theme-black-color;
        font-weight: 500;
        margin-bottom: 0;
        text-align: center;
    }
}
.social-connect{
    h4{
        text-align: center;
        color: $theme-black-color;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 20px;
    }
    .row-custom{
        margin: 0 -5px;
        @media only screen and (max-width: 767px) {
            margin: 0;
        }
    }
    .col-custom{
        flex: 1;
        margin: 0 5px;
        @media only screen and (max-width: 767px) {
            margin: 0 0 10px 0;
        }
    }
}
.condition-section{
    background-color: $theme-gray-color7;
    @include border-radius(20px);
    padding: 24px;
    margin-bottom: 25px;
    margin-top: 25px;
    border: 2px solid transparent;
    &.error{
        background-color: $theme-alert-bg;
        border-color: $theme-red-color;
        .check_lb{
            color: $theme-red-color;
            a{
                color: $theme-red-color;
            }
        }
    }

}

.forgot-pass {
    text-align: right;
    margin: 15px 0 20px 0;
}
.pay-text{
    text-align: center;
    max-width: 380px;
    margin: 0 auto;
    h3{
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 0;
    }
    span{
        font-size: 12px;
        font-weight: 300;
        color: var(--theme-primary-color);
    }
}
.col-custom{
    margin: 0 10px;
    cursor: pointer;
    width: 100%;
    @media only screen and (max-width: 1279px) {
        margin: 0 5px;
    }
}
.row-custom{
    margin: 0 -10px;
    @media only screen and (max-width: 1279px) {
        margin: 0 -5px;
    }
}
@media only screen and (max-width: 767px) {
    .row-custom{
        flex-direction: column;
        .col-custom{
            margin-bottom: 10px;
            width: 100%;
        }
    }
}



// Shop page style

.page-content-wrp{
    margin-bottom: 71px;
}

.shop-header-main{
    position: relative;
    // top: 0;
    width: 100%;
    // z-index: 999;
    background-color: $theme-white-bg-color;
}
.product-item-list-main{
    // margin-top: 195px;
}
.shop-page{
    .shop-page-header{
        background-color: var(--theme-third-color);
    }
}
.shop-page-header{
    background-color: $theme-white-bg-color;
    padding: 19px 60px;
    @media only screen and (max-width: 1279px) {
        padding: 19px 20px;
    }
    @media only screen and (max-width: 767px) {
        padding: 10px 20px;
    }
}

.shop-profile-img{
    img{
        width: 50px;
        height: 50px;
        @include border-radius(100%);
        border: 5px solid $theme-white-color;
        display: inline-block;
        cursor: pointer;
    }
}
.delivery-options-list{
    background-color: $theme-white-color;
    @include border-radius(41px);
    margin-left: 68px;
    margin-right: 68px;
    position: relative;
    border: 3px solid transparent;
    transition: all .3s ease-in-out;
    @media only screen and (max-width: 1279px) {
        margin-left: 20px;
        margin-right: 20px;
        // max-width: 70%;
    }
    @media only screen and (max-width: 1023px) {
        position: inherit;
    }
    @media only screen and (max-width: 567px) {
        margin-left: 10px;
        margin-right: 10px;
    }
    &.active{
        border-color: var(--theme-secondary-color);
        -ms-transform: scale(1.05); /* IE 9 */
        -webkit-transform: scale(1.05); /* Safari 3-8 */
        transform: scale(1.05);
    }
}
// .modal-backdrop{
//     &.modal-backdrop-custom{
//         z-index: 999;
//     }
// }
.deli-optiond-wrp{
    padding: 12px 20px;
    cursor: pointer;
    min-height: 51px;
    @media only screen and (max-width: 1279px) {
        padding: 14px 10px;
    }
    @media only screen and (max-width: 1023px) {
        min-height: auto;
    }
    @media only screen and (max-width: 567px) {
        padding: 6px 3px;
    }
    ul{
        padding: 0;
        margin: 0;
    }
    li{
        margin: 0;
        border-left: 2px solid $theme-form-field-bg;
        padding: 0 15px;
        @media only screen and (max-width: 1279px) {
            padding: 0px 10px;
        }
        @media only screen and (max-width: 1023px) {
            padding: 0px 13px;
        }
        @media only screen and (max-width: 567px) {
            padding: 0px 8px;
        }
        &:first-child{
            border: none;
        }
        svg{
            fill: var(--theme-secondary-color);
            margin-right: 15px;
            @media only screen and (max-width: 1279px) {
                margin-right: 10px;
            }
            @media only screen and (max-width: 1023px) {
                margin-right: 0;
            }
        }
        span{
            color: var(--theme-primary-color);
            font-size: 16px;
            font-weight: 600;
            @media only screen and (max-width: 1279px) {
                font-size: 14px;
            }
            @media only screen and (max-width: 1023px) {
                display: none;
            }
        }
        &.long-add{
            flex-wrap: wrap;
            white-space: nowrap;
            max-width: 800px;
            overflow: hidden;
            text-overflow: ellipsis;
            @media only screen and (max-width: 1599px) {
                max-width: 600px;
            }
            @media only screen and (max-width: 1499px) {
                max-width: 480px;
            }
            @media only screen and (max-width: 1399px) {
                max-width: 350px;
            }
        }
    }
}
.deli-list-dropdown{
    background-color: $theme-white-bg-color;
    @include border-radius(30px);
    padding: 30px;
    position: absolute;
    top: 100%;
    left: 0;
    // width: 905px;
    margin-top: 15px;
    box-shadow: 0px 2px 60px -10px rgba(0, 0, 0, 0.15);
    z-index: 9999;
    animation: growDown 300ms ease-in-out forwards;
  transform-origin: top center;
    @media only screen and (max-width: 1023px) {
        position: fixed;
        top: 0;
        margin-top: 0;
        @include border-radius(0);
        width: 100%;
        height: 100vh;
        padding: 20px;
        // overflow: hidden;
        overflow-y: auto;
        overflow-x: hidden;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar{
            display: none;
        }
    }
    .close{
        height: 40px;
        width: 40px;
        background-color: var(--theme-third-color);
        border-radius: 100%;
        opacity: 1;
        margin-bottom: 15px;
        @media only screen and (max-width: 1023px) {
            display: none;
        }
        span{
            font-size: 33px;
            font-weight: bold;
            color: var(--theme-primary-color);
            opacity: 1;
            svg{
                fill: var(--theme-primary-color);
            }
        }
    }
    .modal-content{
        display: none;
        @media only screen and (max-width: 1023px) {
            display: block;
        }
        @media only screen and (max-width: 1023px) {
            &.d-l-drop-mob{
                padding: 0;
                .close{
                    display: block;
                    &.with-name{
                        position: inherit;
                        left: auto;
                        right: auto;
                        top: auto;
                        display: flex !important;
                        float: none;
                        justify-content: center;
                        border-bottom: 2px solid $theme-border-color;
                        padding-bottom: 20px;
                        width: 100%;
                    }
                }
            }
        }
    }
    .add-con-dropdown-list{
        position: inherit;
        top: 0;
        max-height: 180px;
    }
}
.d-l-row{
    width: 100%;
    margin: 0 -15px;
    @media only screen and (max-width: 1023px) {
        display: block !important;
        margin: 0;
    }
}
.d-l-block{
    width: auto;
    padding: 0 15px;
    border-left: 2px solid $theme-border-color3;
    @media only screen and (max-width: 1023px) {
        border-left: none;
        border-top: 2px solid $theme-border-color3;
        padding: 20px 10px 0px 10px;
        margin-top: 20px;
        &:first-child{
            border-top: none;
        }
    }
    &:first-child{
        border: none;
        // padding: 0;
    }
    &.d-l-b-mob{
        display: none;
        @media only screen and (max-width: 1023px) {
            display: inline-flex;
            justify-content: center;
            width: 100%;
            border: none;
            .primary-btn{
                background-color: $theme-black-color;
                @include border-radius(20px);
                min-height: 67px;
                width: 100%;
                color: $theme-white-color;
                font-size: 20px;
            }
        }
    }
}
.block-title{
    h3{
        color: $theme-black-color;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 20px;
    }
}
.deli-type-btn{
    .primary-btn{
        background-color: $theme-body-bg;
        color: $theme-black-color;
        @include border-radius(12px);
        font-size: 16px;
        padding: 8px 12px;
        min-width: 230px;
        justify-content: start;
        margin-bottom: 10px;
        @media only screen and (max-width: 1023px) {
            min-width: auto;
            width: 100%;
        }
        svg{
            fill: $theme-black-color;
            margin-right: 10px;
            margin-left: 10px;
        }
        .btn-mark{
            height: 24px;
            width: 24px;
            @include border-radius(100%);
            background-color: $theme-white-bg-color;
            display: flex;
            align-items: center;
            justify-content: center;
            .blue-tick{
                height: 16px;
                width: 16px;
                background-color: $theme-blue-color;
                @include border-radius(100%);
                margin-bottom: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                svg{
                    stroke: $theme-white-color;
                    margin: 0;
                    fill: $theme-blue-color;
                }
            }
        }
        &.active{
            background-color: $theme-blue-bg;
            border-color: $theme-blue-color;
        }
    }
}
.shop-d-h{
    margin-top: 20px;
    .date-dropdown{
        margin-top: 15px;
        &:first-child{
            margin-top: 0;
        }
        label{
            color: $theme-gray-color5;
            font-weight: 500;
            font-size: 14px;
        }
        &.hour-dropdown{
            margin-left: 0;
        }
        .d-dropdown{
            font-weight: 500;
            width: 100%;
            justify-content: start;
            svg{
                margin-left: auto;
            }
        }
    }
}
.input-dropdown{
    position: relative;
}
.add-con-dropdown-list{
    background-color: $theme-dropdown-bg;
    padding: 20px 15px;
    max-height: 230px;
    @include border-radius(25px);
    margin-top: 0px;
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    z-index: 99999;
    // box-shadow: 0px 2px 60px -10px rgba(0, 0, 0, 0.15);
    animation: growDown 300ms ease-in-out forwards;
  transform-origin: top center;
    @media only screen and (max-width: 1279px) {
        top: 47px;
    }
    &.active{
        @include border-radius(0 0 25px 25px);
    }
    h3{
        font-size: 14px;
        font-weight: 600;
        color: $theme-black-color;
        margin-bottom: 15px;
    }
}
.address-form-list-main{
    .form-control{
        background-color: $theme-white-bg-color;
        color: $theme-black-color;
        margin-top: 4px;
        cursor: pointer;
    }
}
.d-alert-msg{
    p{
        margin-bottom: 10px;
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        color: $theme-red-color;
    }
    .black{
        color: $theme-black-color;
    }
    .gray{
        color: #888888;
    }
}
.e-s-p-link{
    .primary-btn.link-btn{
        font-size: 14px;
        font-weight: 500;
        @include border-radius(0);
    }
}
.estimated-delivery{
    background-color: var(--theme-fourth-color);
    text-align: center;
    p{
        color: var(--theme-primary-color);
        padding: 7px 20px;
        font-weight: 600;
        margin-bottom: 0;
        @media only screen and (max-width: 1279px) {
            font-size: 14px;
        }
    }
}
.menu-content-sticky{
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
}
.menu-hori-main{
    background-color: $theme-white-bg-color;
    box-shadow: 0px 20px 20px -10px rgba(0, 0, 0, 0.05);
    position: relative;
    .nav-tabs{
        border: none;
        padding: 0px 50px;
        flex-wrap: nowrap;
        align-items: center;
        // overflow-x: auto;
        // overflow-y: hidden;
        width: calc(100% - 500px);
        overflow: hidden;
        // width: 100%;
        padding-right: 0;
        @media only screen and (max-width: 1599px) {
            // max-width: 60%;
        }
        @media only screen and (max-width: 1279px) {
            padding: 0px 20px;
            // max-width: 50%;
            overflow:hidden;
        }
        @media only screen and (max-width: 1023px) {
            // max-width: 40%;
        }
        @media only screen and (max-width: 767px) {
            // max-width: 55%;
            width: calc(100% - 200px);
        }
        li{
            margin-bottom: 0;
            white-space: nowrap;
        }
        .nav-link{
            border: none;
            font-size: 18px;
            font-weight: 600;
            color: var(--theme-secondary-color);
            position: relative;
            padding: 0.5rem 0;
            margin: 0 22px;
            @media only screen and (max-width: 1279px) {
                font-size: 16px;
                margin: 0 15px;
            }
            @media only screen and (max-width: 767px) {
                margin: 0 10px;
            }
            &.active{
                background: none;
                color: var(--theme-primary-color);
                &::before{
                    display: block;
                }
            }
            &::before{
                content: "";
                position: absolute;
                bottom: -8px;
                left: 0;
                height: 3px;
                width: 100%;
                background-color: var(--theme-primary-color);
                display: none;
                @media only screen and (max-width: 1279px) {
                    bottom: -3px;
                }
            }
        }
    }
}
.menu-plus{
    border-right: 2px solid $theme-border-color;
    padding-right: 25px;
    margin-right: 25px;
    position: relative;
    // margin-left: auto;
    @media only screen and (max-width: 767px) {
        padding-right: 0;
        margin-right: 0;
        border-right: none;
    }
    .primary-btn{
        background-color: $theme-white-bg-color;
        color: var(--theme-primary-color);
        box-shadow: 0px 0px 32px -10px rgba(0, 0, 0, 0.15);
        svg{
            stroke: var(--theme-primary-color);
            fill: $theme-white-color;
        }
        &:hover{
            svg{

            }
        }
    }
    ul{
        margin: 0;
        padding: 0;
        background-color: $theme-white-bg-color;
        box-shadow: 0px 2px 50px -10px rgba(0, 0, 0, 0.1);
        @include border-radius(20px);
        width: 207px;
        max-height: 303px;
        position: absolute;
        right: 20px;
        top: 120%;
        overflow-y: auto;
        overflow-x: hidden;
        z-index: 999;
        animation: growDown 300ms ease-in-out forwards;
        transform-origin: top center;
        li{
            margin-bottom: 0;
            border-top: 2px solid var(--theme-third-color);
            &:first-child{
                border-top: none;
            }
            a{
                font-size: 16px;
                font-weight: 600;
                color: var(--theme-primary-color) !important;
                padding: 11px 15px;
                display: block;
                @media only screen and (max-width: 1279px) {
                    font-size: 14px;
                    padding: 8px 15px;
                }
                &:hover{
                    background-color: var(--theme-third-color);
                }
            }
        }
    }
    &.active{
        .primary-btn{
            svg{
                transform: rotate(180deg);
                -webkit-transform: rotate(180deg);
                -moz-transform: rotate(180deg);
            }
        }
    }
}
.menu-cart-btn{
    @media only screen and (max-width: 767px) {
        position: fixed;
        bottom: 20px;
        left: 0;
        width: 100%;
        padding: 0 10px;
        z-index: 999;
    }
    .primary-btn{
        padding: 0;
        &.btn-mob{
            display: none;
        }
        @media only screen and (max-width: 767px) {
            width: 100%;
            &.btn-desktop{
                display: none;
            }
            &.btn-mob{
                display: flex;
                justify-content: space-between;
                min-height: 74px;
                background-color: $theme-black-bg-color;
                color: $theme-white-color;
                @include border-radius(20px);
                padding: 10px 20px;
                transition: none;
                label{
                    line-height: 1.6;
                    padding: 0;
                    width: 100%;
                }
                span{
                    border: none;
                    padding: 0;
                    display: block;
                }
                svg{
                    height: 26px;
                    width: 26px;
                }
                &:hover{
                    svg{
                        fill: $theme-white-color;
                    }
                }
            }
        }
        label{
            padding: 13px 18px;
            margin-bottom: 0;
            cursor: pointer;
            position: relative;

            @media only screen and (max-width: 1279px) {
                padding: 10px 22px;
            }
        }
        span{
            border-left: 2px solid rgba(255, 255, 255, 0.1);
            padding: 16px 22px;
            font-size: 14px;
            @media only screen and (max-width: 1279px) {
                padding: 12px 22px;
            }
        }
        .count-pill{
            background-color: $theme-white-bg-color;
            color: var(--theme-primary-color);
            font-size: 10px;
            height: 16px;
            width: 16px;
            @include border-radius(100%);
            padding: 0;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 13px;
            top: 6px;
            @media only screen and (max-width: 1279px) {
                top:3px;
            }
        }
        &:hover{
            .count-pill{
                color: $theme-white-color;
                background-color: var(--theme-primary-color);
            }
        }
    }
}
.menu-list-right{
    padding: 10px 60px 10px 20px;
    justify-content: flex-end;
    width: 640px;
    @media only screen and (max-width: 1279px) {
        padding: 10px 20px;
    }
    @media only screen and (max-width: 767px) {
        width: auto;
    }
}
.re-order-btn{
    padding: 20px 60px;
    margin-bottom: 10px;
    @media only screen and (max-width: 1279px) {
        padding: 10px 20px;
    }
    @media only screen and (max-width: 767px) {
        display: none;
    }
    .primary-btn{
        background-color: transparent;
        font-size: 14px;
    }
}
.product-item-list{
    padding: 0 60px;
    @media only screen and (max-width: 1279px) {
        padding: 0 20px;
    }
    h2{
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 0;
        @media only screen and (max-width: 1279px) {
            font-size: 18px;
        }

    }
    &.unavailable{
        opacity: 0.3;
        pointer-events: none;
    }
}
.p-t-with-btn{
    margin-bottom: 30px;
    flex-wrap: wrap;
    @media only screen and (max-width: 1279px) {
        margin-bottom: 15px;
    }
    @media only screen and (max-width: 767px) {
        padding-top: 15px;
    }
    .unavailable-btn{
        margin-left: 10px;
        .primary-btn{
            background-color: $theme-gray-color1;
            color: $theme-white-color;
            font-size: 14px;
            font-weight: 500;
            @include border-radius(8px);
            padding: 5px 10px;
            cursor: auto;
            span{
                margin-left: 5px;
            }
            @media only screen and (max-width: 767px) {
                font-size: 12px;
                padding: 5px 5px;
                span{
                    display: none;
                }
            }
        }
    }
    .available-from{
        margin-left: 15px;
        font-size: 14px;
        font-weight: 500;
        color: $theme-gray-color1;
        @media only screen and (max-width: 767px) {
            margin-top: 10px;
            margin-left: 0;
        }
        label{
            margin-bottom: 0;
        }
        span{
            margin-left: 5px;
        }
    }
}
.product-list-block{
    background-color: $theme-white-bg-color;
    @include border-radius(25px);
    padding: 15px 15px 15px 20px;
    width: 100%;
    cursor: pointer;
    // line-break: anywhere;
    &.unavailable{
        opacity: 0.3;
        pointer-events: none;
    }
}
.indisponible-tag {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-left: -100px;
	margin-top: -15px;
    span{
        background-color: $theme-gray-color1;
        color: $theme-white-color;
        font-size: 14px;
        font-weight: 500;
        padding: 5px 10px;
        @include border-radius(10px);
        display: block;
    }
}
.product-content{
    h3{
        font-size: 18px;
        color: $theme-black-color;
        margin-bottom: 10px;
        @media only screen and (max-width: 1279px) {
            font-size: 16px;
        }
    }
    p{
        color: $theme-gray-color17;
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        text-transform: lowercase;
        margin-bottom: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        height: 55px;
    }
    .p-price{
        font-size: 16px;
        font-weight: 600;
    }
}
.pro-strike-price{
    color: $theme-black-color;
    opacity: 0.4;
    position: relative;
    margin-right: 10px;
    &::before{
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        background-color: $theme-black-color;
        height: 1px;
        width: 100%;
        margin-top: -0.5px;
        transform: rotate(15deg);
    }
}
.pro-strike-price-mob-cart{
    color: #fff;
}
.product-img{
    position: relative;
    padding-left: 15px;
    img{
        width: 142px;
        height: 142px;
        max-width: unset;
        object-fit: cover;
        @include border-radius(14px);
        @media only screen and (max-width: 1279px) {
            width: 120px;
            height: 120px;
        }
    }
    &.only-tag{
        min-width: 152px;
        min-height: 142px;
        @media only screen and (max-width: 1279px) {
            min-width: 130px;
            min-height: 120px;
        }
    }
}
.pro-tag-list{
    position: absolute;
    bottom: 8px;
    left: auto;
    right: 4px;
    text-align: right;
}
.popular-tag{
    background-color: $theme-popular-tag-color;
    color: $theme-white-color;
    font-size: 16px;
    font-weight: 600;
    padding: 6px 6px;
    @include border-radius(6px);
    line-height: normal;
    margin-bottom: 5px;
    display: inline-block;
    @media only screen and (max-width: 1279px) {
        font-size: 12px;
    }
    svg{
        margin-left: 5px;
    }
    &.percentage-tag{
        background-color: $theme-per-tag-bg;
        color: $theme-per-tag-color;
    }
}

.shop-footer{
    text-align: center;
    background-color: var(--theme-primary-color);
    padding: 14px 60px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    @media only screen and (max-width: 767px) {
        @include border-radius(20px 20px 0 0);
        bottom: 72px;
        width: 96%;
        left: 12px;
        padding: 14px 20px 35px;
        z-index: 99;
    }
    @media only screen and (max-width: 567px) {
        width: 95%;
    }
    @media only screen and (max-width: 479px) {
        width: 94%;
    }
    p{
        color: $theme-white-color;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 12px;
        @media only screen and (max-width: 1279px) {
            font-size: 16px;
        }
        @media only screen and (max-width: 767px) {
            font-size: 14px;
        }
    }
}
.shop-footer-cart-open{
    width: calc(100% - 443px);
    transition: all .2s ease;
    @media only screen and (max-width: 767px) {
        width: 96%;
    }
    @media only screen and (max-width: 567px) {
        width: 95%;
    }
    @media only screen and (max-width: 479px) {
        width: 94%;
    }
}
.footer-close{
    position: absolute;
    right: 20px;
    top: 50%;
    margin-top: -17px;
    @media only screen and (max-width: 767px) {
        display: none;
    }
    span{
        cursor: pointer;
        svg{
            fill: $theme-white-color;
        }
    }
}
.cart-dropdown-open{
    .cart-dropdown{
        right: 0;
        transition: right 0.2s;
    }
}
.cart-dropdown{
    background-color: $theme-white-bg-color;
    width: 443px;
    // height: calc(100vh - 195px);
    position: fixed;
    right: -999px;
    // right: 0;
    bottom: 0;
    z-index: 99;
    overflow-y: auto;
    overflow-x: hidden;
    box-shadow: 0px 2px 60px -10px rgba(0, 0, 0, 0.15);
    transition: all .2s ease;
    @media only screen and (max-width: 767px) {
        top: 0;
        width: 100%;
        height: 100vh !important;
    }
}

.livraison-offerte-mob{
    display: none;
    @media only screen and (max-width: 767px) {
        display: block;
        p{
            background-color: $theme-per-tag-bg;
            color: $theme-per-tag-color;
            font-size: 14px;
            text-align: center;
            padding: 8px 10px 5px;
            font-weight: 600;
            margin-bottom: 0;
        }
    }
}
.moa-mobile{
    display: none;
    // margin-bottom: 10px;
    // margin-top: 10px;
    @media only screen and (max-width: 767px) {
        display: block;
        .moa{
            color: $theme-red-color;
            background-color: $theme-red-color5;
            padding: 10px 10px;
            margin-bottom: 0;
            text-align: center;
            font-size: 14px;
            font-weight: 600;
        }
    }
}

.cart-dropdown-wrp{
    padding: 15px;
    margin-bottom: 135px;
    @media only screen and (max-width: 767px) {
        padding: 0;
        .close-shopping-cart{
            padding-top: 20px;
        }
    }
}
.cart-product-list{
    @media only screen and (max-width: 767px) {
        padding: 15px;
    }
}
.cart-dropdown-wrp-offer{
    @media only screen and (max-width: 767px) {
        margin-bottom: 185px;
    }
}
.close-shopping-cart{
    margin-bottom: 20px;
    @media only screen and (max-width: 767px) {
        display: flex;
        justify-content: center;
    }
    .primary-btn{
        font-size: 16px;
        padding: 5px 9px;
        @media only screen and (max-width: 1279px) {
            font-size: 14px;
            svg{
                width: 20px;
                height: 20px;
            }
        }
    }
}
.c-p-list-box{
    border-top: 2px solid $theme-gray-color7;
    padding-top: 20px;
    margin-top: 20px;
    @media only screen and (max-width: 767px) {
        padding-top: 10px;
        margin-top: 10px;
    }
    &:first-child{
        border-top: none;
        padding-top: 0;
        margin-top: 0;
    }
}
.c-product-title{
    margin-bottom: 10px;
    h3{
        font-size: 16px;
        font-weight: 700;
        color: $theme-black-color;
        margin-bottom: 0;
    }
}
.qty-box{
    .primary-btn{
        background-color: $theme-gray-color7;
        padding: 0;
        color: $theme-black-color;
        @include border-radius(100%);
        height: 22px;
        width: 22px;
        font-size: 26px;
        line-height: 8px;
    }
    .form-control{
        width: 25px;
        height: 25px;
        padding: 0;
        margin: 0 10px;
        font-size: 16px;
        color: $theme-black-color;
        background-color: transparent;
        min-height: auto;
        text-align: center;
        @include border-radius(0);
        &:focus, &:active{
            box-shadow: none;
            border-color: transparent;
        }
    }
}
.c-pro-dec{
    max-height: 74px;
    overflow: hidden;
    p{
        color: $theme-gray-color8;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 5px;
    }
}
.c-pro-dec-all{
    max-height: unset;
}
.c-pro-tag{
    margin-top: 18px;
}
.product-tag{
    flex-wrap: wrap;
    span{
        cursor: pointer;
        font-size: 15px;
        font-weight: 400;
        color: $theme-black-color;
        background-color: $theme-gray-color7;
        padding: 3px 10px;
        @include border-radius(100px);
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 5px 10px;
        @media only screen and (max-width: 767px) {
            font-size: 14px;
            padding: 3px 8px;
            margin: 0 3px 10px;
        }
        &:first-child{
            margin-left: 0;
        }
    }
}
.c-product-price{
    width: 200px;
    text-align: right;
    @media only screen and (max-width: 767px) {
        width: 150px;
    }
    span{
        font-weight: 500;
        font-size: 17px;
    }
}
.c-drop-footer{
    background-color: $theme-white-bg-color;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
    // padding: 18px 44px;
    padding: 18px 0 0 0;
    text-align: center;
    position: fixed;
    bottom: 0;
    left: auto;
    width: 443px;
    display: flex;
    flex-direction: column;
    flex-flow: wrap;
    justify-content: center;
    z-index: 999;
    p{
        color: $theme-yello-color2;
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 10px;
        padding: 0 44px 0;
    }
    .moa{
        color: $theme-red-color;
        background-color: $theme-red-color5;
        padding: 10px 10px;
        font-size: 14px;
        width: 100%;
    }
    .commander-btn-p{
        padding: 0 44px 18px;
        width: 100%;
    }
    .primary-btn{
        width: 100%;
        span{
            margin-left: 10px;
        }
        &.disabled{
            cursor: not-allowed;
            background: #D1D1D1;
            &:hover{
                background: #D1D1D1;
                color: #fff;
            }
        }
    }
}
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) {

    .c-drop-footer {
        position: sticky;
        top: 100%;
    }
}}
.product-item-list-main{
    width: 100%;
    @media only screen and (max-width: 767px) {
        margin-bottom: 100px;
    }
    // background-size: cover !important;
    .c-drop-footer{
        @media only screen and (max-width: 767px) {
            display: none;
        }
    }
}
.product-item-list-main-offer{
    @media only screen and (max-width: 767px) {
        margin-bottom: 180px;
    }
}
.product-item-list-main-cart-open{
    width: calc(100% - 443px);
    transition: all .2s ease;
    @media only screen and (max-width: 767px) {
        width: 100%;
    }
    @media only screen and (max-width: 1599px) {
        .col-lg-4{
            max-width: 50%;
            flex: 0 0 50%;
        }
    }
    @media only screen and (max-width: 962px) {
        .col-sm-6{
            max-width: 100%;
            flex: 0 0 100%;
        }
    }
}

// Reorder popup style

.re-order-popup{
    max-width: 855px;
    @media only screen and (max-width: 1023px) {
        max-width: 100%;
        padding: 0 10px;
    }
    .modal-content{
        padding: 30px;
        max-height: 730px;
        overflow-y: auto;
        overflow-x: hidden;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar{
            display: none;
        }
    }
    .modal-header{
        margin-top: 50px;
        margin-bottom: 30px;
        @media only screen and (max-width: 767px) {
            margin-top: 30px;
            margin-bottom: 20px;
        }
        h2{
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 0;
            @media only screen and (max-width: 767px) {
                font-size: 18px;
            }
        }
    }
    .modal-body-wrp{
        margin-top: 0;
    }
}
.r-o-list-box{
    background-color: $theme-gray-color7;
    @include border-radius(20px);
    padding: 20px;
    margin-top: 15px;
    &:first-child{
        margin-top: 0;
    }
}
.order-create-detail{
    margin-bottom: 20px;
    @media only screen and (max-width: 1023px) {
        display: block !important;
    }
}
.o-c-d-left{
    span{
        color: $theme-gray-color10;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 5px;
        display: block;
    }
}
.o-c-d-right{
    .primary-btn{
        font-size: 16px;
    }
}
.alert-box{
    p{
        background-color: $theme-alert-bg;
        color: $theme-alert-color;
        font-size: 14px;
        font-weight: 500;
        @include border-radius(6px);
        padding: 5px;
        margin-bottom: 10px;
        display: inline-block;
        svg{
            fill: $theme-alert-color;
        }
    }
}
.r-o-product-name{
    margin-bottom: 10px;
    h3{
        font-size: 14px;
        font-weight: 600;
        color: $theme-black-color;
        margin-bottom: 5px;
    }
    &.red{
        h3{
            color: $theme-red-color;
        }
    }
}
.r-o-desc{
    span{
        color: $theme-gray-color11;
        font-size: 14px;
        font-weight: 600;
        margin-left: 3px;
        &:first-child{
            margin-left: 0;
        }
        &.red{
            color: var(--theme-primary-color);
        }
    }
}
.r-o-total{
    text-align: right;
    span{
        color: $theme-black-color;
        font-size: 18px;
        font-weight: 600;
        @media only screen and (max-width: 767px) {
            font-size: 16px;
        }
    }
}

// Product Item popup style

.product-item-popup{
    .modal-content{
        padding: 45px 0 0 0;
        // height: 655px;
        overflow-y: auto;
        overflow-x: hidden;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar{
            display: none;
        }
    }
    .modal-content::-webkit-scrollbar {
      display: none;
    }
    .modal-footer{
        box-shadow: 0px -6px 14px rgba(0, 0, 0, 0.05);
        padding: 25px;
        position: sticky;
        bottom: 0;
        background-color: $theme-white-bg-color;
        @include border-radius(0 0 40px 40px);
    }
    .modal-footer-wrp{
        width: 100%;
        margin: 0;
    }
    .popup-footer-btn{
        width: 100%;
        span{
            color: $theme-red-color;
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            @media only screen and (max-width: 767px) {
                font-size: 14px;
            }
        }
        label{
            height: 24px;
            width: 24px;
            background-color: $theme-white-bg-color;
            @include border-radius(100%);
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 0;
            margin-left: 10px;
            @media only screen and (max-width: 767px) {
                height: 20px;
                width: 20px;
            }
            svg{
                fill: var(--theme-primary-color);
                margin: 0;
                @media only screen and (max-width: 767px) {
                    height: 10px;
                    width: 10px;
                }
            }
        }
        .primary-btn{
            width: 100%;
            font-size: 20px;
            font-weight: 700;
            min-height: 58px;
            @media only screen and (max-width: 767px) {
                font-size: 16px;
                min-height: 48px;
            }
            &:hover{
                background-color: var(--theme-primary-color);
            }
            &.disabled{
                // cursor: not-allowed;
                background: $theme-gray-color1;
                opacity: 0.3;
                &:hover{
                    background-color: $theme-gray-color1;
                }
                label{
                    svg{
                        fill: $theme-black-color;
                    }
                }
            }
            span{
                color: $theme-white-color;
                margin-bottom: 0;
                font-size: 20px;
                font-weight: 700;
                @media only screen and (max-width: 767px) {
                    font-size: 16px;
                }
            }
        }
        .qty-box{
            justify-content: center;
            margin-bottom: 15px;
            .primary-btn{
                min-height: auto;
                height: 24px;
                width: 24px;
                font-size: 26px;
                line-height: 8px;
                padding: 0;
                &:hover{
                    background-color: $theme-gray-color7;
                }
            }
        }
    }
}
.p-i-content{
    text-align: center;
    padding: 25px;
    border-bottom: 2px solid $theme-border-color;
}
.p-i-img{
    margin-bottom: 25px;
    img{
        @include border-radius(29px);
        width: 100%;
        width: 425px;
        height: 215px;
        object-fit: cover;
    }
}
.p-i-name{
    h3{
        font-size: 20px;
        font-weight: 700;
        color: $theme-black-color;
        margin-bottom: 15px;
        @media only screen and (max-width: 767px) {
            font-size: 18px;
        }
    }
    p{
        color: $theme-gray-color1;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 15px;
        line-height: 24px;
        text-transform: lowercase;
        @media only screen and (max-width: 767px) {
            font-size: 14px;
            line-height: 20px;
        }
    }
    span{
        color: $theme-black-color;
        font-size: 16px;
        font-weight: 600;
    }
}
.product-options-list{
    padding: 25px;
    border-top: 2px solid $theme-border-color;
    &:first-child{
        border-top: none;
    }
}
.p-options-box{
    h4{
        font-size: 16px;
        font-weight: 700;
        color: $theme-black-color;
        margin-bottom: 5px;
        &.red{
            color: $theme-red-color;
        }
    }
    span{
        font-size: 14px;
        font-weight: 400;
        color: $theme-gray-color8;
        &.red{
            color: $theme-red-color;
        }
    }
}
.p-variant-list{
    margin-top: 10px;
    .check_lb{
        padding-left: 46px;
        font-size: 16px;
        font-weight: 500;
        color: $theme-black-color;
        max-width: 75%;
        @media only screen and (max-width: 767px) {
            font-size: 14px;
        }
    }
    .checkmark.sign_in_checkmak{
        height: 30px;
        width: 30px;
        margin-top: -15px;
        background-color: $theme-gray-color12 !important;
        box-shadow: none;
    }
    .sign_in_checkmak{
        &::after{
            left: 3px !important;
            top: 3px !important;
            width: 24px !important;
            height: 24px !important;
            border-width: 0 !important;
            color: $theme-black-color !important;
            background-color: $theme-black-bg-color;
            @include border-radius(100%);
            transform: rotate(0deg);
        }
    }
    .p-v-b{
        border-top: 2px solid $theme-border-color;
        margin-top: 10px;
        padding-top: 10px;
        &:first-child{
            border-top: none;
            margin-top: 0;
            padding-top: 0;
        }
    }
    .p-v-price{
        font-size: 16px;
        font-weight: 500;
        @media only screen and (max-width: 767px) {
            font-size: 14px;
        }
    }
    &.input-checkbox{
        .checkmark.sign_in_checkmak{
            @include border-radius(8px);
            height: 30px;
            width: 30px;
            background-color:$theme-gray-color12 !important;
            border: 3px solid $theme-gray-color12;
            margin-top: -12.5px;
            &.checked{
                background-color: $theme-black-bg-color !important;
            }
        }
        .sign_in_checkmak{
            &::after{
                left: 7px !important;
                top: 0px !important;
                width: 10px !important;
                height: 19px !important;
                border-width: 0 3px 3px 0 !important;
                color: $theme-white-color !important;
                @include border-radius(0);
                transform: rotate(45deg);
                background-color: transparent;
            }
        }
    }
}
.comment-trade{
    background-color: $theme-gray-color7;
    @include border-radius(19px);
    padding: 15px;
    h4{
        font-size: 14px;
        font-weight: 600;
        color: $theme-black-color;
        margin-bottom: 10px;
        span{
            font-size: 12px;
            font-weight: 400;
        }
    }
    .form-control{
        background-color: $theme-white-bg-color;
    }
}

// Checkout page style

.checkout-page{
    .shop-page-header{
        background-color: $theme-white-bg-color;
    }
    .page-content-wrp{
        @media only screen and (max-width: 1023px) {
            background-color: $theme-white-bg-color;
            margin-bottom: 0;
        }
    }
    // .estimated-delivery{
    //     p{
    //       span{
    //            color: var(--theme-secondary-color);
    //            text-decoration: line-through;
    //         }
    //     }
    // }
}
.page-title {
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;
    h2{
        margin-bottom: 0;
        font-size: 24px;
        font-weight: 700;
        position: relative;
        z-index: 2;
        padding: 0 10px;
        @media only screen and (max-width: 1279px) {
            font-size: 20px;
        }
        &::before{
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            background-color: $theme-white-bg-color;
            height: 46px;
            width: 100%;
            margin-top: -23px;
            border-radius: 10px;
            z-index: -1;
            transform: rotate(-1.23deg);
            box-shadow: 0px 3.42803px 44.85px -15px rgba(15, 81, 118, 0.3);
        }
    }
}
.page-heading-section{
    margin-bottom: 35px;
    position: relative;
    @media only screen and (max-width: 1023px) {
        display: none !important;
    }
    .primary-btn{
        background-color: transparent;
        position: absolute;
        left: 0;
        svg{
            stroke: var(--theme-primary-color);
            fill: transparent;
        }
        &:hover{
            svg{
                stroke: $theme-white-color;
                fill: transparent;
            }
        }
    }
}
.checkout-page-wrp{
    padding: 35px 60px;
    @media only screen and (max-width: 1279px) {
        padding: 20px 20px;
    }
}
.cart-content {
	max-width: 700px;
	margin: 0 auto;
    @media only screen and (max-width: 1023px) {
        max-width: 100%;
        &.c-c-mobile-show{

        }
    }

}
.mob-close-btn{
    display: none;
}
@media only screen and (max-width: 1023px) {
    .c-c-mob-popup{
        display: none;
        position: fixed;
        height: 100vh;
        width: 100%;
        top: 0;
        left: 0;
        background-color: $theme-white-bg-color;
        z-index: 999;
        overflow-y: auto;
        overflow-x: hidden;
        padding-bottom: 30px;
        .mob-close-btn{
            display: inline-flex;
            &.modal-content{
                padding: 20px 0;
                flex-direction: inherit;
                justify-content: center;
                border-bottom: 2px solid $theme-border-color;
                margin-bottom: 20px;
                @include border-radius(0);
                .close{
                    position: inherit;
                    right: auto;
                    left: auto;
                    top: auto;
                }
            }
        }
    }
    .c-c-mob-popup-show{
        display: block;
        .box-heading{
            margin-bottom: 0;
            h3{
                display: none;
            }
            h4{
                color: $theme-black-color;
                font-size: 14px;
                display: block;
                margin-bottom: 0;
            }
        }
        .view-basket-section{
            display: none !important;
        }
        .summary-content-list{
            &:first-child{
                border-top: none;
            }
        }
        .summary-total{
            border-top: 2px solid $theme-border-color;
            padding-top: 20px;
        }
    }
}

// .checkout-page-inn{
//     @media only screen and (max-width: 1023px) {
//         .row{
//             margin: 0;
//         }
//         .col-lg-6{
//             padding: 0;
//         }
//     }
// }
.box-heading{
    margin-bottom: 20px;
    @media only screen and (max-width: 1023px) {
        text-align: center;
    }
    h3{
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 0;
        @media only screen and (max-width: 767px) {
            font-size: 18px;
        }
    }
    h4{
        display: none;
    }
}
.cart-content-wrp {
	background-color: $theme-white-bg-color;
	@include border-radius(40px);
    padding: 30px;
    @media only screen and (max-width: 1023px) {
        @include border-radius(0);
        padding: 0 ;
    }
    // @media only screen and (max-width: 767px) {

    // }
}
.cart-content-wrp{
    &.checkout-part{
        padding: 0;
    }
}
.view-basket-section{
    margin-bottom: 25px;
    .primary-btn{
        background-color: $theme-gray-color7;
        color: $theme-black-color;
        svg{
            fill: $theme-black-color;
        }
    }
}
.summary-content-list{
    padding: 20px 0;
    border-top: 2px solid $theme-gray-color7;
    .primary-btn{
        &.link-btn{
            color: $theme-black-color;
            margin-left: 15px;
        }
    }
}
.s-total{
    margin-top: 5px;
    &:first-child{
        margin-top: 0;
    }
    h4, span{
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 0;
        color: $theme-black-color;
        @media only screen and (max-width: 767px) {
            font-size: 14px;
        }
    }
    h4{
        // width: 70%;
    }
    span{
        width: 200px;
        text-align: right;
        &.strike-price{
            color: $theme-gray-color10;
            text-decoration:line-through;
        }
    }
    &.code-promo{
        h4, span{
            color: $theme-yello-color2;
        }
    }
}
.tooltip-title{
    position: relative;
}
.tooltip-svg{
    margin-left: 10px;
    &:hover{
        .tooltip-content{
            display: inline-flex;
        }
    }
    svg{
        fill: $theme-black-color;
        cursor: pointer;
    }
}
.tooltip-content{
    display: none;
    position: absolute;
    top: -50px;
    z-index: 9;
    span{
        background-color: $theme-gray-color12;
        color: $theme-black-color;
        font-size: 16px;
        font-weight: 500;
        padding: 16px 15px;
        @include border-radius(15px);
        width: 100%;
        text-align: left;
        @media only screen and (max-width: 767px) {
            font-size: 14px;
            padding: 10px 10px;
        }
    }
}
.promo-code{
    position: relative;
    width: 300px;
    .form-control{
        min-height: 50px;
        @include border-radius(15px);
        padding: 6px 24px;
        color: $theme-black-color;
    }
    .primary-btn{
        position: absolute;
        right: 10px;
        top: 50%;
        font-size: 16px;
        color: $theme-white-color;
        background-color: $theme-black-bg-color;
        padding: 4px 15px;
        margin-top: -15px;
        @media only screen and (max-width: 767px) {
            font-size: 14px;
        }
    }
    &.red{
        .form-control{
            color: $theme-red-color;
        }
    }
    &.deactive{
        .primary-btn{
            background-color: $theme-gray-color13;
        }
    }
}
.no-valid-promo{
    margin-top: 15px;
    p{
        margin-bottom: 0;
        color: $theme-red-color;
        font-weight: 600;
    }
}
.no-login-promo{
    margin-top: 5px;
    p{
        margin-bottom: 0;
        color: $theme-black-color;
        font-weight: 600;
    }
}
.summary-total-wrp{
    background-color: $theme-gray-color7;
    border: 2px solid rgba(0, 0, 0, 0.19);
    @include border-radius(11px);
    min-height: 75px;
    font-size: 28px;
    font-weight: 700;
    padding: 10px 20px;
    @media only screen and (max-width: 767px) {
        font-size: 18px;
        min-height: 55px;
    }
    label{
        margin-bottom: 0;
    }
}
.order-ready-msg{
    background-color: $theme-per-tag-bg;
    padding: 10px 10px;
    text-align: center;
    @include border-radius(11px);
    margin-top: 25px;
    p{
        margin-bottom: 0;
        color: $theme-yello-color2;
        font-size: 18px;
        font-weight: 700;
        @media only screen and (max-width: 767px) {
            font-size: 14px;
            line-height: 20px;
        }
    }
}
.checkout-form-inn{
    .t-l-b-wrp{
        margin-bottom: 35px;
    }
    .t-b-l{
        .primary-btn{
            font-size: 18px;
            padding: 6px 5px;
            @media only screen and (max-width: 767px) {
                font-size: 16px;
            }
        }
    }
}

.edit-cart-popup{
    max-width: 525px;
    .modal-main{
        @media only screen and (max-width: 767px) {
            margin-bottom: 190px;
        }
    }
    .modal-content{
        padding: 45px 0 0;
        overflow-y: auto;
        overflow-x: hidden;
        -ms-overflow-style: none;
        scrollbar-width: none;
        @media only screen and (max-width: 767px) {
            padding: 20px 0 0 0;
            height: 100vh;
        }
        &::-webkit-scrollbar{
            display: none;
        }
    }
    &.modal-dialog-scrollable{
        @media only screen and (max-width: 767px) {
            max-height: 100%;
        }
    }
    .modal-body{
        padding: 0 56px;
        @media only screen and (max-width: 767px) {
            padding: 0 15px;
        }
    }
    .modal-body-wrp{
        margin-top: 70px;
        @media only screen and (max-width: 767px) {
            margin-top: 30px;
        }
    }
    .cart-dropdown{
        background-color: inherit;
        position: inherit;
        width: auto;
        overflow: auto;
        box-shadow: none;
        @media only screen and (max-width: 767px) {
            height: auto !important;
        }
    }
    .cart-dropdown-wrp{
        padding: 0;
        margin-bottom: 0;
    }
    .c-drop-footer{
        position: inherit;
        padding: 15px 25px 36px;
        width: 100%;
        .summary-total{
            width: 100%;
        }
    }
    .modal-footer{
        position: sticky;
        bottom: 0;
        margin: 0;
        padding: 0;
        @media only screen and (max-width: 767px) {
            display: none;
        }
    }
    .modal-footer > *{
        margin: 0;
    }
    .discount-offer{
        @include border-radius(18px);
        margin-bottom: 20px;
        padding: 15px;
        p{
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 0;
        }
    }
    .cart-product-list{
        @media only screen and (max-width: 767px) {
            padding: 0;
        }
    }
    .shop-footer, .menu-cart-btn{
        display: none;
        @media only screen and (max-width: 767px) {
            display: block;
        }
    }
    .add-new-pro-btn .primary-btn{
        &:hover{
            color: var(--theme-primary-color);
            background-color: var(--theme-third-color);
        }
    }
}
.product-item-popup .add-new-pro-btn{
    padding: 13px 56px;
    border-top: 2px solid $theme-border-color;
    @media only screen and (max-width: 767px) {
        padding: 13px 15px;
    }
    .primary-btn{
        min-width: 221px;
        min-height: 40px;
        width: auto;
        font-size: 16px;
        font-weight: 500;
        padding: 5px 15px;
        label{
            svg{
                height: 12px;
                width: 12px;
            }
        }
    }
}

 @keyframes loader4 {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
 }
 @-webkit-keyframes loader4 {
    from {-webkit-transform: rotate(0deg);}
    to {-webkit-transform: rotate(360deg);}
 }
.loader{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--theme-fourth-color);
    // opacity: 0.5;
    height: 100%;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    &::before{
        content: '';
        width:45px;
        height:45px;
        display:inline-block;
        padding:0px;
        border-radius:100%;
        border:5px solid;
        border-top-color: var(--theme-primary-color);
        border-bottom-color:rgba(255,255,255, 0.3);
        border-left-color:var(--theme-primary-color);
        border-right-color:rgba(255,255,255, 0.3);
        -webkit-animation: loader4 1s ease-in-out infinite;
        animation: loader4 1s ease-in-out infinite;
    }
}

.main_loader{
    background-color: #82cffb;
    opacity: 1;
    &::before{
        content: '';
        width:45px;
        height:45px;
        display:inline-block;
        padding:0px;
        border-radius:100%;
        border:5px solid;
        border-top-color: #0F5176;
        border-bottom-color:rgba(255,255,255, 0.3);
        border-left-color:#0F5176;
        border-right-color:rgba(255,255,255, 0.3);
        -webkit-animation: loader4 1s ease-in-out infinite;
        animation: loader4 1s ease-in-out infinite;
    }
}

.missing_choice{
    cursor: pointer;
    color: $theme-red-color !important;
}

.c-type-delivery{
    padding: 40px 40px 25px 40px;
    @media only screen and (max-width: 1023px) {
        padding: 0 0 20px 0;
    }
    h4{
        color: $theme-black-color;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 20px;
        @media only screen and (max-width: 767px) {
            font-size: 16px;
        }
    }
    .date-dropdown{
        label{
            color: $theme-black-color;
        }
        .d-dropdown{
            min-width: 197px;
        }
        &.hour-dropdown{
            margin-top: 0;
            margin-left: 20px;
            .d-dropdown{
                min-width: 110px;
            }
        }
    }
}
.c-drop-deli-btn{
    position: relative;
    @media only screen and (max-width: 767px) {
        min-width: 155px;
    }
    .primary-btn{
        background-color: $theme-black-bg-color;
        color: $theme-white-color;
        @include border-radius(10px);
        padding: 5px 10px;
        min-width: 207px;
        min-height: 46px;
        font-size: 16px;
        @media only screen and (max-width: 1279px) {
            min-width: auto;
        }
        @media only screen and (max-width: 767px) {
            font-size: 14px;
            padding: 5px 1px;
            min-width: 100%;
        }
        svg{
            fill: $theme-white-color;
            @media only screen and (max-width: 767px) {
                margin-right: 0;
                &.svg1{
                    width: 12px;
                    height: 14px;
                }
                &.svg2{
                    width: 20px;
                    height: 20px;
                }
                &.svg3{
                    width: 12px;
                    height: 16px;
                }
                &.svg4{
                    width: 20px;
                    height: 20px;
                }
            }
        }
        span{
            margin-left: 10px;
            @media only screen and (max-width: 767px) {
                margin-left: 5px;
            }
            svg{
                stroke: $theme-white-color;
                fill: $theme-black-color;
                margin-right: 0;
            }
        }
        &.des-que{
            // margin-left: 15px;
        }
    }
    ul{
      z-index: 1;
        margin: 0;
        padding: 0;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        // background-color: $theme-black-color;
        animation: growDown 300ms ease-in-out forwards;
        transform-origin: top center;
        li{
            color: $theme-black-color;
            font-size: 16px;
            margin-bottom: 0;
        }
    }
    &.active{
        .primary-btn{
            @include border-radius(10px 10px 0 0);
            span{
                svg{
                    transform: rotate(180deg);
                    -webkit-transform: rotate(180deg);
                    -moz-transform: rotate(180deg);
                }
            }
        }
        ul{
            li{
                .primary-btn{
                    @include border-radius(0 0 10px 10px);
                    border-top: 2px solid rgba(255, 255, 255, 0.1);
                    min-width: 100%;
                }
            }
        }
    }
}
.confirm-voter-add{
    h4{
        color: $theme-black-color;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 20px;
        @media only screen and (max-width: 767px) {
            font-size: 16px;
        }
    }
}

.confirm-voter-main{
    padding: 0px 10px 10px 10px;
    border-top: 2px solid $theme-border-color;
    @media only screen and (max-width: 1023px) {
        padding: 0;
    }
}
.confirm-voter-wrp{
    padding: 30px 30px 0 30px;
    @media only screen and (max-width: 1023px) {
        padding: 20px 0 0 0;
    }
    .form-label{
        color: $theme-black-color;
        font-size: 16px;
        @media only screen and (max-width: 1279px) {
            font-size: 14px;
        }
    }
}
.paymetn-means{
    background-color: $theme-gray-color14;
    @include border-radius(30px);
    padding: 20px 20px 10px 20px;
    margin-top: 30px;
    h3{
        font-size: 18px;
        color: $theme-black-color;
        @media only screen and (max-width: 767px) {
            font-size: 16px;
        }
    }
    .deli-type-btn{
        .primary-btn{
            background-color: $theme-white-bg-color;
            min-height: 70px;
            width: 100%;
            @include border-radius(20px);
            .btn-mark{
                background-color: $theme-dropdown-bg;
                height: 42px;
                width: 42px;
                margin-right: 20px;
                @media only screen and (max-width: 767px) {
                    height: 24px;
                    width: 24px;
                    margin-right: 10px;
                }
                .blue-tick{
                    height: 28px;
                    width: 28px;
                    margin: 0;
                    @media only screen and (max-width: 767px) {
                        height: 16px;
                        width: 16px;
                        svg{
                            width: 10px;
                            height: 9px;
                        }
                    }
                }
            }
            svg{
                margin: 0;
            }
            label{
                margin-bottom: 0;
                font-size: 20px;
                font-weight: 600;
                margin-left: 15px;
                @media only screen and (max-width: 1023px) {
                    font-size: 16px;
                }
                @media only screen and (max-width: 1023px) {
                    font-size: 14px;
                    margin-left: 10px;
                }
            }
            &.active{
                background-color: $theme-blue-bg;
                .btn-mark{
                    background-color: $theme-white-bg-color;
                }
            }
        }
    }
    .another-card{
        position: absolute;
        right: 12px;
        top: 50%;
        margin-top: -19px;
        .primary-btn{
            background-color: $theme-black-bg-color;
            color: $theme-white-color;
            min-height: auto;
            width: auto;
            font-size: 14px;
            margin-bottom: 0;
            min-width: auto;
            @media only screen and (max-width: 767px) {
                padding: 8px 2px;
            }
            svg{
                fill: $theme-white-color;
                margin-right: 10px;
                @media only screen and (max-width: 767px) {
                    margin-right: 5px;
                }
            }
        }
    }
}
.payment-card-wrp{
    position: relative;
    cursor: pointer;
    margin-top: 10px;
}
.paymetn-means .deli-type-btn .add-payment-card{
    display: flex;
    justify-content: center;
    .primary-btn{
        width: auto;
        background-color: $theme-white-bg-color;
        color: $theme-black-color;
        justify-content: center;
        margin-bottom: 0;
        font-size: 18px;
        @include border-radius(30px);
        min-height: 52px;
        @media only screen and (max-width: 767px) {
            font-size: 15px;
        }
        span{
            height: 32px;
            width: 32px;
            @include border-radius(100%);
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $theme-black-color;
            margin-left: 20px;
            svg{
                fill:$theme-white-color;
            }
        }
    }
}
.use-wallet{
    background-color: rgba(0, 0, 0, 0.05);
    padding: 20px;
    @include border-radius(10px);
    margin-bottom: 25px;
}
.to-order-section{
    border-top: 2px solid $theme-gray-color15;
    padding-top: 20px;
    margin-top: 20px;
}
.use-wallet-text{
    .u-w-p{
        margin-bottom: 0;
        display: flex;
        align-items: center;
        font-weight: 700;
        @media only screen and (max-width: 767px) {
            font-weight: 700;
        }
        @media only screen and (max-width: 567px) {
            position: relative;
            display: inline-block;
        }
        .tooltip-svg{
            display: flex;
            order: 3;
            @media only screen and (max-width: 567px) {
                display: inline-block;
            }
            svg{
                margin-left: 0;
            }
        }
        .tooltip-content{
            left: 0;
            @media only screen and (max-width: 767px) {
                top: -30px;
            }
            @media only screen and (max-width: 567px) {
                top: -90px;
            }
        }
        svg{
            fill: $theme-black-color;
            margin-left: 5px;
            // @media only screen and (max-width: 567px) {
            //     // position: absolute;
            //     // right: 20px;
            //     // top: -2px;
            //     // margin-top: -10px;
            //     display: inline-block;
            // }
        }
        span{
            margin-left: 5px;
            font-weight: 400;
            // @media only screen and (max-width: 767px) {
            //     font-weight: 400;
            // }
            @media only screen and (max-width: 567px) {
                display: block;
                margin-top: 5px;
                margin-left: 0;
            }
        }
    }
}
.to-order-btn{
    .primary-btn{
        background-color: $theme-black-bg-color;
        min-height: 102px;
        @include border-radius(30px);
        color: $theme-white-color;
        width: 100%;
        padding: 11px 35px;
        @media only screen and (max-width: 1023px) {
            @include border-radius(20px);
            min-height: 70px;
        }
        @media only screen and (max-width: 767px) {
            min-height: 60px;
            padding: 11px 10px;
        }
        label{
            margin-bottom: 0;
            font-size: 23px;
            cursor: pointer;
            @media only screen and (max-width: 1279px) {
                font-size: 20px;
            }
            @media only screen and (max-width: 767px) {
                font-size: 16px;
            }
        }
        span{
            font-size: 18px;
            @media only screen and (max-width: 1279px) {
                font-size: 16px;
            }
        }
    }
}

.card-error-popup{
    max-width: 525px;
    .modal-content{
        padding: 45px 20px 30px 20px;
        text-align: center;
    }
}
.card-error-title{
    border-bottom: 2px solid $theme-border-color;
    padding-bottom: 30px;
    p{
        color: $theme-red-color;
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        margin-bottom: 0;
        span{
            display: block;
        }
    }
}
.change-card-section{
    margin: 30px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .primary-btn{
        min-width: 274px;
        min-height: 60px;
        &.secondary-btn{
            margin-top: 15px;
        }
    }
}
.card-f-support{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 2px solid $theme-border-color;
    padding-top: 30px;
    span{
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 10px;
        color: $theme-blue-color2;
    }
    .primary-btn{
        background-color: $theme-blue-color2;
        color: $theme-white-color;
        font-size: 16px;
        padding: 5px 11px;
        svg{
            fill: $theme-white-color;
        }
    }
}

.card-edit-popup{
    .modal-content{
        padding: 45px 0 0 0;
        text-align: center;
        overflow-y: auto;
        overflow-x: hidden;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .c-drop-footer{
        position: inherit;
        padding: 20px;
        width: 100%;
        .primary-btn{
            label{
                height: 30px;
                width: 30px;
                background-color: #ffffff;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 0;
                margin-left: 10px;
                svg{
                    fill: var(--theme-primary-color);
                    margin: 0;
                }
            }

            &:hover{
                label{
                    background-color: var(--theme-primary-color);
                    svg{
                        fill: $theme-white-color;
                    }
                }
            }
        }
    }
    .modal-footer{
        position: sticky;
        bottom: 0;
        margin: 0;
        padding: 0;
    }
    .modal-footer > *{
        margin: 0;
    }
    .modal-body-wrp{
        padding: 30px 20px;
        @media only screen and (max-width: 767px) {
            margin-top: 0;
        }
    }
    .modal-footer-wrp{
        width: 100%;
    }
}
.c-card-list{
    ul{
        margin: 0;
        padding: 0;
    }
    li{
        .primary-btn{
            background-color: $theme-body-bg;
            color: $theme-black-color;
            font-size: 20px;
            width: 100%;
            @include border-radius(20px);
            justify-content: flex-start;
            min-height: 80px;
            @media only screen and (max-width: 767px) {
                min-height: 60px;
                font-size: 16px;
            }
            svg{
                fill: $theme-black-color;
                margin-right: 20px;
                margin-left: 0;
                @media only screen and (max-width: 767px) {
                    width: 30px;
                    height: 23px;
                }
            }
            &.active{
                background-color: $theme-blue-bg;
                border-color: $theme-blue-color;
            }
        }
    }
}
.card-edit-popup{
    .form-label{
        color: $theme-black-color;
        @media only screen and (max-width: 767px) {
            font-size: 14px;
        }
        &.error{
            color: $theme-red-color;
        }
    }
    .form-control{
        &.error{
            background-color: $theme-red-color4;
            color: $theme-red-color;
        }
    }
    .c-drop-footer{
        .primary-btn{
            width: auto;
            min-height: 60px;
            @media only screen and (max-width: 767px) {
                font-size: 14px;
                min-height: 43px;
            }
            &.back-btn-popup{
                @include border-radius(100%);
                height: 60px;
                width: 60px;
                border: 5px solid rgba(0, 0, 0, 0.1);
                padding: 0;
                background-color: $theme-white-bg-color;
                margin-right: 20px;
                min-height: auto;
                @media only screen and (max-width: 767px) {
                    height: 43px;
                    width: 43px;
                    svg{
                        width: 10px;
                        height: 17px;
                    }
                }
                svg{
                    margin: 0;
                    fill: $theme-black-color;
                }
            }
        }
    }
    .form-control.StripeElement{
        padding: 15px 14px;
    }
}
.error-msg{
    color: $theme-red-color;
    font-size: 14px;
    font-weight: 400;
    // display: flex;
    // justify-content: flex-end;
    margin-top: 10px;
}

.cart-total-btn-mob{
    display: none;
}
@media only screen and (max-width: 1023px) {
    .cart-total-btn-mob{
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 2px solid $theme-border-color;
        padding-bottom: 20px;
        margin-bottom: 20px;

    }
    .cart-count-btn{
        background-color: $theme-gray-color7;
        @include border-radius(20px);
        padding: 15px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        min-width: 165px;
        min-height: 100px;
        margin: 0 5px;
        svg{
            fill: $theme-black-color;
            margin-bottom: 15px;
        }
        span{
            font-size: 14px;
            &.price{
                font-size: 18px;
                font-weight: bold;
            }
        }
        label{
            font-size: 14px;
            margin-bottom: 0;
            color: $theme-gray-color11;
        }
    }
}

.cart-total-btn-mob1{
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid $theme-border-color;
    padding-bottom: 20px;
    margin-bottom: 20px;

}
.cart-count-btn1{
    background-color: $theme-gray-color7;
    @include border-radius(20px);
    cursor: pointer;
    padding: 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    min-width: 165px;
    min-height: 100px;
    margin: 0 5px;
    svg{
        fill: $theme-black-color;
        margin-bottom: 15px;
    }
    span{
        font-size: 14px;
        &.price{
            font-size: 18px;
            font-weight: bold;
        }
    }
    label{
        font-size: 14px;
        margin-bottom: 0;
        color: $theme-gray-color11;
    }
}



//////////// account page css start

.address-page-wrp{
    width: 100%;
    padding: 50px 40px;
    @media only screen and (max-width: 1279px) {
        padding: 50px 20px;
    }
    @media only screen and (max-width: 1023px) {
        padding: 20px 20px;
    }
}
.address-page-inn{
    width: 100%;
}
.account-page-container{
    max-width: 1400px;
    margin: 0 auto;
}
.pro-img{
    height: 128px;
    width: 128px;
    @include border-radius(100%);
    @media only screen and (max-width: 1023px) {
        height: 98px;
        width: 98px;
    }
    img{
        @include border-radius(100%);
    }
}
.pro-name{
    margin-left: 30px;
    span{
        font-size: 30px;
        font-weight: bold;
        color:  $theme-black-color;
        text-transform: capitalize;
        @media only screen and (max-width: 1023px) {
            font-size: 18px;
        }
    }
}
.account-header-top{
    margin-bottom: 30px;
}
.pro-content{
    width: 100%;
}
.profile-detail{
    @media only screen and (max-width: 767px) {
        flex-direction: column;
    }
}
.pro-list{
    width: 250px;
    margin-right: 30px;
    @media only screen and (max-width: 767px) {
        width: 100%;
        margin-right: 0;
        border-bottom: 2px solid var(--theme-secondary-color);
        margin-bottom: 30px;
        display: none;
    }
    ul{
        padding: 0;
        margin: 0;
    }
    li{
        margin-bottom: 25px;
        a{
            display: block;
            font-weight: bold;
            font-size: 20px;
            text-transform: capitalize;
            color: var(--theme-primary-color);
            &:hover{
                color: $theme-black-color;
            }
            @media only screen and (max-width: 1023px) {
                font-size: 18px;
            }
        }
        &.active{
            a{
                color: $theme-black-color;
            }
        }
    }
}
.pro-cont-title{
    margin-bottom: 30px;
    h5{
        font-size: 20px;
        text-transform: uppercase;
        margin-bottom: 0;
        @media only screen and (max-width: 1023px) {
            font-size: 18px;
        }
    }
    &.add-map{
        margin-top: 30px;
        h5{
            text-transform: none;
            margin-bottom: 0;
        }
    }
}
.modi-btn{
    a{
        font-weight: bold;
        font-size: 20px;
        color: var(--theme-primary-color);
        @media only screen and (max-width: 1023px) {
            font-size: 18px;
        }
        &:hover{
            color: var(--theme-secondary-color);
        }
    }
    .close{
        opacity: 1;
        font-size: 38px;
        font-weight: 500;
        color: var(--theme-primary-color);
        &:hover{
            color: var(--theme-secondary-color);
        }
    }
}
.pro-cont-detail{
    .form-block{
        margin-bottom: 20px;
        position: relative;
        .form-label{
            font-size: 16px;
            font-weight: 500;
            color: $theme-black-color;
            text-transform: capitalize;
        }
        .form-control{
            border: 2px solid transparent;
            background-color: $theme-white-bg-color;
            padding-right: 50px;
        }
        &.modifier{
            .form-control{
                border-color: var(--theme-secondary-color);
            }
        }
        label{
            font-size: 18px;
            text-transform: uppercase;
            font-weight: bold;
            @media only screen and (max-width: 1023px) {
                font-size: 16px;
            }
        }
    }
    h5{
        font-size: 22px;
        margin-bottom: 15px;
        color: $theme-black-color;
        @media only screen and (max-width: 1023px) {
            font-size: 18px;
        }
        a{
            color: var(--theme-primary-color);
        }
    }
    .pro-cont-title{
        h5{
            margin-bottom: 0;
        }
    }
}
.account-page{
    .address-page-wrp{
        min-height: 100vh;
        align-items: start;
    }
}
.checkbox-block{
    position: absolute;
    right: 40px;
    top: 50%;
    // margin-top: -12px;
    @media only screen and (max-width: 1279px) {
        // margin-top: -14px;
        // top: 29px;
    }
    // @media only screen and (max-width: 1023px) {
    //     margin-top: -14px;
    //     top: 26px;
    // }
    &.right-icons{
        margin-top: -20px;
        right: 10px;
        .close{
            margin: 0 15px;
            // color: $theme-black-color;
            @media only screen and (max-width:767px) {
                margin: 0 8px;
            }
            &.correct{
                span{
                    display: block;
                    width: 12px;
                    height: 21px;
                    border: solid var(--theme-primary-color);
                    border-width: 0 3px 3px 0;
                    -webkit-transform: rotate(45deg);
                    transform: rotate(45deg);
                    color: var(--theme-primary-color);
                    &:hover{
                        border-color: var(--theme-secondary-color);
                    }
                }
            }
        }
    }
    &.payment-page{
        top: 50%;
    }
    .check_lb{
        position: inherit;
    }
}
.order-page-tab{
    .nav-tabs{
        border: none;
        margin: 0 -1rem;
    }
    .nav-item{
        margin-bottom: 0;
    }
    .nav-link{
        font-size: 20px;
        font-weight: bold;
        border: none;
        @media only screen and (max-width:767px) {
            font-size: 18px;
        }
        &:hover{
            border: none;
            color: $theme-black-color;
        }
        &.active{
            background: none;
            border: none;
            color: $theme-black-color;
        }
    }
}
.order-page-list{
    margin-top: 50px;
    @media only screen and (max-width:767px) {
        margin-top: 30px;
    }
    ul{
        padding: 0;
        margin: 0;
    }
    li{
        margin-top: 25px;
        padding-top: 25px;
        margin-bottom: 0;
        border-top: 1px solid var(--theme-secondary-color);
        &:first-child{
            border-top: none;
            margin-top: 0;
            padding-top: 0;
        }
        h5{
            font-size: 20px;
            color: $theme-gray-color11;
            @media only screen and (max-width:767px) {
                font-size: 16px;
            }
        }
    }
    .o-images{
        height: 90px;
        width: 90px;
        display: flex;
        align-items: center;
        img{
            max-width: 90px;
            width: 90px;
            height: 90px;
            @include border-radius(100%);
        }
    }
}
.o-name{
    cursor: pointer;
    // @media only screen and (max-width:767px) {
    //     display: block !important;
    // }
    h4{
        margin-bottom: 10px;
        font-size: 20px;
        font-weight: 500;
        color: $theme-black-color;
        @media only screen and (max-width:767px) {
            font-size: 18px;
        }
    }
    .o-number{
        color: $theme-gray-color4;
        font-weight: 500;
    }
}
.o-content{
    margin-left: 30px;
    width: 100%;
}
.o-price{
    font-size: 20px;
    font-weight: bold;
    // color: $theme-blue-shad4;
    display: block;
    @media only screen and (max-width:767px) {
        font-size: 18px;
        // margin-top: 10px;
    }
}
.o-time{
    margin-top: 15px;
}
.o-p-time{
    font-size: 20px;
    @media only screen and (max-width:767px) {
        font-size: 18px;
    }
}

.form-no-input{
    .form-block{
        margin-bottom: 30px;
        .form-control{
            border-top: none;
            border-left: none;
            border-right: none;
            border-bottom: 2px solid $theme-gray-color9;
            background-color: transparent;
            @include border-radius(0);
            padding: 10px 50px 10px 0;
            color: $theme-gray-color5;
            height: auto;
        }
        &.modifier{
            .form-control{
                // border-color: $theme-blue-shad4;
            }
        }
        label{
            margin-bottom: 0;
        }
    }
}

.order-map-popup{
    .modal-body-wrp{
        margin-top: 0;
        text-align: center;
    }
    .modal-content{
        padding: 30px;
        @media only screen and (max-width:767px) {
            padding: 20px;
        }
    }
}

.profile_button{
    background: none;
    border: none;
    font-weight: bold;
    color: unset;
}

#carddetail {
  border-radius: 4px;
  padding: 12px;
  border: 1px solid var(--dark-theme-color);
  height: 44px;
  width: 100%;
  background: white;
  max-width: unset !important;
}

input[name="cardnumber"]{
    color: blue;
}


#stripe-cvc-element {
    background: #ffffff !important;
    margin: 5px 0px !important;
    padding: 10px 5px !important;
}

.form-control.StripeElement{
    padding: 20px 24px;
}

.forgot-popup{
    max-width: 450px;
    padding: 0 10px;
    margin: 0 auto;
    @media screen and (max-width:567px){
        max-width: 100%;
    }
    .modal-content{
        padding: 60px;
        text-align: center;
        @media screen and (max-width:767px){
            padding: 30px;
        }
    }
    .popup-title{
        h2{
            font-size: 20px;
        }
        p{
            color: $theme-gray-color11;
        }
    }
}

.mob-full-popup{
    @media screen and (max-width:767px){
        max-width: 100%;
        padding: 0;
        margin: 0;
        .modal-content{
            @include border-radius(0);
            // height: 100vh;
            .close.with-name{
                position: inherit;
                left: auto;
                right: auto;
                top: auto;
                display: flex !important;
                float: none;
                justify-content: center;
                border-bottom: 2px solid $theme-border-color;
                padding-bottom: 20px;
                width: 100%;
            }
        }
    }
}


// Order Tracking style

.order-tracking-page{
    .home-wrp{
        background: rgba(0, 0, 0, 0.8);
        min-height: auto;
        padding: 20px;
    }
    &.home-main{
        min-height: calc(100vh - 88px);
        height: auto;
    }
    .home-content{
        @media screen and (max-width:767px){
            justify-content: center;
        }
    }
}
.tracking-profile{
    background-color: $theme-white-bg-color;
    @include border-radius(45px);
    padding: 40px 47px;
    min-width: 600px;
    @media screen and (max-width:1439px){
        padding: 32px 27px;
    }
    @media screen and (max-width:767px){
        padding: 22px 17px 40px;
        @include border-radius(35px);
        min-width: 100%;
    }
}
.t-profile-img{
    margin-right: 35px;
    @media screen and (max-width:1439px){
        margin-right: 20px;
    }
    span{
        img{
            height: 70px;
            width: 70px;
            @include border-radius(100%);
            @media screen and (max-width:1439px){
                height: 50px;
                width: 50px;
            }
        }
    }
}
.t-p-thanku-msg{
    display: flex;
    flex: 1;
    h3{
        margin-bottom: 0;
        font-size: 40px;
        font-weight: 700;
        word-break: break-all;
        @media screen and (max-width:1439px){
            font-size: 30px;
        }
        @media screen and (max-width:767px){
            font-size: 16px;
        }
    }
}
.order-tracking-detail {
	margin-top: 30px;
    // @media screen and (max-width:1439px){
    //     margin-top: -25px;
    // }
}
.o-t-btn{
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    .primary-btn{
        min-height: 70px;
        padding: 11px 40px;
        @media screen and (max-width:1439px){
            min-height: 50px;
        }
        @media screen and (max-width:767px){
            min-height: auto;
        }
        &:hover{
            background-color: var(--theme-primary-color);
            color: $theme-white-color;
        }
    }
}
.order-number{
    color: $theme-white-color;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    @media screen and (max-width:767px){
        font-size: 16px;
    }
    label{
        margin-bottom: 0;
    }
    span{
        display: block;
    }
}
.order-time{
    text-align: center;
    margin-top: 50px;
    @media screen and (max-width:767px){
        margin-top: 30px;
    }
    span{
        color: $theme-white-color;
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 15px;
        display: block;
        @media screen and (max-width:767px){
            font-size: 16px;
        }
    }
}
.time-show{
    background-color: $theme-white-bg-color;
    @include border-radius(20px);
    padding: 15px 30px;
    // max-width: 142px;
    margin: 0 auto;
    @media screen and (max-width:1439px){
        padding: 10px 30px;
    }
    label{
        display: block;
        font-size: 20px;
        font-weight: 700;
        color: var(--theme-primary-color);
        margin-bottom: 0;
        @media screen and (max-width:1439px){
            font-size: 22px;
        }
        @media screen and (max-width:767px){
            font-size: 20px;
        }
    }
}
.empty-cart{
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    max-width: 250px;
    margin: 0 auto;
}
.e-c-icon{
    svg{
        fill: $theme-black-color;
        opacity: 0.2;
    }
}
.e-c-text{
    text-align: center;
    margin-top: 10px;
    span{
        color: $theme-black-color;
        opacity: 0.2;
        font-size: 20px;
        font-weight: 500;
    }
}
.alert-popup{
    max-width: 525px;
    .modal-body-wrp{
        text-align: center;
    }
}
.alert-icon{
    svg{
        fill: var(--theme-primary-color);
    }
}
.alert-text{
    margin-top: 35px;
    p{
        color: var(--theme-primary-color);
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
    }
}

.back-button{
    display: none;
    @media screen and (max-width:1023px){
        display: block;
    }
}

.checkout-logo{
    display: block;
    @media screen and (max-width:1023px){
        display: none;
    }
}

.checkout-heder{
    display: none;
    @media screen and (max-width:1023px){
        display: block;
    }
}

// Order details page style

.order-number-section{
    background-color: var(--theme-primary-color);
    padding: 40px;
    @media screen and (max-width:767px){
        padding: 20px;
    }
}
.o-n-section-wrp{
    flex-direction: column;
    flex: 1;
    h3{
        font-size: 24px;
        font-weight: 600;
        color: $theme-white-color;
        margin-bottom: 15px;
        @media screen and (max-width:767px){
            font-size: 20px;
        }
    }
    .link-btn{
        color: $theme-white-color;
        font-weight: 500;
    }
}
.oder-no{
    background-color: $theme-white-bg-color;
    @include border-radius(10px);
    padding: 5px 15px;
    font-size: 28px;
    font-weight: 700;
    color: var(--theme-primary-color);
    margin-bottom: 30px;
    @media screen and (max-width:767px){
        font-size: 20px;
    }
}
.order-time-section{
    padding: 60px 20px;
    background-color: $theme-white-bg-color;
    @media screen and (max-width:767px){
        padding: 20px;
    }
}
.o-t-section-wrp{
    flex-direction: column;
    flex: 1;
    max-width: 750px;
    margin: 0 auto;
    h3{
        font-size: 28px;
        font-weight: 700;
        @media screen and (max-width:767px){
           font-size: 18px;
        }
    }
}
.order-add-time{
    position: relative;
    margin-top: 30px;
    @media screen and (max-width:767px){
        display: block !important;
        margin-top: 10px;
    }
}
.order-per{
    margin-right: 50px;

    @media screen and (max-width:767px){
        margin-right: 0;
    }
    &.order-a{
        margin-left: 50px;
        margin-right: 0;
        @media screen and (max-width:767px){
            margin-left: 0;
            margin-top: 50px;
        }
        &::before{
            content: '';
            position: absolute;
            left: 50%;
            top: 0;
            background-color: var(--theme-third-color);
            width: 3px;
            height: 100%;
            @media screen and (max-width:767px){
                height: 3px;
                width: 140px;
                top: 50%;
                left: 24%;
            }
        }
        &.o-only-address{
            margin-left: 0;
            &::before{
                display: none;
            }
        }
    }
    label{
        margin-bottom: 15px;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        color: var(--theme-primary-color);
        display: flex;
        justify-content: center;
    }
}
.number-box{
    background-color: $theme-white-bg-color;
    box-shadow: 0px 4px 34px -10px rgba(0, 0, 0, 0.15);
    @include border-radius(15px);
    padding: 15px 20px;
    width: 272px;
    min-height: 77px;
}
.n-box-icon{
    margin-right: 15px;
    span{
        background-color: var(--theme-primary-color);
        height: 27px;
        width: 27px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include border-radius(100%);
        svg{
            fill: $theme-white-color;
        }
    }
}
.n-box-details{
    color: var(--theme-primary-color);
    span{
        display: block;
        font-size: 18px;
        font-weight: 700;
        color: var(--theme-primary-color);
        @media screen and (max-width:767px){
            font-size: 16px;
        }
    }
    a{
        color: var(--theme-primary-color);
        font-size: 16px;
        font-weight: 500;
    }
    p{
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 0;
        line-height: 20px;
    }
}
.order-a{
    .number-box{
        max-width: 290px;
    }
}
.status-order-command{
    background-color: var(--theme-third-color);
    padding: 30px;
    .primary-btn{
        margin-top: 25px;
    }
}
.s-o-c-wrp{
    h3{
        font-size: 16px;
        font-weight: 500;
    }
}
.s-o-c-wrp{
    flex: 1;
    flex-direction: column;
}
.order-status-box{
    background-color: $theme-white-bg-color;
    box-shadow: 0px 4px 34px -10px rgba(0, 0, 0, 0.15);
    @include border-radius(28px);
    padding: 25px 45px;
    min-height: 132px;
    max-width: 500px;
    position: relative;
    @media screen and (max-width:767px){
        padding: 15px 15px;
        min-height: auto;
    }
    svg{
        fill: var(--theme-primary-color);
        // stroke: var(--theme-primary-color);
    }
    span{
        font-size: 20px;
        font-weight: 500;
        color: var(--theme-primary-color);
        margin-left: 20px;
        @media screen and (max-width:767px){
            font-size: 18px;
        }
        p{
            font-weight: 700;
            font-size: 20px;
            margin-bottom: 0;
            margin-top: 10px;
            line-height: 22px;
            @media screen and (max-width:767px){
                font-size: 18px;
            }
        }
    }
    .time{
        position: absolute;
        right: 20px;
        bottom: 10px;
        font-size: 14px;
        font-weight: 400;
        color: var(--theme-primary-color);
        @media screen and (max-width:767px){
            bottom: 0;
        }
    }
    &.opacity-down{
        opacity: 0.4;
    }
}
.remain-disposal{
    padding: 80px 20px;
    background-color: $theme-white-bg-color;
    @media screen and (max-width:767px){
        padding: 20px 20px;
    }
}
.r-d-wrp{
    flex: 1;
    flex-direction: column;
    h3{
        font-size: 18px;
    }
}
.da-gabriele{
    border: 3px solid var(--theme-third-color);
    @include border-radius(30px);
    padding: 17px 30px;
    text-align: center;
    h3{
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 10px;
        @media screen and (max-width:767px){
            font-size: 18px;
        }
    }
    p{
        color: var(--theme-primary-color);
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 20px;
        @media screen and (max-width:767px){
            font-size: 16px;
        }
    }
    .da-phone{
        font-size: 24px;
        font-weight: 500;
        color: var(--theme-primary-color);
        @media screen and (max-width:767px){
            font-size: 20px;
        }
        span{
            height: 37px;
            width: 37px;
            background-color: var(--theme-third-color);
            display: flex;
            align-items: center;
            justify-content: center;
            @include border-radius(100%);
            margin-right: 10px;
            svg{
                fill: var(--theme-primary-color);
            }
        }
    }
}
.powered-by-hop{
    text-align: center;
    background-color: $theme-gray-color7;
    padding: 12px 20px;
    span{
        color: $theme-gray-color16;
        font-size: 16px;
        font-weight: 500;
        svg{
            margin-left: 10px;
        }
    }
}
.oder-detail-page{
    .page-content-wrp{
        margin-bottom: 0;
    }
}
.a-o-s-wrp{
    flex: 1;
    flex-direction: column;
    h3{
        font-size: 16px;
        font-weight: 500;
    }
}
.alert-order-status{
    background-color: var(--theme-third-color);
    padding: 40px 20px;
}
.alert-msg-box{
    max-width: 560px;
    background-color: $theme-white-bg-color;
    @include border-radius(28px);
    text-align: center;
    padding: 30px;
    color: var(--theme-primary-color);
    svg{
        fill: var(--theme-primary-color);
    }
    p{
        font-size: 22px;
        font-weight: 500;
        margin: 26px 0 20px;
        @media screen and (max-width:767px){
            font-size: 18px;
        }
    }
    span{
        font-size: 14px;
        font-weight: 400;
    }
}
.order-reason-section{
    background-color: $theme-white-bg-color;
    padding: 40px 20px 20px;
    text-align: center;
    border-bottom: 2px solid var(--theme-third-color);
}
.o-r-wrp{
    flex: 1;
    flex-direction: column;
}
.reason-alert-box{
    border: 2px solid var(--theme-third-color);
    @include border-radius(18px);
    margin-bottom: 40px;
    padding: 15px 35px;
    max-width: 320px;
    text-align: center;
    p{
        margin-bottom: 0;
        font-size: 16px;
        font-weight: 400;
        color: var(--theme-primary-color);
        line-height: 22px;
    }
}
.reason-content{
    max-width: 600px;
    p{
        line-height: 24px;
        color: var(--theme-primary-color);
        font-size: 18px;
        font-weight: 500;
        @media screen and (max-width:767px){
            font-size: 16px;
            line-height: 22px;
        }
    }
}
.cancel-order-form{
    ul{
        padding: 0;
        margin: 0;
    }
    li{
        margin-top: 10px;
        padding: 18px 10px;
        background-color: var(--theme-third-color);
        @include border-radius(10px);
        text-align: left;
        border: 2px solid transparent;
        &:first-child{
            margin-top: 0;
        }
        &.active{
            border-color: var(--theme-primary-color);
        }
        .check_lb{
            padding-left: 50px;
            p{
                margin-bottom: 0;
                color: var(--theme-primary-color);
                font-size: 15px;
                font-weight: 500;
            }
        }
        .checkmark{
            &.sign_in_checkmak{
                height: 36px;
                width: 36px;
                margin-top: -18px;
                &::after{
                    border-width: 0 3px 3px 0 !important;
                    left: 12px !important;
                    top: 6px !important;
                    width: 12px !important;
                    height: 20px !important;
                }
            }
        }
        textarea{
            background-color: $theme-white-bg-color;
            @include border-radius(10px);
            margin-top: 10px;
            font-size: 12px;
            margin-left: 50px;
            max-width: 290px;
            padding: 10px 10px;
            @media screen and (max-width:767px){
                max-width: 210px;
            }
            &::-webkit-input-placeholder{
                color: $theme-gray-color4;
            }
            &::-moz-placeholder{
                color: $theme-gray-color4;
            }
            &::-ms-input-placeholder{
                color: $theme-gray-color4;
            }
        }
    }
}
.cancel-order-popup{
    h3{
        font-size: 18px;
        text-align: center;
    }
    .modal-body-wrp {
        margin-top: 50px;
    }
}
.o-d-e-cart-popup{
    .c-pro-tag{
        margin-top: 0;
    }
    .cart-product-list{
        margin-bottom: 30px;
    }
}

.moa-popup{
    .modal-body-wrp{
        text-align: center;
    }
    .popup-title{
        h2{
            font-size: 22px;
            font-weight: 700;
            margin-top: 15px;
            line-height: 30px;
            @media screen and (max-width:767px){
                font-size: 18px;
                line-height: normal;
            }
            label{
                margin-bottom: 0;
                display: block;
            }
        }
    }
    .title-text{
        font-size: 16px;
        font-weight: 600;
        color: var(--theme-primary-color);
    }
}
.new-user-signup-popup{
    .modal-body-wrp{
        text-align: center;
        padding: 45px;
        @media screen and (max-width:767px){
            padding: 25px;
        }
    }
    .modal-content{
        padding: 0;
    }

    .popup-title{
        h2{
            font-size: 22px;
            font-weight: 700;
            margin-top: 15px;
            @media screen and (max-width:767px){
                font-size: 18px;
                line-height: normal;
            }
        }
    }
    .title-text{
        color: var(--theme-primary-color);
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
        @media screen and (max-width:767px){
            font-size: 14px;
        }
    }
}
.powered-by-popup{
    text-align: center;
    border-top: 2px solid $theme-border-color;
    padding: 15px;
    p{
        color: $theme-black-color;
        opacity: 0.3;
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 0;
        svg{
            margin-left: 5px;
        }
    }
}
.user-already-account-popup{
    .primary-btn{
        width: 70%;
        @media screen and (max-width:767px){
            width: 100%;
        }
    }
    .powered-by-popup{
        text-align: left;
        padding: 25px 14px;
        p{
            font-weight: 400;
            font-size: 14px;
            color: var(--theme-primary-color);
            opacity: 1;
            line-height: 18px;
            span{
                margin-right: 5px;
                svg{
                    @media screen and (max-width:767px){
                       height: 24px;
                       width: 24px;
                    }
                }
            }
        }
    }
}
.send-new-pass{
    .title-text{
        margin-top: 30px;
        line-height: 24px;
    }
}


.alert-msg{
  color: red;
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px;
  display: block;
}
.store-close{
    span{
        margin-bottom: 15px;
        display: block;
    }
    h4{
        color: $theme-gray-color18;
        font-size: 26px;
        margin-bottom: 0;
        @media screen and (max-width:767px){
            font-size: 22px;
        }
    }
    .home-title{
        border-bottom: none;
    }
    .home-cont-mid{
        flex-direction: column;
        h3{
            color: $theme-popular-tag-color;
            font-size: 18px;
            font-weight: 600;
            max-width: 280px;
            margin: 0 auto;
            margin-bottom: 20px;
            @media screen and (max-width:767px){
                font-size: 16px;
            }
        }
    }
}
.command-not-available{
    padding: 0 20px 30px;
    border-bottom: 2px solid $theme-border-color;
    p{
        background-color: $theme-alert-bg;
        color: $theme-alert-color;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 0;
        padding: 10px 20px;
        @include border-radius(10px);
    }
}
.stop_preorder{
    max-width: 313px;
    margin: 0 auto;
    p{
        font-weight: 600;
        font-size: 18px;
        color: #888888;
        margin-top: 30px;
        margin-bottom: 0;
        line-height: 1.4;
    }
}

.stop_order_p{
    color: #888888;
    /* margin-top: 30px; */
    margin-bottom: 0;
    line-height: 1.4;
    font-weight: 600;
    font-size: 14px;
}

.p-i-img{
    position: relative;
    &:hover{
        .agrandir-img-btn{
            opacity: 1;
            overflow: visible;
            bottom: 10px;
            transform: none;
        }
    }
}
.agrandir-img-btn{
    position: absolute;
    bottom: -20px;
    right: 10px;
    opacity: 0;
    overflow: hidden;
    transition: .3s;
    // transition: transform 0.3s ease-out;
    .primary-btn{
        background-color: $theme-white-bg-color;
        color: $theme-black-color;
        font-size: 14px;
        font-weight: 400;
        padding: 6px 22px;
        svg{
            fill: $theme-black-color;
        }
    }
}
.product-image-preview{
    max-width: auto;
    .modal-content{
        padding: 0;
        text-align: center;
        @include border-radius(0);
    }
    .modal-body-wrp{
        margin-top: 0;
    }
    .modal-content .close.with-name{
        span{
            background-color: var(--theme-primary-color);
            label{
                color: $theme-white-color;
            }
            svg{
                fill: $theme-white-color;
            }
        }

    }
    .p-item-preview-img{
        img{
            width: 100%;
        }
        @media screen and (max-width:767px){
            padding: 0 20px;
        }
    }
}

.custom-text {
    max-width: 340px;
    margin: 0 auto;
    margin-bottom: 0;
    color: #6F6F6F;
    line-height: 22px;
}

.pay{
    text-align: center;
    font-size: 16px !important;
    font-weight: 600;
    margin-top: 22px;
    margin-bottom: 10px;
}

.table_text1{
    background: var(--theme-third-color);
    color: var(--theme-primary-color);
    padding: 10px;
    border-radius: 12px;
    margin-bottom: 20px;
}
