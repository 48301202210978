// common variables

:root{
    --theme-primary-color: #F8004A;
    --theme-secondary-color: #fc99b7;
    --theme-third-color: #fef2f6;
    --theme-fourth-color: #fef2f6;
    --theme-body-color: #000000;
    --body-fonts-typo:'Epilogue', sans-serif;
}
// $body-fonts-typo:'Epilogue', sans-serif;
$theme-body-bg:#f2f2f2;


// Theme white color 
$theme-white-color: #ffffff;
$theme-white-bg-color: #ffffff;

// Theme black color 
$theme-black-color: #000000;
$theme-black-bg-color: #000000;

// Theme border color 
$theme-border-color: #F4F4F4;
$theme-border-color2:#DADADA;
$theme-border-color3:#F2F2F2;

// Theme green color 
$theme-green-color: #00D254;

// Theme red color 
$theme-red-color: #FF000F;
$theme-red-color2:#FF0303;
$theme-red-color3:#DF0043;
$theme-red-color4:#FFEBEB;
$theme-red-color5:#FFD0D0;

// Theme gray color 
$theme-gray-color1: #969696;
$theme-gray-color2:#989898;
$theme-gray-color3:#E6E6E6;
$theme-gray-color4:#C4C4C4;
$theme-gray-color5: #808080;
$theme-gray-color6:#888888;
$theme-gray-color7: #F6F6F6;
$theme-gray-color8:#828282;
$theme-gray-color9:#919191;
$theme-gray-color10:#B8B8B8;
$theme-gray-color11:#959595;
$theme-gray-color12:#EAEAEA;
$theme-gray-color13:#DCDCDC;
$theme-gray-color14: #F0F0F0;
$theme-gray-color15:#DFDFDF;
$theme-gray-color16: #9B9B9B;
$theme-gray-color17: #707070;
$theme-gray-color18:#C1C1C1;

// Theme yellow color 
$theme-light-yellow-color:#FFF2E7;
$theme-yello-color2:#AD3E00;

// Theme gray color btn 
$theme-disable-btn-bg:#DEDEDE;
$theme-disable-btn-color:#9F9F9F;

// form control bg 
$theme-form-field-bg: #F7F7F7;

// Theme dropdown color 
$theme-dropdown-bg:#F1F1F1;

// Theme blue color 
$theme-blue-bg:#e1f0ff;
$theme-blue-color: #0A89FF;
$theme-blue-color2:#0F5176;

// Theme tag color 
$theme-popular-tag-color:#82CFFB;
$theme-per-tag-bg:#FFEBD9;
$theme-per-tag-color:#AD3E00;

// Theme alert box color 
$theme-alert-bg: #FFECEE;
$theme-alert-color:#F24E61;

// Theme green color 
$theme-green-color: #4CEC8C;

// account page
$theme-blue-shad4: #68C2F4;

// Theme Mixins
@mixin box-shadow($shadow) {
    -webkit-box-shadow: $shadow;
    -moz-box-shadow: $shadow;
    box-shadow: $shadow;
}

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius;
}


