// Common style start 


html { -webkit-font-smoothing:antialiased; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; scroll-behavior: smooth; }
body { -webkit-font-smoothing: subpixel-antialiased; -webkit-text-stroke-width: 0; -moz-osx-font-smoothing: auto; padding: 0; font-size: 16px; color: var(--theme-body-color); font-family: var(--body-fonts-typo);background-color: $theme-body-bg;
}
body{
    *{
      outline: none !important;
    }
  }

input[type="text"], input[type="password"], input[type="date"], input[type="datetime"], input[type="datetime-local"],
input[type="month"], input[type="week"], input[type="email"], input[type="number"], input[type="search"], input[type="tel"],
input[type="time"], input[type="url"], input[type="color"], textarea{ outline: none; }
textarea { resize: vertical;}
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6{
  margin: 0 0 30px 0; padding: 0; font-weight: 600; color: var(--theme-primary-color);
  @media only screen and (max-width: 767px) {
    margin-bottom: 20px;
  }
}

h1, .h1{
  font-size: 45px;
  @media only screen and (max-width: 1279px) {
    font-size: 40px;
  }
  @media only screen and (max-width: 1023px) {
    font-size: 34px;
  }
  @media only screen and (max-width: 767px) {
    font-size: 30px;
  }
}
h2, .h2{
  font-size: 32px;
  @media only screen and (max-width: 1279px) {
    font-size: 22px;
  }
  @media only screen and (max-width: 1023px) {
    font-size: 20px;
  }
}
h3, .h3{
  font-size: 24px;
  @media only screen and (max-width: 1279px) {
    font-size: 20px;
  }
}
h4, .h4{font-size: 16px;}
h5, .h5{font-size: 16px;}
h6, .h6{font-size: 12px;}
p{ 
  font-size: 16px; line-height: normal; margin: 0 0 30px 0; font-weight: normal;
  @media only screen and (max-width: 1279px) {
    margin-bottom: 15px;
  }
  @media only screen and (max-width: 767px) {
    font-size: 14px;
  }
}
a, .link{ outline: none; text-decoration: none; transition: all .3s; color: var(--theme-primary-color); cursor: pointer;
  &:hover{
	transition: all .3s;
    color: var(--theme-secondary-color);
    text-decoration: none;
  }
}
img{
    max-width: 100%;
}
ul, ol{
  margin-bottom: 30px; margin-left: 0px;
  li{
	  line-height: 18px; margin-bottom: 15px; list-style: none;
  }
}
.container-full{
    max-width: 100%;
    margin: 0 auto;
}
.container{
  max-width: 1440px;
  margin: 0 auto;
  @media only screen and (max-width: 1279px) {
    max-width: 100%;
  }
}
.p-container{
    padding: 0 82px;
    @media only screen and (max-width: 1439px) {
      padding: 0 20px;
    }
}

.hide{
  display: none !important;
}
.show-568{
  display: none;
}
@media only screen and (max-width: 767px) {
  .hide-568{
    display: none;
  }
  .show-568{
    display: inline-flex;
  }
}

svg{
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
}

// common button style 

.primary-btn{
  background-color: var(--theme-primary-color);
  color: $theme-white-color;
  font-size: 18px;
  font-weight: 600;
  padding: 11px 22px;
  @include border-radius(45px);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid transparent;
  transition: all .3s;
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  line-height: normal;
  @media only screen and (max-width: 1279px) {
    font-size: 16px;
    padding: 8px 10px;
  }
  // @media only screen and (max-width: 767px) {
  //   font-size:18px;
  // }
  svg{
    fill: $theme-white-color;
    margin-left: 10px;
    @media only screen and (max-width: 1279px) {
      margin-left: 5px;
    }
  }
  &:hover{
    background-color: var(--theme-third-color);
    color: var(--theme-primary-color);
    svg{
      fill: var(--theme-primary-color);
    }
  }
  &.svg-left{
    svg{
      margin-left: 0;
      margin-right: 10px;
      @media only screen and (max-width: 1279px) {
        margin-right: 5px;
      }
    }
  }
  &.secondary-btn{
    background-color: var(--theme-third-color);
    color: var(--theme-primary-color);
    svg{
      fill: var(--theme-primary-color);
    }
    &:hover{
      background-color: var(--theme-primary-color);
      color: $theme-white-color;
      svg{
        fill: $theme-white-color;
      }
    }
  }
  &.disable-btn{
    background-color: $theme-disable-btn-bg;
    color: $theme-disable-btn-color;
    cursor: not-allowed;
    &:hover{
      background-color: $theme-disable-btn-bg;
    color: $theme-disable-btn-color;
    }
  } 
  &.border-btn{
    border-color: var(--theme-third-color);
    color: var(--theme-primary-color);
    background-color: $theme-white-bg-color;
    svg{
      fill: var(--theme-primary-color);
    }
    &:hover{
      border-color: var(--theme-primary-color);
      color: $theme-white-color;
      background-color: var(--theme-primary-color);
      svg{
        fill: $theme-white-color;
      }
    }
  }
}
.link-btn{
  color: var(--theme-primary-color);
  text-decoration: underline;
  background-color: transparent;
  padding: 0;
  @include border-radius(0);
  &:hover{
    color: var(--theme-secondary-color);
    text-decoration: underline;
    background-color: transparent;
  }
}

// scroll 

.v-scroll-hide{
  -ms-overflow-style: none; 
    scrollbar-width: none; 
}
.v-scroll-hide::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar {
  width: 7px;
  height: 8px;
  background-color: white;
}
::-webkit-scrollbar-thumb {
  border-radius: 0px;
  -webkit-box-shadow: inset 10px 10px 5px 0px rgba(225,225,225,0.75);
}
* {
  scrollbar-width: thin;
  scrollbar-color: #E1E1E1 white;
}

// Date picker style 

// .bs-datepicker{
//   border-radius: 30px !important;
// }
// .theme-green .bs-datepicker-head{
//   background-color: var(--theme-primary-color) !important;
//   border-radius: 20px 20px 0 0 !important;
// }
// .bs-datepicker-body{
//   border-radius:0 0  20px 20px !important;
// }

// .theme-green .bs-datepicker-body table td.week span{
//   color: var(--theme-primary-color) !important;
// }
// .theme-green .bs-datepicker-body table td span.selected, .theme-green .bs-datepicker-body table td.selected span, .theme-green .bs-datepicker-body table td span[class*="select-"]:after, .theme-green .bs-datepicker-body table td[class*="select-"] span:after{
//   background-color: var(--theme-primary-color) !important;
// }

// .section-title{
//   text-align: center;
//   font-size: 34px;
//   font-weight: bold;
//   margin-bottom: 50px;
//   @media only screen and (max-width: 1279px) {
//     font-size: 26px;
//     margin-bottom: 30px;
//   }
//   @media only screen and (max-width: 767px) {
//     font-size: 24px;
//     margin-bottom: 25px;
//   }
// }

// @media only screen and (max-width: 767px) {
//   .b-m-t-20{
//     margin-top: 20px;
//   }
// }

// .m-t-20-768{
//   @media only screen and (max-width: 1023px) {
//     margin-top: 20px;
//   }
//   @media only screen and (max-width: 767px) {
//     margin-top: 15px;
//   }
// }

// @media only screen and (max-width: 1279px) {
//   .m-t-20-1024{
//     margin-top: 20px;
//   }
// }
// @media only screen and (max-width: 767px) {
//   .m-t-20-568{
//     margin-top: 20px;
//   }
// }

// @media only screen and (max-width: 767px) {
//   .flex-none-767{
//     display: block !important;
//   }
// }
// .m-b-20{
//   margin-bottom: 20px;
// }
// .m-b-25{
//   margin-bottom: 25px;
//   @media only screen and (max-width: 767px) {
//     margin-bottom: 20px;
//   }
// }
// .m-t-30{
//   margin-top: 30px;
//   @media only screen and (max-width: 767px) {
//     margin-top: 20px;
//   }
// }
// .m-b-0{
//   margin-bottom: 0;
// }

.m-t-15{
  margin-top: 15px;
}
.m-b-30{
  margin-bottom: 30px;
    @media only screen and (max-width: 767px) {
        margin-bottom: 10px;
    }
}
.m-b-25{
  margin-bottom: 25px;
     @media only screen and (max-width: 767px) {
        margin-bottom: 20px;
      }
}
.m-l-15{
  margin-left: 15px;
}
.summary-content-list.mob-border-none{
  @media only screen and (max-width: 1023px) {
    border: none;
  }
}
.m-t-0{
  margin-top: 0 !important;
}
.p-r{
  position: relative;
}

// form style 

.form-control{
  background-color: $theme-form-field-bg;
  padding: 10px 24px;
  min-height: 50px;
  @include border-radius(18px);
  border: none;
  font-size: 16px;
  color: $theme-black-color;
  font-weight: 500;
  @media only screen and (max-width: 1279px) {
    padding: 5px 20px;
    min-height: 47px;
  }
  &::-webkit-input-placeholder{
    color: $theme-gray-color4;
  }
  &::-moz-placeholder{
      color: $theme-gray-color4;
  }
  &::-ms-input-placeholder{
      color: $theme-gray-color4;
  }
  &.error{
    border-color: $theme-red-color2;
    &::-webkit-input-placeholder{
      color: $theme-red-color2;
    }
    &::-moz-placeholder{
        color: $theme-red-color2;
    }
    &::-ms-input-placeholder{
        color: $theme-red-color2;
    }
  }
}

// switch btn 

.tgl {
	display: none;
  
	// add default box-sizing for this scope
	&,
  &:after,
  &:before,
	& *,
  & *:after,
  & *:before,
	& + .tgl-btn {
		box-sizing: border-box;
		&::selection {
			background: none;
		}
	}
  
	+ .tgl-btn {
		outline: 0;
		display: block;
		width: 47px;
		height: 26px;
		position: relative;
		cursor: pointer;
    user-select: none;
		&:after,
    &:before {
			position: relative;
			display: block;
			content: "";
			width: 50%;
			height: 100%;
		}
    
		&:after {
			left: 0;
		}
    
		&:before {
			display: none;
		}
	}
  
	&:checked + .tgl-btn:after {
		left: 50%;
	}
}
.tgl-btn{
  margin-bottom: 0;
}

// themes
.tgl-light {
	+ .tgl-btn {
		background: #f0f0f0;
		border-radius: 2em;
		padding: 2px 3px;
    transition: all .4s ease;
    border: 1px solid #E8E8E8;
		&:after {
			border-radius: 50%;
			background: $theme-white-bg-color;
			transition: all .2s ease;
		}
	}
  
	&:checked + .tgl-btn {
		background: $theme-green-color;
	}
}

// checkboc custom style 

.checkmark {
  position: absolute;
  left: 0;
  top: 0;
  background-color: $theme-black-color;
  height: 76px;
  width: 76px;
  @include border-radius(100%);
  z-index: 0;
  @media only screen and (max-width: 1279px) {
      height: 48px;
      width: 48px;
  }
  &.sign_in_checkmak {
      height: 76px;
      width: 76px;
      border-radius: 50%;
      top: 50%;
      margin-top: -38px;
      background: $theme-white-bg-color !important;
      border: 0;
      box-shadow: 0px 2px 15px -5px rgba(15, 81, 118, 0.2);
      @media only screen and (max-width: 1279px) {
        height: 48px;
        width: 48px;
        margin-top: -24px;
    }
  }
  &:after {
      content: "";
      position: absolute;
      display: none;
  }
}
.check_lb{
  position: relative;
  padding-left: 100px;
  cursor: pointer;
  user-select: none;
  width: 100%;
  font-weight: bold;
  color: $theme-gray-color9;
  margin-bottom: 0;
  @media only screen and (max-width: 1279px) {
    padding-left: 60px;
  }
  a{
      color: $theme-black-color;
      font-size: 18px;
      font-weight: bold;
      line-height: 26px;
      text-decoration: underline;
      @media only screen and (max-width: 1279px) {
        font-size: 16px;
      }
  }
  &.check-square{
    padding-left: 0;
    .checkmark{
      height: 24px;
      width: 24px;
      background: var(--theme-primary-color);
      @include border-radius(5px);
      &::after{
        color: $theme-white-color;
        border-color: $theme-white-color;
        left: 7px;
        top: 3px;
      }
    }
    input:checked ~ .checkmark{
      background-color:var(--theme-primary-color);
      border-color: var(--theme-primary-color);
    }
  }
}
.check_lb input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 0;
}
.check_lb input:checked~.checkmark {
  background-color: transparent;
  border-color: transparent;
}
.check_lb input:checked~.checkmark:after {
  display: block;
}
.check_lb .checkmark:after {
  left: 6px;
  top: 1px;
  width: 9px;
  height: 14px;
  border: solid var(--theme-primary-color);
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  color: var(--theme-primary-color);
}
.sign_in_checkmak:after {
  left: 27px!important;
  top: 13px!important;
  width: 22px!important;
  height: 40px!important;
  border: solid!important;
  border-width: 0 5px 5px 0!important;
  @media only screen and (max-width: 1279px) {
    left: 16px !important;
    top: 7px !important;
    width: 15px !important;
    height: 28px !important;
  }
}


// Popup common style 

.modal-body{
  padding: 0
}
.modal-content {
	@include border-radius(40px);
	padding: 45px;
	border: none;
  @media only screen and (max-width: 1279px) {
    padding: 35px;
  }
  @media only screen and (max-width: 767px) {
    padding: 25px;
  }
}
.modal-header {
	border: none;
	padding: 0;
	margin-bottom: 30px;
    &.no-header{
      margin-bottom: 0;
    }
}
.modal-content{
  .close {
    height: 48px;
    width: 48px;
    background-color: var(--theme-third-color);
    border-radius: 100%;
    line-height: 18px;
    text-shadow: none;
    opacity: 1;
    position: absolute;
    right: 30px;
    top: 30px;
    z-index: 99;
    @media only screen and (max-width: 1279px) {
      height: 40px;
      width: 40px;
      right: 30px;
      top: 30px;
    }
    @media only screen and (max-width: 767px) {
      right: 20px;
      top: 10px;
    }
    span{
      // font-size: 40px;
      font-weight: bold;
      color: var(--theme-primary-color);
      opacity: 1;
      @media only screen and (max-width: 1279px) {
        font-size: 30px;
      }
      svg{
        fill: var(--theme-primary-color);
      }
    }
    &.with-name{
      height: auto;
      width: auto;
      @include border-radius(0);
      background-color: transparent;
      // position: sticky;
      // top: 0;
      // right: 0;
      // justify-content: end;
      label{
        margin-bottom: 0;
        color: var(--theme-primary-color);
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;
      }
      span{
        padding: 10px 10px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        min-width: 116px;
        max-height: 40px;
        background-color: var(--theme-third-color);
        @include border-radius(30px);
        svg{
          fill: var(--theme-primary-color);
          margin-left: 10px;
        }
        &:hover{
          background-color: var(--theme-primary-color);
          svg{
            fill: $theme-white-color;
          }
          label{
            color: $theme-white-color;
          }
        }
      }
    }
  }
} 
// .modal-body-wrp{
//   margin-top: 30px;
// }
.modal-body-wrp{
  margin-top: 30px;
}
.popup-mid{
  max-width: 475px;
  @media only screen and (max-width: 767px) {
    max-width: 100%;
    padding: 0 10px;
  }
}
.popup-lg{
  max-width: 604px;
  @media only screen and (max-width: 1023px) {
    max-width: 100%;
    padding: 0 10px;
  }
}
.popup-full{
  max-width: 100%;
  padding: 0 40px;
  @media only screen and (max-width: 1023px) {
    padding: 10px;
  }
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
	opacity: 1;
}
.modal-footer{
  border-top: none;
  justify-content: center;
}


// Progress bar style 

.bg-warning{
  background-color: var(--theme-secondary-color) !important;
  @include border-radius(0 10px 10px 0px);
}
.progress{
  background-color: $theme-white-color;
  height: 10px !important;
}

.progress-bar-top {
  max-width: 317px;
  margin: 0 auto;
}


// Header account style 

.login-btn{
  position: relative;
  .primary-btn{
    &::before{
      content: '';
      position: absolute;
      bottom: -19px;
      left: 0;
      width: 100%;
      height: 20px;
      // @media only screen and (max-width: 767px) {
      //   top: 58px;
      //   height: 20px;
      // }
    }
  }
  &:hover{
    .account-dropdown{
      display: block;
    }
  }
}

.account-dropdown{
  position: absolute;
  top: 130%;
  right: 0;
  z-index: 999;
  display: none;
  animation: growDown 300ms ease-in-out forwards;
  transform-origin: top center;
  ul{
    background: $theme-white-bg-color;
    width: 237px;
    @include border-radius(15px);
    padding: 15px 31px;
    box-shadow: 0 0 5px 0 rgba(0,0,0,.1);
    // margin-top: 8px;
  }
  li{
    margin-top: 20px;
    &:first-child{
      margin-top: 0;
    }
    a{
      font-size: 20px;
      color: $theme-gray-color11;
      display: block;
      @media only screen and (max-width: 767px) {
        font-size: 18px;
      }
      &:hover{
        color: var(--theme-primary-color);
        svg{
          fill: var(--theme-primary-color);
        }
      }
      svg{
        margin-right: 15px;
      }
    }
  }
}

.validation_error {color: $theme-red-color; text-align: left; font-size: 10px;top: 50px; left: 0;margin-left: 0px; margin-bottom: 15px;}

@keyframes growDown {

  0% {

    transform: scaleY(0)

  }

  80% {

    transform: scaleY(1.1)

  }

  100% {

    transform: scaleY(1)

  }

}
// for toast notification

.toast-container .ngx-toastr {
    position: relative;
    overflow: hidden;
    margin: 0 0 6px;
    padding: 15px 15px 15px 50px;
    color: #fff;
    width: 400px;
    font-weight: 600;
}


#toast-container>.toast-success {
  color: #0E6932;
  background: #4CEC8C;
  background-repeat: no-repeat;
  background-position: 15px center;
  background-size: 24px;
  border-radius: 15px;
  background-image: url(../assets/images/grommet-icons_validate.png)!important;
}

#toast-container>.toast-error {
  color: #fff;
  background: #FF495F;
  background-repeat: no-repeat;
  background-position: 15px center;
  background-size: 24px;
  border-radius: 15px;
  background-image: url(../assets/images/bx_bxs-error.png)!important;
}
///

.alert-msg{
  color: $theme-red-color;
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px;
  display: block;
}
.modal{
  z-index: 9999;
}