.rtl{
    direction: rtl;
    .ml-auto, .mx-auto {
        margin-left: 0 !important;
        margin-right: auto !important;
    }
}
.rtl{
    .primary-btn.svg-left svg{
        margin-right: 0;
        margin-left: 10px;
        @media only screen and (max-width: 767px) {
            margin-left: 5px;
        }
    }
    .primary-btn svg{
        margin-left: 0;
        margin-right: 10px;
        @media only screen and (max-width: 767px) {
            margin-right: 5px;
        }
    }
    .add-control .form-control-svg{
        right: auto;
        left: 26px;
    }
    .deli-add-bottom{
        text-align: right;
    }
    .date-dropdown.hour-dropdown{
        margin-left: 0;
        margin-right: 20px;
    }
    .open-t-b-w .open-heading{
        margin-left: 20px;
        margin-right: 10px;
        @media only screen and (max-width: 767px) {
            margin-left: 10px;
        }
    }
    .email-btn .primary-btn span svg{
        margin: 0;
    }
    .email-btn .primary-btn span{
        margin-right: 0;
        margin-left: 10px;
        @media only screen and (max-width: 767px) {
            margin-left: 5px;
        }
    }
    .add-control .form-control{
        padding-left: 60px;
        padding-right: 20px;
    }
    .modal-content .close{
        right: auto;
        left: 30px;
    }
    .form-label{
        text-align: right;
    }
    .pass-show-icon{
        right: auto;
        left: 30px;
    }
    .forgot-pass{
        text-align: right;
    }
    .check_lb{
        padding-left: 0;
        padding-right: 100px;
        text-align: right;
        @media only screen and (max-width: 1279px) {
            padding-right: 60px;
        }
    }
    .checkmark{
        left: auto;
        right: 0;
    }
    .r-o-total {
        text-align: left;
    }
    .r-oder-details, .o-c-d-left{
        text-align: right;
    }
    .modal-content .close.with-name span svg{
        margin-left: 0;
        margin-right: 10px;
    }
    .product-options-list{
        text-align: right;
    }
    .p-variant-list .check_lb{
        padding-right: 46px;
    }.product-item-popup .popup-footer-btn label{
        margin-left: 0;
        margin-right: 10px;
    }
}

// Shop page 

.rtl{
    .delivery-options-list{
        margin-left: 0;
        margin-right: 88px;
        @media only screen and (max-width: 1279px) {
            margin-left: 20px;
            margin-right: 20px;
        }
        @media only screen and (max-width: 567px) {
            margin-left: 10px;
            margin-right: 10px;
        }
    }
    .menu-plus{
        border-right: none;
        padding-right: 0;
        margin-right: 0;
        border-left: 2px solid #F4F4F4;
        padding-left: 25px;
        margin-left: 25px;
        @media only screen and (max-width: 767px) {
            padding-left: 0;
            margin-left: 0;
            border-left: none;
        }
    }
    .menu-cart-btn .primary-btn span{
        border-left: none;
        border-right: 2px solid $theme-red-color3;
        @media only screen and (max-width: 767px) {
            border: none;
        }
    }
    .menu-hori-main .nav-tabs{
        padding-right: 50px;
        padding-left: 0;
        @media only screen and (max-width: 1279px) {
            padding: 13px 20px;
        }
    }
    .product-item-list h2{
        text-align: right;
    }
    .product-img{
        padding-left: 0;
        padding-right: 15px;
    }
    .pro-tag-list{
        text-align: left;
        left: 4px;
        right: auto;
    }
    .product-content{
        text-align: right;
    }
    .footer-close{
        left: 20px;
        right: auto;
    }
    .deli-optiond-wrp li{
        border-right: 2px solid $theme-form-field-bg;
        border-left: none;
        &:first-child{
            border-right: none;
        }
    }
    .deli-list-dropdown{
        left: auto;
        right: 0;
    }
    .deli-optiond-wrp li svg{
        margin-right: 0;
        margin-left: 15px;
        @media only screen and (max-width: 1279px) {
            margin-left: 10px;
        }
        @media only screen and (max-width: 1023px) {
            margin-left: 0;
        }
    }
    .close{
        float: left;
    }
    .d-l-block{
        border-right: 2px solid $theme-border-color3;
        border-left: none;
        &:first-child{
            border-right: none;
        }
    }
    .deli-type-btn .primary-btn svg{
        margin-right: 10px;
        margin-left: 10px;
    }
    .add-control.shop-add-control .form-control.error{
        padding-right: 40px;
        padding-left: 60px;
    }
    .add-control.shop-add-control .form-control-svg.location{
        right: 20px;
        left: auto;
    }
    .add-con-dropdown-list h3, .block-title h3, .d-alert-msg p{
        text-align: right;
    }
    .shop-d-h .date-dropdown .d-dropdown svg{
        margin-right: auto;
        margin-left: 10px;
    }
    .menu-plus ul{
        right: auto;
        left: 20px;
        li{
            text-align: right;
        }
    }
    .c-drop-footer{
        right: auto;
    }
    .cart-dropdown{
        right: auto;
        left: -999px;
    }
    .cart-dropdown-open .cart-dropdown{
        left: 0;
    }
    .c-product-price{
        text-align: left;
    }
    .menu-cart-btn .primary-btn .count-pill{
        right: auto;
        left: 13px;
    }
    .c-pro-dec p{
        text-align: right;
    }
    .c-p-list-box{
        .link-btn{
            display: block;
            text-align: right;
        }
    }
    .c-drop-footer .primary-btn span{
        margin-right: 10px;
        margin-left: 0;
    }
}
.rtl{
    .login-btn .primary-btn.only-svg svg{
        margin-left: 0;
    }
    .t-b-l .primary-btn.second{
        margin-left: 0;
        margin-right: 10px;
    }
    .account-dropdown ul{
        text-align: right;
    }
    .account-dropdown li a svg{
        margin-right: 0;
        margin-left: 15px;
    }
    .home-content .login-btn{
        right: auto;
        left: 30px;
        @media only screen and (max-width: 767px) {
            left: auto;
        }
    }
    .account-dropdown{
        right: auto;
        left: 0;
    }
    .shop-footer{
        left: auto;
        right: 0;
        @media only screen and (max-width: 767px) {
            left: 12px;
            right: auto;
        }
    }
    .p-t-with-btn .available-from{
        margin-left: 0;
        margin-right: 15px;
    }
    .pro-strike-price{
        margin-left: 10px;
        margin-right: 0;
    }
    .m-l-15{
        margin-left: 0;
        margin-right: 15px;
    }
    .paymetn-means .another-card{
        right: auto;
        left: 12px;
    }
    .paymetn-means .deli-type-btn .primary-btn .btn-mark{
        margin-right: 0;
        margin-left: 20px;
        @media only screen and (max-width: 767px) {
            margin-left: 10px;
        }
    }
    .c-type-delivery h4, .confirm-voter-add h4, .paymetn-means h3{
        text-align: right;
    }
    .tooltip-svg{
        margin-left: 0;
        margin-right: 10px;
    }
    .use-wallet-text .u-w-p span{
        margin-left: 0;
        margin-right: 5px;
    }
    .promo-code .primary-btn{
        right: auto;
        left: 10px;
    }
    .c-drop-deli-btn .primary-btn span{
        // margin-left: 0;
        // margin-right: 10px;
        svg{
            margin: 0;
        }
    }
    .product-tag span{
        margin-left: 0;
        margin-right: 5px;
        &:first-child{
            margin-right: 0;
        }
    }
}
.rtl{
    .pro-name {
        margin-left: 0px;
        margin-right: 30px;
    }
    .pro-list{
        margin-right: 0;
        margin-left: 30px;
        ul{
            text-align: right;
        }
    }
    .o-content{
        margin-left: 0;
        margin-right: 30px;
    }
    .o-name h4, .order-page-list li h5, .o-name, .pro-cont-detail h5{
        text-align: right;
    }
    .pro-cont-detail .form-block label{
        display: block;
        text-align: right;
    }
    .pro-cont-detail .form-block .form-control{
        padding-right: 24px;
        padding-left: 50px;
    }
    .checkbox-block.right-icons{
        right: auto;
        left: 10px;
    }
    .checkbox-block{
        right: auto;
        left: 40px;
    }
    .form-no-input .form-block .form-control{
        padding: 10px 0px 10px 50px;
    }
}