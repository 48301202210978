@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
/* Importing Bootstrap SCSS file. */
/*!
 * Bootstrap v4.6.1 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}
.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label,
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #0062cc;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.alert-primary hr {
  border-top-color: #9fcdff;
}
.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #fff !important;
  }

  .table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
:root {
  --theme-primary-color: #F8004A;
  --theme-secondary-color: #fc99b7;
  --theme-third-color: #fef2f6;
  --theme-fourth-color: #fef2f6;
  --theme-body-color: #000000;
  --body-fonts-typo:"Epilogue", sans-serif;
}

html {
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  scroll-behavior: smooth;
}

body {
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-text-stroke-width: 0;
  -moz-osx-font-smoothing: auto;
  padding: 0;
  font-size: 16px;
  color: var(--theme-body-color);
  font-family: var(--body-fonts-typo);
  background-color: #f2f2f2;
}

body * {
  outline: none !important;
}

input[type=text], input[type=password], input[type=date], input[type=datetime], input[type=datetime-local],
input[type=month], input[type=week], input[type=email], input[type=number], input[type=search], input[type=tel],
input[type=time], input[type=url], input[type=color], textarea {
  outline: none;
}

textarea {
  resize: vertical;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin: 0 0 30px 0;
  padding: 0;
  font-weight: 600;
  color: var(--theme-primary-color);
}
@media only screen and (max-width: 767px) {
  h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    margin-bottom: 20px;
  }
}

h1, .h1 {
  font-size: 45px;
}
@media only screen and (max-width: 1279px) {
  h1, .h1 {
    font-size: 40px;
  }
}
@media only screen and (max-width: 1023px) {
  h1, .h1 {
    font-size: 34px;
  }
}
@media only screen and (max-width: 767px) {
  h1, .h1 {
    font-size: 30px;
  }
}

h2, .h2 {
  font-size: 32px;
}
@media only screen and (max-width: 1279px) {
  h2, .h2 {
    font-size: 22px;
  }
}
@media only screen and (max-width: 1023px) {
  h2, .h2 {
    font-size: 20px;
  }
}

h3, .h3 {
  font-size: 24px;
}
@media only screen and (max-width: 1279px) {
  h3, .h3 {
    font-size: 20px;
  }
}

h4, .h4 {
  font-size: 16px;
}

h5, .h5 {
  font-size: 16px;
}

h6, .h6 {
  font-size: 12px;
}

p {
  font-size: 16px;
  line-height: normal;
  margin: 0 0 30px 0;
  font-weight: normal;
}
@media only screen and (max-width: 1279px) {
  p {
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 767px) {
  p {
    font-size: 14px;
  }
}

a, .link {
  outline: none;
  text-decoration: none;
  transition: all 0.3s;
  color: var(--theme-primary-color);
  cursor: pointer;
}
a:hover, .link:hover {
  transition: all 0.3s;
  color: var(--theme-secondary-color);
  text-decoration: none;
}

img {
  max-width: 100%;
}

ul, ol {
  margin-bottom: 30px;
  margin-left: 0px;
}
ul li, ol li {
  line-height: 18px;
  margin-bottom: 15px;
  list-style: none;
}

.container-full {
  max-width: 100%;
  margin: 0 auto;
}

.container {
  max-width: 1440px;
  margin: 0 auto;
}
@media only screen and (max-width: 1279px) {
  .container {
    max-width: 100%;
  }
}

.p-container {
  padding: 0 82px;
}
@media only screen and (max-width: 1439px) {
  .p-container {
    padding: 0 20px;
  }
}

.hide {
  display: none !important;
}

.show-568 {
  display: none;
}

@media only screen and (max-width: 767px) {
  .hide-568 {
    display: none;
  }

  .show-568 {
    display: inline-flex;
  }
}
svg {
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
}

.primary-btn {
  background-color: var(--theme-primary-color);
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  padding: 11px 22px;
  -webkit-border-radius: 45px;
  -moz-border-radius: 45px;
  border-radius: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid transparent;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  line-height: normal;
}
@media only screen and (max-width: 1279px) {
  .primary-btn {
    font-size: 16px;
    padding: 8px 10px;
  }
}
.primary-btn svg {
  fill: #ffffff;
  margin-left: 10px;
}
@media only screen and (max-width: 1279px) {
  .primary-btn svg {
    margin-left: 5px;
  }
}
.primary-btn:hover {
  background-color: var(--theme-third-color);
  color: var(--theme-primary-color);
}
.primary-btn:hover svg {
  fill: var(--theme-primary-color);
}
.primary-btn.svg-left svg {
  margin-left: 0;
  margin-right: 10px;
}
@media only screen and (max-width: 1279px) {
  .primary-btn.svg-left svg {
    margin-right: 5px;
  }
}
.primary-btn.secondary-btn {
  background-color: var(--theme-third-color);
  color: var(--theme-primary-color);
}
.primary-btn.secondary-btn svg {
  fill: var(--theme-primary-color);
}
.primary-btn.secondary-btn:hover {
  background-color: var(--theme-primary-color);
  color: #ffffff;
}
.primary-btn.secondary-btn:hover svg {
  fill: #ffffff;
}
.primary-btn.disable-btn {
  background-color: #DEDEDE;
  color: #9F9F9F;
  cursor: not-allowed;
}
.primary-btn.disable-btn:hover {
  background-color: #DEDEDE;
  color: #9F9F9F;
}
.primary-btn.border-btn {
  border-color: var(--theme-third-color);
  color: var(--theme-primary-color);
  background-color: #ffffff;
}
.primary-btn.border-btn svg {
  fill: var(--theme-primary-color);
}
.primary-btn.border-btn:hover {
  border-color: var(--theme-primary-color);
  color: #ffffff;
  background-color: var(--theme-primary-color);
}
.primary-btn.border-btn:hover svg {
  fill: #ffffff;
}

.link-btn {
  color: var(--theme-primary-color);
  text-decoration: underline;
  background-color: transparent;
  padding: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.link-btn:hover {
  color: var(--theme-secondary-color);
  text-decoration: underline;
  background-color: transparent;
}

.v-scroll-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.v-scroll-hide::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar {
  width: 7px;
  height: 8px;
  background-color: white;
}

::-webkit-scrollbar-thumb {
  border-radius: 0px;
  -webkit-box-shadow: inset 10px 10px 5px 0px rgba(225, 225, 225, 0.75);
}

* {
  scrollbar-width: thin;
  scrollbar-color: #E1E1E1 white;
}

.m-t-15 {
  margin-top: 15px;
}

.m-b-30 {
  margin-bottom: 30px;
}
@media only screen and (max-width: 767px) {
  .m-b-30 {
    margin-bottom: 10px;
  }
}

.m-b-25 {
  margin-bottom: 25px;
}
@media only screen and (max-width: 767px) {
  .m-b-25 {
    margin-bottom: 20px;
  }
}

.m-l-15 {
  margin-left: 15px;
}

@media only screen and (max-width: 1023px) {
  .summary-content-list.mob-border-none {
    border: none;
  }
}

.m-t-0 {
  margin-top: 0 !important;
}

.p-r {
  position: relative;
}

.form-control {
  background-color: #F7F7F7;
  padding: 10px 24px;
  min-height: 50px;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  border-radius: 18px;
  border: none;
  font-size: 16px;
  color: #000000;
  font-weight: 500;
}
@media only screen and (max-width: 1279px) {
  .form-control {
    padding: 5px 20px;
    min-height: 47px;
  }
}
.form-control::-webkit-input-placeholder {
  color: #C4C4C4;
}
.form-control::-moz-placeholder {
  color: #C4C4C4;
}
.form-control::-ms-input-placeholder {
  color: #C4C4C4;
}
.form-control.error {
  border-color: #FF0303;
}
.form-control.error::-webkit-input-placeholder {
  color: #FF0303;
}
.form-control.error::-moz-placeholder {
  color: #FF0303;
}
.form-control.error::-ms-input-placeholder {
  color: #FF0303;
}

.tgl {
  display: none;
}
.tgl, .tgl:after, .tgl:before, .tgl *, .tgl *:after, .tgl *:before, .tgl + .tgl-btn {
  box-sizing: border-box;
}
.tgl::selection, .tgl:after::selection, .tgl:before::selection, .tgl *::selection, .tgl *:after::selection, .tgl *:before::selection, .tgl + .tgl-btn::selection {
  background: none;
}
.tgl + .tgl-btn {
  outline: 0;
  display: block;
  width: 47px;
  height: 26px;
  position: relative;
  cursor: pointer;
  user-select: none;
}
.tgl + .tgl-btn:after, .tgl + .tgl-btn:before {
  position: relative;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
}
.tgl + .tgl-btn:after {
  left: 0;
}
.tgl + .tgl-btn:before {
  display: none;
}
.tgl:checked + .tgl-btn:after {
  left: 50%;
}

.tgl-btn {
  margin-bottom: 0;
}

.tgl-light + .tgl-btn {
  background: #f0f0f0;
  border-radius: 2em;
  padding: 2px 3px;
  transition: all 0.4s ease;
  border: 1px solid #E8E8E8;
}
.tgl-light + .tgl-btn:after {
  border-radius: 50%;
  background: #ffffff;
  transition: all 0.2s ease;
}
.tgl-light:checked + .tgl-btn {
  background: #4CEC8C;
}

.checkmark {
  position: absolute;
  left: 0;
  top: 0;
  background-color: #000000;
  height: 76px;
  width: 76px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  z-index: 0;
}
@media only screen and (max-width: 1279px) {
  .checkmark {
    height: 48px;
    width: 48px;
  }
}
.checkmark.sign_in_checkmak {
  height: 76px;
  width: 76px;
  border-radius: 50%;
  top: 50%;
  margin-top: -38px;
  background: #ffffff !important;
  border: 0;
  box-shadow: 0px 2px 15px -5px rgba(15, 81, 118, 0.2);
}
@media only screen and (max-width: 1279px) {
  .checkmark.sign_in_checkmak {
    height: 48px;
    width: 48px;
    margin-top: -24px;
  }
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.check_lb {
  position: relative;
  padding-left: 100px;
  cursor: pointer;
  user-select: none;
  width: 100%;
  font-weight: bold;
  color: #919191;
  margin-bottom: 0;
}
@media only screen and (max-width: 1279px) {
  .check_lb {
    padding-left: 60px;
  }
}
.check_lb a {
  color: #000000;
  font-size: 18px;
  font-weight: bold;
  line-height: 26px;
  text-decoration: underline;
}
@media only screen and (max-width: 1279px) {
  .check_lb a {
    font-size: 16px;
  }
}
.check_lb.check-square {
  padding-left: 0;
}
.check_lb.check-square .checkmark {
  height: 24px;
  width: 24px;
  background: var(--theme-primary-color);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.check_lb.check-square .checkmark::after {
  color: #ffffff;
  border-color: #ffffff;
  left: 7px;
  top: 3px;
}
.check_lb.check-square input:checked ~ .checkmark {
  background-color: var(--theme-primary-color);
  border-color: var(--theme-primary-color);
}

.check_lb input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 0;
}

.check_lb input:checked ~ .checkmark {
  background-color: transparent;
  border-color: transparent;
}

.check_lb input:checked ~ .checkmark:after {
  display: block;
}

.check_lb .checkmark:after {
  left: 6px;
  top: 1px;
  width: 9px;
  height: 14px;
  border: solid var(--theme-primary-color);
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  color: var(--theme-primary-color);
}

.sign_in_checkmak:after {
  left: 27px !important;
  top: 13px !important;
  width: 22px !important;
  height: 40px !important;
  border: solid !important;
  border-width: 0 5px 5px 0 !important;
}
@media only screen and (max-width: 1279px) {
  .sign_in_checkmak:after {
    left: 16px !important;
    top: 7px !important;
    width: 15px !important;
    height: 28px !important;
  }
}

.modal-body {
  padding: 0;
}

.modal-content {
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  border-radius: 40px;
  padding: 45px;
  border: none;
}
@media only screen and (max-width: 1279px) {
  .modal-content {
    padding: 35px;
  }
}
@media only screen and (max-width: 767px) {
  .modal-content {
    padding: 25px;
  }
}

.modal-header {
  border: none;
  padding: 0;
  margin-bottom: 30px;
}
.modal-header.no-header {
  margin-bottom: 0;
}

.modal-content .close {
  height: 48px;
  width: 48px;
  background-color: var(--theme-third-color);
  border-radius: 100%;
  line-height: 18px;
  text-shadow: none;
  opacity: 1;
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 99;
}
@media only screen and (max-width: 1279px) {
  .modal-content .close {
    height: 40px;
    width: 40px;
    right: 30px;
    top: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .modal-content .close {
    right: 20px;
    top: 10px;
  }
}
.modal-content .close span {
  font-weight: bold;
  color: var(--theme-primary-color);
  opacity: 1;
}
@media only screen and (max-width: 1279px) {
  .modal-content .close span {
    font-size: 30px;
  }
}
.modal-content .close span svg {
  fill: var(--theme-primary-color);
}
.modal-content .close.with-name {
  height: auto;
  width: auto;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  background-color: transparent;
}
.modal-content .close.with-name label {
  margin-bottom: 0;
  color: var(--theme-primary-color);
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}
.modal-content .close.with-name span {
  padding: 10px 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 116px;
  max-height: 40px;
  background-color: var(--theme-third-color);
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}
.modal-content .close.with-name span svg {
  fill: var(--theme-primary-color);
  margin-left: 10px;
}
.modal-content .close.with-name span:hover {
  background-color: var(--theme-primary-color);
}
.modal-content .close.with-name span:hover svg {
  fill: #ffffff;
}
.modal-content .close.with-name span:hover label {
  color: #ffffff;
}

.modal-body-wrp {
  margin-top: 30px;
}

.popup-mid {
  max-width: 475px;
}
@media only screen and (max-width: 767px) {
  .popup-mid {
    max-width: 100%;
    padding: 0 10px;
  }
}

.popup-lg {
  max-width: 604px;
}
@media only screen and (max-width: 1023px) {
  .popup-lg {
    max-width: 100%;
    padding: 0 10px;
  }
}

.popup-full {
  max-width: 100%;
  padding: 0 40px;
}
@media only screen and (max-width: 1023px) {
  .popup-full {
    padding: 10px;
  }
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 1;
}

.modal-footer {
  border-top: none;
  justify-content: center;
}

.bg-warning {
  background-color: var(--theme-secondary-color) !important;
  -webkit-border-radius: 0 10px 10px 0px;
  -moz-border-radius: 0 10px 10px 0px;
  border-radius: 0 10px 10px 0px;
}

.progress {
  background-color: #ffffff;
  height: 10px !important;
}

.progress-bar-top {
  max-width: 317px;
  margin: 0 auto;
}

.login-btn {
  position: relative;
}
.login-btn .primary-btn::before {
  content: "";
  position: absolute;
  bottom: -19px;
  left: 0;
  width: 100%;
  height: 20px;
}
.login-btn:hover .account-dropdown {
  display: block;
}

.account-dropdown {
  position: absolute;
  top: 130%;
  right: 0;
  z-index: 999;
  display: none;
  animation: growDown 300ms ease-in-out forwards;
  transform-origin: top center;
}
.account-dropdown ul {
  background: #ffffff;
  width: 237px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  padding: 15px 31px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
}
.account-dropdown li {
  margin-top: 20px;
}
.account-dropdown li:first-child {
  margin-top: 0;
}
.account-dropdown li a {
  font-size: 20px;
  color: #959595;
  display: block;
}
@media only screen and (max-width: 767px) {
  .account-dropdown li a {
    font-size: 18px;
  }
}
.account-dropdown li a:hover {
  color: var(--theme-primary-color);
}
.account-dropdown li a:hover svg {
  fill: var(--theme-primary-color);
}
.account-dropdown li a svg {
  margin-right: 15px;
}

.validation_error {
  color: #FF000F;
  text-align: left;
  font-size: 10px;
  top: 50px;
  left: 0;
  margin-left: 0px;
  margin-bottom: 15px;
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }
  80% {
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(1);
  }
}
.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  color: #fff;
  width: 400px;
  font-weight: 600;
}

#toast-container > .toast-success {
  color: #0E6932;
  background: #4CEC8C;
  background-repeat: no-repeat;
  background-position: 15px center;
  background-size: 24px;
  border-radius: 15px;
  background-image: url(../assets/images/grommet-icons_validate.png) !important;
}

#toast-container > .toast-error {
  color: #fff;
  background: #FF495F;
  background-repeat: no-repeat;
  background-position: 15px center;
  background-size: 24px;
  border-radius: 15px;
  background-image: url(../assets/images/bx_bxs-error.png) !important;
}

.alert-msg {
  color: #FF000F;
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px;
  display: block;
}

.modal {
  z-index: 9999;
}

.home-main {
  background-repeat: no-repeat;
  background-size: cover !important;
  min-height: 100vh;
  display: flex;
  position: relative;
  flex-direction: column;
}

.home-wrp {
  display: flex;
  flex: 1 1 0%;
  background-size: cover;
  min-height: 100vh;
}

.home-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  flex-direction: column;
}
@media only screen and (max-width: 767px) {
  .home-content {
    justify-content: start;
  }
}
.home-content .login-btn {
  position: absolute;
  right: 30px;
  top: 30px;
}
@media only screen and (max-width: 767px) {
  .home-content .login-btn {
    position: relative;
    right: auto;
    top: auto;
  }
}

@media only screen and (max-width: 767px) {
  .login-btn {
    margin: 20px;
    margin-left: auto;
  }
}
.login-btn .primary-btn:hover {
  background-color: var(--theme-primary-color);
  color: #ffffff;
}
.login-btn .primary-btn:hover svg {
  fill: #ffffff;
}
@media only screen and (max-width: 767px) {
  .login-btn .primary-btn {
    font-size: 14px;
    font-weight: 500;
    padding: 8px 15px;
  }
  .login-btn .primary-btn svg {
    width: 15px;
    height: 16px;
  }
}
.login-btn .primary-btn.only-svg {
  height: 50px;
  width: 50px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
@media only screen and (max-width: 767px) {
  .login-btn .primary-btn.only-svg {
    height: 38px;
    width: 38px;
  }
}
.login-btn .primary-btn.only-svg svg {
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .shop-page-header .login-btn {
    margin: 0px;
  }
}
@media only screen and (max-width: 1023px) {
  .shop-page-header .shop-profile-img .primary-btn {
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    padding: 10px 10px;
    min-width: 68px;
  }
}
@media only screen and (max-width: 1023px) {
  .shop-page-header .shop-profile-img .primary-btn span {
    display: none;
  }
}

.home-content-main {
  background-color: #ffffff;
  -webkit-border-radius: 53px;
  -moz-border-radius: 53px;
  border-radius: 53px;
  text-align: center;
  min-width: 502px;
  margin-top: 20px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 767px) {
  .home-content-main {
    margin-top: 0;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    border-radius: 40px;
    min-width: 345px;
    max-width: 345px;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .home-content-main::-webkit-scrollbar {
    display: none;
    width: 0;
  }
}
.home-content-main.delivery-add-section {
  text-align: left;
}

.home-title {
  border-bottom: 2px solid #F4F4F4;
  padding: 60px 80px 40px;
}
@media screen and (max-width: 1601px) {
  .home-title {
    padding: 30px 30px 20px;
  }
}
.home-title h4 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}
.home-title h1 {
  color: #000000;
  font-size: 65px;
  font-weight: 500;
  margin-bottom: 0;
}
@media screen and (max-width: 1601px) {
  .home-title h1 {
    font-size: 30px;
  }
}

.home-cont-mid {
  padding: 40px;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 1601px) {
  .home-cont-mid {
    padding: 20px;
  }
}
.home-cont-mid .primary-btn {
  margin: auto;
  margin-bottom: 10px;
  min-width: 306px;
  font-size: 20px;
  min-height: 71px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
}
@media screen and (max-width: 767px) {
  .home-cont-mid .primary-btn {
    font-size: 18px;
  }
}
.home-cont-mid a {
  margin-top: 35px;
  display: inline-block;
  text-decoration: underline;
  font-size: 20px;
  font-weight: 600;
}
@media screen and (max-width: 1601px) {
  .home-cont-mid a {
    margin-top: 6px;
  }
}
@media screen and (max-width: 767px) {
  .home-cont-mid a {
    font-size: 16px;
  }
}

.home-cont-footer {
  border-top: 2px solid #F4F4F4;
  padding: 17px 20px;
  text-align: center;
}

.h-c-footer-img span {
  color: var(--theme-secondary-color);
  font-size: 12px;
  font-weight: 600;
}
.h-c-footer-img span svg {
  fill: var(--theme-primary-color);
  margin-left: 5px;
}

.delivery-area-link a {
  display: inline-block;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  text-decoration: underline;
  margin-top: 25px;
  margin-bottom: 25px;
}

.home-footer {
  background-color: #ffffff;
  padding: 14px 30px;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .home-footer {
    padding: 14px 20px;
  }
}

.email-btn .primary-btn.secondary-btn {
  font-size: 16px;
  font-weight: 500;
  padding: 6px 15px;
}
@media screen and (max-width: 767px) {
  .email-btn .primary-btn.secondary-btn {
    font-size: 14px;
    padding: 6px 5px;
  }
}
.email-btn .primary-btn.secondary-btn span {
  height: 28px;
  width: 28px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  text-align: center;
  background-color: var(--theme-primary-color);
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .email-btn .primary-btn.secondary-btn span {
    height: 16px;
    width: 16px;
    margin-right: 5px;
  }
  .email-btn .primary-btn.secondary-btn span svg {
    width: 10px;
    height: 10px;
  }
}
.email-btn .primary-btn.secondary-btn span svg {
  fill: #ffffff;
  margin: 0;
}
.email-btn .primary-btn.secondary-btn:hover {
  background-color: var(--theme-third-color);
  color: var(--theme-primary-color);
}

.open-time-btn {
  position: absolute;
  bottom: 94px;
  right: 17px;
  z-index: 9999;
}
@media only screen and (max-width: 767px) {
  .open-time-btn {
    top: 20px;
    left: 20px;
    bottom: auto;
    right: auto;
    display: flex;
    flex-direction: column;
  }
}

.open-t-b-w {
  background-color: #ffffff;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  padding: 7px 15px;
  cursor: pointer;
  max-width: 250px;
}
@media only screen and (max-width: 767px) {
  .open-t-b-w {
    margin-right: auto !important;
    margin-left: 0 !important;
  }
}
.open-t-b-w .open-heading {
  margin-left: 10px;
  font-size: 16px;
  font-weight: 700;
  margin-right: 20px;
  color: #000000;
}
@media only screen and (max-width: 767px) {
  .open-t-b-w .open-heading {
    margin-right: 10px;
  }
}
.open-t-b-w .open-heading.red {
  color: var(--theme-primary-color);
}

.light-btn {
  height: 15px;
  width: 15px;
  background-color: #4CEC8C;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  display: flex;
}
.light-btn.red {
  background-color: #FF000F;
}

.time-list {
  max-width: 125px;
  text-align: right;
}
.time-list span {
  font-size: 14px;
  color: #969696;
  font-weight: 500;
}

@media only screen and (max-width: 767px) {
  .open-t-b-w .time-list {
    display: none;
  }
}

.timetable-list {
  background-color: #ffffff;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  width: 270px;
  flex-direction: column;
  margin-bottom: 15px;
  box-shadow: 0px 4px 44px -10px rgba(0, 0, 0, 0.15);
}
@media only screen and (max-width: 767px) {
  .timetable-list {
    order: 2;
    margin-bottom: 0;
    margin-top: 15px;
  }
}
.timetable-list ul {
  margin: 0;
  padding: 0;
}
.timetable-list li {
  margin-bottom: 0;
  border-top: 2px solid #F4F4F4;
  padding: 6px 10px 6px 24px;
  min-height: 55px;
}
.timetable-list li:first-child {
  border-top: none;
}
.timetable-list li.active .days-name span {
  color: #000000;
}
.timetable-list li.active .days-time .time-list span {
  color: #000000;
}

.timetable-close {
  margin: 10px;
  cursor: pointer;
}
.timetable-close span {
  background-color: var(--theme-third-color);
  height: 30px;
  width: 30px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.timetable-close span svg {
  fill: var(--theme-primary-color);
}

.days-name {
  display: flex;
  align-items: center;
}
.days-name .light-btn {
  margin-left: 10px;
  height: 8px;
  width: 8px;
}
.days-name span {
  font-size: 16px;
  font-weight: 500;
  color: #969696;
}

.days-time {
  text-align: right;
}
.days-time .time-list {
  max-width: 145px;
}
.days-time .time-list span {
  font-size: 16px;
  font-weight: 500;
}

.discount-offer {
  background-color: #FFF2E7;
  padding: 7px 20px;
  text-align: center;
}
.discount-offer p {
  color: #AD3E00;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}
@media screen and (max-width: 1601px) {
  .discount-offer p {
    line-height: 16px;
  }
}

.close-time {
  background-color: var(--theme-third-color);
  padding: 10px 20px;
  text-align: center;
}
.close-time p {
  color: var(--theme-primary-color);
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
}
@media only screen and (max-width: 767px) {
  .close-time p {
    font-size: 16px;
  }
}

.pre-order-text {
  margin-bottom: 15px;
  color: var(--theme-primary-color);
  font-size: 16px;
  font-weight: 600;
}

.see-menu-title {
  text-align: center;
  max-width: 285px;
  margin: 0 auto;
}
.see-menu-title span {
  color: var(--theme-primary-color);
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
}
.see-menu-title h3 {
  color: var(--theme-primary-color);
  font-size: 20px;
  font-weight: 600;
}
.see-menu-title p {
  font-size: 16px;
  font-weight: 500;
  color: #969696;
}

.see-menu-popup .modal-footer .primary-btn {
  min-width: 230px;
  min-height: 60px;
}

.ScopeOfDelivery-popup .modal-content {
  padding: 55px 25px 25px 25px;
}
.ScopeOfDelivery-popup .modal-body-wrp {
  margin-top: 10px;
}

.modal-header h2 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
}

.deli-map {
  text-align: center;
}
.deli-map img {
  -webkit-border-radius: 34px;
  -moz-border-radius: 34px;
  border-radius: 34px;
}

.return-btn {
  padding: 30px;
}
.return-btn .primary-btn span {
  padding-top: 1px;
}
@media only screen and (max-width: 767px) {
  .return-btn {
    padding: 20px;
  }
  .return-btn .primary-btn {
    font-size: 16px;
  }
}

.deli-address {
  padding: 0 30px 30px;
}
@media only screen and (max-width: 767px) {
  .deli-address {
    padding: 0 20px 20px;
  }
}
.deli-address h3 {
  font-size: 16px;
  margin-bottom: 10px;
}

.deli-area-link {
  margin-top: 10px;
}

.deli-add-top {
  border-bottom: 2px solid #F4F4F4;
  padding-bottom: 15px;
}
.deli-add-top .add-control .form-control.error {
  border: 3px solid #FF0303;
}

.deli-add-bottom {
  margin-top: 20px;
}
.deli-add-bottom .toggle-large-btn {
  margin: 0;
}
.deli-add-bottom.click-collect {
  text-align: center;
}
.deli-add-bottom.click-collect .toggle-large-btn {
  margin: 0 auto;
}
.deli-add-bottom.click-collect .hour-time-section {
  justify-content: center;
}

.toggle-large-btn {
  max-width: 336px;
  margin: 0 auto;
}

.t-b-l {
  background-color: var(--theme-third-color);
  -webkit-border-radius: 107px;
  -moz-border-radius: 107px;
  border-radius: 107px;
  padding: 8px;
}
.t-b-l .secondary-btn {
  border-color: transparent;
  background-color: transparent;
}
.t-b-l .primary-btn {
  padding: 5px 5px;
  width: 100%;
  font-size: 16px;
}
@media only screen and (max-width: 767px) {
  .t-b-l .primary-btn {
    font-size: 14px;
  }
}
.t-b-l .primary-btn.first {
  margin-left: 0;
}
.t-b-l .primary-btn.second {
  margin-left: 10px;
}
.t-b-l .primary-btn:hover {
  background-color: var(--theme-primary-color);
  color: #ffffff;
}

.order-deli-time {
  text-align: center;
  margin-top: 23px;
}
.order-deli-time p {
  color: #989898;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 0;
}
.order-deli-time p span {
  display: block;
  margin-top: 5px;
}

.delivery-add-section .home-cont-footer {
  border: none;
  box-shadow: 0px -6px 44px rgba(0, 0, 0, 0.1);
  padding: 30px;
}
@media only screen and (max-width: 767px) {
  .delivery-add-section .home-cont-footer {
    padding: 20px;
  }
}
@media only screen and (max-width: 1599px) {
  .delivery-add-section .home-cont-footer {
    padding: 20px 30px;
  }
}
.delivery-add-section .home-cont-footer .primary-btn {
  min-height: 83px;
  font-size: 24px;
  width: 100%;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
}
@media only screen and (max-width: 767px) {
  .delivery-add-section .home-cont-footer .primary-btn {
    min-height: auto;
    font-size: 20px;
  }
}
@media only screen and (max-width: 767px) and (max-width: 1599px) {
  .delivery-add-section .home-cont-footer .primary-btn {
    min-height: 73px;
  }
}

.pre-order-only {
  max-width: 350px;
}
.pre-order-only p {
  background-color: #FFEBEB;
  text-align: center;
  padding: 7px 10px;
  line-height: 1.5;
  color: #FF000F;
  font-size: 12px;
  font-weight: 500;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  margin-bottom: 0;
  margin-top: 10px;
}

.hour-time-section {
  margin-top: 23px;
}

.date-dropdown label {
  font-size: 14px;
  font-weight: 600;
  color: var(--theme-primary-color);
}
.date-dropdown.hour-dropdown {
  margin-left: 20px;
}

.d-dropdown {
  background-color: #F1F1F1;
  color: #000000;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  font-size: 16px;
  padding: 10px 18px;
}
@media only screen and (max-width: 767px) {
  .d-dropdown {
    font-size: 14px;
  }
}
.d-dropdown svg {
  stroke: #000000;
}
.d-dropdown:hover {
  background-color: #F1F1F1;
  color: #000000;
}
.d-dropdown:hover svg {
  fill: #F1F1F1;
}
.d-dropdown.active svg {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
}

.d-wrp {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  position: relative;
}
.d-wrp .primary-btn.active {
  -webkit-border-radius: 10px 10px 0 0;
  -moz-border-radius: 10px 10px 0 0;
  border-radius: 10px 10px 0 0;
}

.dropdown-menu-list {
  padding: 0;
  margin: 0;
  text-align: center;
  background-color: #E6E6E6;
  -webkit-border-radius: 0 0 10px 10px;
  -moz-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 9;
  animation: growDown 300ms ease-in-out forwards;
  transform-origin: top center;
}
.dropdown-menu-list li {
  border-top: 2px solid #DADADA;
  padding: 7px 20px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  .dropdown-menu-list li {
    font-size: 14px;
  }
}
.dropdown-menu-list li:first-child {
  border-top: none;
}

.add-control {
  position: relative;
}
.add-control .form-control {
  padding-right: 60px;
}
.add-control .form-control.active {
  -webkit-border-radius: 18px 18px 0 0;
  -moz-border-radius: 18px 18px 0 0;
  border-radius: 18px 18px 0 0;
}
.add-control .form-control.active:focus, .add-control .form-control.active:active {
  box-shadow: none;
  border: none;
  background-color: #F7F7F7;
}
.add-control .form-control-svg {
  position: absolute;
  top: 50%;
  right: 26px;
  margin-top: -13px;
  cursor: pointer;
}
.add-control .form-control-svg svg {
  stroke: #000000;
}
@media only screen and (max-width: 1023px) {
  .add-control .form-control-svg svg {
    height: 10px;
    width: 20px;
  }
}
.add-control.active .form-control-svg svg {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
}
.add-control.shop-add-control .form-control {
  min-width: 314px;
  padding-left: 40px;
  min-height: 51px;
}
.add-control.shop-add-control .form-control.error {
  background-color: rgba(255, 3, 3, 0.1);
  color: #FF000F;
}
.add-control.shop-add-control .form-control-svg.location {
  right: auto;
  left: 20px;
}
.add-control.shop-add-control .form-control-svg.location svg {
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
}
.add-control.shop-add-control.error .form-control-svg svg {
  stroke: #FF000F;
}
.add-control.shop-add-control.error .form-control-svg.location svg {
  fill: #FF000F;
}

.e-s-p-link {
  margin-top: 15px;
}

.valider-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.valider-btn .primary-btn {
  min-width: 185px;
}

.notdeliver-popup .see-menu-title {
  max-width: 100%;
  border-bottom: 2px solid #F4F4F4;
}
.notdeliver-popup .popup-footer-btn .primary-btn {
  min-height: 60px;
  min-width: 321px;
}

.send-address {
  margin-top: 15px;
  text-align: center;
}
.send-address p {
  font-size: 16px;
  font-weight: 600;
  color: var(--theme-primary-color);
  max-width: 257px;
  margin: 0 auto 20px;
  line-height: 19px;
}
.send-address .form-control {
  max-width: 321px;
  margin: 0 auto;
}

.form-label {
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  color: var(--theme-primary-color);
  display: block;
}
@media only screen and (max-width: 1279px) {
  .form-label {
    font-size: 18px;
  }
}

.cart-content-wrp-p {
  background-color: #ffffff;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
}
.cart-content-wrp-p .add-edit-section {
  text-align: center;
}

.login-form .form-label {
  font-size: 16px;
  font-weight: 500;
}
@media only screen and (max-width: 767px) {
  .login-form .form-label {
    font-size: 16px;
  }
}
.login-form .form-control {
  font-size: 16px;
}
@media only screen and (max-width: 767px) {
  .login-form .form-control {
    font-size: 14px;
  }
}
.login-form .form-control::-webkit-input-placeholder {
  color: #C4C4C4;
}
.login-form .form-control::-moz-placeholder {
  color: #C4C4C4;
}
.login-form .form-control::-ms-input-placeholder {
  color: #C4C4C4;
}
.login-form .form-control.error {
  background-color: #FFECEE;
  color: #FF000F;
  border: 2px solid #FF0303;
}
.login-form .form-control.error::-webkit-input-placeholder {
  color: #ffbfbf;
}
.login-form .form-control.error::-moz-placeholder {
  color: #ffbfbf;
}
.login-form .form-control.error::-ms-input-placeholder {
  color: #ffbfbf;
}
.login-form .red-color .pass-show-icon {
  margin-top: -19px;
}
@media screen and (max-width: 1279px) {
  .login-form .red-color .pass-show-icon {
    margin-top: -13px;
  }
}

.account-popup {
  max-width: 550px;
}
.account-popup .modal-content {
  padding: 0;
}
@media screen and (max-width: 767px) {
  .account-popup .modal-content {
    padding: 20px;
  }
}
.account-popup .modal-body-wrp {
  margin-top: 0;
}
.account-popup .toggle-large-btn {
  margin: 20px auto 0;
}
.account-popup .t-l-b-wrp {
  padding: 80px 20px 0;
}
@media screen and (max-width: 767px) {
  .account-popup .t-l-b-wrp {
    padding: 30px 0 0;
  }
}
.account-popup .l-f-wrp {
  padding: 30px 30px;
}
@media screen and (max-width: 767px) {
  .account-popup .l-f-wrp {
    padding: 20px 0px;
  }
}
.account-popup .social-text {
  margin-top: 10px;
}

.a-p-login-btn {
  border-bottom: 2px solid #F4F4F4;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.a-p-login-btn .primary-btn {
  min-width: 306px;
  font-size: 20px;
  min-height: 60px;
}
.a-p-login-btn .primary-btn.disabled {
  background-color: #D1D1D1;
  color: #ffffff;
  cursor: not-allowed;
  pointer-events: none;
}
@media screen and (max-width: 767px) {
  .a-p-login-btn .primary-btn {
    max-width: 100%;
    font-size: 18px;
    min-height: auto;
  }
}

.telephone {
  position: relative;
}
.telephone .phone-code {
  font-weight: 600;
  background-color: #F7F7F7;
  min-height: 50px;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  border-radius: 18px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
@media screen and (max-width: 1279px) {
  .telephone .phone-code {
    min-height: 47px;
  }
}
.telephone .phone-code img {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: -3px;
}
.telephone .phone-code svg {
  stroke: #000000;
  margin-left: auto;
}
.telephone .phone-code .d-dropdown {
  background-color: transparent;
  padding: 10px 24px;
  display: flex;
  align-items: center;
  flex: 1;
}
@media screen and (max-width: 1279px) {
  .telephone .phone-code .d-dropdown {
    padding: 5px 20px;
  }
}
.telephone .phone-code .dropdown-menu-list {
  background-color: #efefef;
  text-align: left;
}
.telephone .phone-code .dropdown-menu-list li {
  border: none;
  padding: 7px 24px;
}
@media screen and (max-width: 567px) {
  .telephone .phone-code .dropdown-menu-list li {
    padding: 7px 7px;
    text-align: center;
  }
}
.telephone .phone-code.active {
  -webkit-border-radius: 18px 18px 0 0;
  -moz-border-radius: 18px 18px 0 0;
  border-radius: 18px 18px 0 0;
}
.telephone .phone-code.active svg {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.telephone .form-label {
  opacity: 0;
}
.telephone .form-control {
  padding-left: 70px;
}

.pass-show-icon {
  position: absolute;
  right: 30px;
  top: 50%;
  margin-top: -3px;
}
@media only screen and (max-width: 1279px) {
  .pass-show-icon {
    margin-top: 2px;
  }
}
.pass-show-icon span {
  height: 38px;
  width: 38px;
  display: block;
  background-color: transparent;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  padding: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
@media only screen and (max-width: 1279px) {
  .pass-show-icon span {
    height: 28px;
    width: 28px;
    padding: 3px;
  }
}
.pass-show-icon span svg {
  fill: #000000;
  height: 22px;
  width: 22px;
}
.pass-show-icon span.active {
  background-color: var(--theme-primary-color);
}
.pass-show-icon span.active svg {
  fill: #ffffff;
}

.social-box {
  background-color: #ffffff;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  box-shadow: 0px 4px 30px -10px rgba(0, 0, 0, 0.15);
  padding: 20px 10px;
  flex-direction: column;
}
@media only screen and (max-width: 767px) {
  .social-box {
    padding: 10px 10px;
  }
}

.social-text {
  margin-top: 20px;
}
@media only screen and (max-width: 767px) {
  .social-text {
    margin-top: 10px;
  }
}
.social-text p {
  font-size: 14px;
  color: #000000;
  font-weight: 500;
  margin-bottom: 0;
  text-align: center;
}

.social-connect h4 {
  text-align: center;
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 20px;
}
.social-connect .row-custom {
  margin: 0 -5px;
}
@media only screen and (max-width: 767px) {
  .social-connect .row-custom {
    margin: 0;
  }
}
.social-connect .col-custom {
  flex: 1;
  margin: 0 5px;
}
@media only screen and (max-width: 767px) {
  .social-connect .col-custom {
    margin: 0 0 10px 0;
  }
}

.condition-section {
  background-color: #F6F6F6;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  padding: 24px;
  margin-bottom: 25px;
  margin-top: 25px;
  border: 2px solid transparent;
}
.condition-section.error {
  background-color: #FFECEE;
  border-color: #FF000F;
}
.condition-section.error .check_lb {
  color: #FF000F;
}
.condition-section.error .check_lb a {
  color: #FF000F;
}

.forgot-pass {
  text-align: right;
  margin: 15px 0 20px 0;
}

.pay-text {
  text-align: center;
  max-width: 380px;
  margin: 0 auto;
}
.pay-text h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
}
.pay-text span {
  font-size: 12px;
  font-weight: 300;
  color: var(--theme-primary-color);
}

.col-custom {
  margin: 0 10px;
  cursor: pointer;
  width: 100%;
}
@media only screen and (max-width: 1279px) {
  .col-custom {
    margin: 0 5px;
  }
}

.row-custom {
  margin: 0 -10px;
}
@media only screen and (max-width: 1279px) {
  .row-custom {
    margin: 0 -5px;
  }
}

@media only screen and (max-width: 767px) {
  .row-custom {
    flex-direction: column;
  }
  .row-custom .col-custom {
    margin-bottom: 10px;
    width: 100%;
  }
}
.page-content-wrp {
  margin-bottom: 71px;
}

.shop-header-main {
  position: relative;
  width: 100%;
  background-color: #ffffff;
}

.shop-page .shop-page-header {
  background-color: var(--theme-third-color);
}

.shop-page-header {
  background-color: #ffffff;
  padding: 19px 60px;
}
@media only screen and (max-width: 1279px) {
  .shop-page-header {
    padding: 19px 20px;
  }
}
@media only screen and (max-width: 767px) {
  .shop-page-header {
    padding: 10px 20px;
  }
}

.shop-profile-img img {
  width: 50px;
  height: 50px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 5px solid #ffffff;
  display: inline-block;
  cursor: pointer;
}

.delivery-options-list {
  background-color: #ffffff;
  -webkit-border-radius: 41px;
  -moz-border-radius: 41px;
  border-radius: 41px;
  margin-left: 68px;
  margin-right: 68px;
  position: relative;
  border: 3px solid transparent;
  transition: all 0.3s ease-in-out;
}
@media only screen and (max-width: 1279px) {
  .delivery-options-list {
    margin-left: 20px;
    margin-right: 20px;
  }
}
@media only screen and (max-width: 1023px) {
  .delivery-options-list {
    position: inherit;
  }
}
@media only screen and (max-width: 567px) {
  .delivery-options-list {
    margin-left: 10px;
    margin-right: 10px;
  }
}
.delivery-options-list.active {
  border-color: var(--theme-secondary-color);
  -ms-transform: scale(1.05);
  /* IE 9 */
  -webkit-transform: scale(1.05);
  /* Safari 3-8 */
  transform: scale(1.05);
}

.deli-optiond-wrp {
  padding: 12px 20px;
  cursor: pointer;
  min-height: 51px;
}
@media only screen and (max-width: 1279px) {
  .deli-optiond-wrp {
    padding: 14px 10px;
  }
}
@media only screen and (max-width: 1023px) {
  .deli-optiond-wrp {
    min-height: auto;
  }
}
@media only screen and (max-width: 567px) {
  .deli-optiond-wrp {
    padding: 6px 3px;
  }
}
.deli-optiond-wrp ul {
  padding: 0;
  margin: 0;
}
.deli-optiond-wrp li {
  margin: 0;
  border-left: 2px solid #F7F7F7;
  padding: 0 15px;
}
@media only screen and (max-width: 1279px) {
  .deli-optiond-wrp li {
    padding: 0px 10px;
  }
}
@media only screen and (max-width: 1023px) {
  .deli-optiond-wrp li {
    padding: 0px 13px;
  }
}
@media only screen and (max-width: 567px) {
  .deli-optiond-wrp li {
    padding: 0px 8px;
  }
}
.deli-optiond-wrp li:first-child {
  border: none;
}
.deli-optiond-wrp li svg {
  fill: var(--theme-secondary-color);
  margin-right: 15px;
}
@media only screen and (max-width: 1279px) {
  .deli-optiond-wrp li svg {
    margin-right: 10px;
  }
}
@media only screen and (max-width: 1023px) {
  .deli-optiond-wrp li svg {
    margin-right: 0;
  }
}
.deli-optiond-wrp li span {
  color: var(--theme-primary-color);
  font-size: 16px;
  font-weight: 600;
}
@media only screen and (max-width: 1279px) {
  .deli-optiond-wrp li span {
    font-size: 14px;
  }
}
@media only screen and (max-width: 1023px) {
  .deli-optiond-wrp li span {
    display: none;
  }
}
.deli-optiond-wrp li.long-add {
  flex-wrap: wrap;
  white-space: nowrap;
  max-width: 800px;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media only screen and (max-width: 1599px) {
  .deli-optiond-wrp li.long-add {
    max-width: 600px;
  }
}
@media only screen and (max-width: 1499px) {
  .deli-optiond-wrp li.long-add {
    max-width: 480px;
  }
}
@media only screen and (max-width: 1399px) {
  .deli-optiond-wrp li.long-add {
    max-width: 350px;
  }
}

.deli-list-dropdown {
  background-color: #ffffff;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  padding: 30px;
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 15px;
  box-shadow: 0px 2px 60px -10px rgba(0, 0, 0, 0.15);
  z-index: 9999;
  animation: growDown 300ms ease-in-out forwards;
  transform-origin: top center;
}
@media only screen and (max-width: 1023px) {
  .deli-list-dropdown {
    position: fixed;
    top: 0;
    margin-top: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    width: 100%;
    height: 100vh;
    padding: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .deli-list-dropdown::-webkit-scrollbar {
    display: none;
  }
}
.deli-list-dropdown .close {
  height: 40px;
  width: 40px;
  background-color: var(--theme-third-color);
  border-radius: 100%;
  opacity: 1;
  margin-bottom: 15px;
}
@media only screen and (max-width: 1023px) {
  .deli-list-dropdown .close {
    display: none;
  }
}
.deli-list-dropdown .close span {
  font-size: 33px;
  font-weight: bold;
  color: var(--theme-primary-color);
  opacity: 1;
}
.deli-list-dropdown .close span svg {
  fill: var(--theme-primary-color);
}
.deli-list-dropdown .modal-content {
  display: none;
}
@media only screen and (max-width: 1023px) {
  .deli-list-dropdown .modal-content {
    display: block;
  }
}
@media only screen and (max-width: 1023px) {
  .deli-list-dropdown .modal-content.d-l-drop-mob {
    padding: 0;
  }
  .deli-list-dropdown .modal-content.d-l-drop-mob .close {
    display: block;
  }
  .deli-list-dropdown .modal-content.d-l-drop-mob .close.with-name {
    position: inherit;
    left: auto;
    right: auto;
    top: auto;
    display: flex !important;
    float: none;
    justify-content: center;
    border-bottom: 2px solid #F4F4F4;
    padding-bottom: 20px;
    width: 100%;
  }
}
.deli-list-dropdown .add-con-dropdown-list {
  position: inherit;
  top: 0;
  max-height: 180px;
}

.d-l-row {
  width: 100%;
  margin: 0 -15px;
}
@media only screen and (max-width: 1023px) {
  .d-l-row {
    display: block !important;
    margin: 0;
  }
}

.d-l-block {
  width: auto;
  padding: 0 15px;
  border-left: 2px solid #F2F2F2;
}
@media only screen and (max-width: 1023px) {
  .d-l-block {
    border-left: none;
    border-top: 2px solid #F2F2F2;
    padding: 20px 10px 0px 10px;
    margin-top: 20px;
  }
  .d-l-block:first-child {
    border-top: none;
  }
}
.d-l-block:first-child {
  border: none;
}
.d-l-block.d-l-b-mob {
  display: none;
}
@media only screen and (max-width: 1023px) {
  .d-l-block.d-l-b-mob {
    display: inline-flex;
    justify-content: center;
    width: 100%;
    border: none;
  }
  .d-l-block.d-l-b-mob .primary-btn {
    background-color: #000000;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    min-height: 67px;
    width: 100%;
    color: #ffffff;
    font-size: 20px;
  }
}

.block-title h3 {
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
}

.deli-type-btn .primary-btn {
  background-color: #f2f2f2;
  color: #000000;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  font-size: 16px;
  padding: 8px 12px;
  min-width: 230px;
  justify-content: start;
  margin-bottom: 10px;
}
@media only screen and (max-width: 1023px) {
  .deli-type-btn .primary-btn {
    min-width: auto;
    width: 100%;
  }
}
.deli-type-btn .primary-btn svg {
  fill: #000000;
  margin-right: 10px;
  margin-left: 10px;
}
.deli-type-btn .primary-btn .btn-mark {
  height: 24px;
  width: 24px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.deli-type-btn .primary-btn .btn-mark .blue-tick {
  height: 16px;
  width: 16px;
  background-color: #0A89FF;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.deli-type-btn .primary-btn .btn-mark .blue-tick svg {
  stroke: #ffffff;
  margin: 0;
  fill: #0A89FF;
}
.deli-type-btn .primary-btn.active {
  background-color: #e1f0ff;
  border-color: #0A89FF;
}

.shop-d-h {
  margin-top: 20px;
}
.shop-d-h .date-dropdown {
  margin-top: 15px;
}
.shop-d-h .date-dropdown:first-child {
  margin-top: 0;
}
.shop-d-h .date-dropdown label {
  color: #808080;
  font-weight: 500;
  font-size: 14px;
}
.shop-d-h .date-dropdown.hour-dropdown {
  margin-left: 0;
}
.shop-d-h .date-dropdown .d-dropdown {
  font-weight: 500;
  width: 100%;
  justify-content: start;
}
.shop-d-h .date-dropdown .d-dropdown svg {
  margin-left: auto;
}

.input-dropdown {
  position: relative;
}

.add-con-dropdown-list {
  background-color: #F1F1F1;
  padding: 20px 15px;
  max-height: 230px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  margin-top: 0px;
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  z-index: 99999;
  animation: growDown 300ms ease-in-out forwards;
  transform-origin: top center;
}
@media only screen and (max-width: 1279px) {
  .add-con-dropdown-list {
    top: 47px;
  }
}
.add-con-dropdown-list.active {
  -webkit-border-radius: 0 0 25px 25px;
  -moz-border-radius: 0 0 25px 25px;
  border-radius: 0 0 25px 25px;
}
.add-con-dropdown-list h3 {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 15px;
}

.address-form-list-main .form-control {
  background-color: #ffffff;
  color: #000000;
  margin-top: 4px;
  cursor: pointer;
}

.d-alert-msg p {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: #FF000F;
}
.d-alert-msg .black {
  color: #000000;
}
.d-alert-msg .gray {
  color: #888888;
}

.e-s-p-link .primary-btn.link-btn {
  font-size: 14px;
  font-weight: 500;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.estimated-delivery {
  background-color: var(--theme-fourth-color);
  text-align: center;
}
.estimated-delivery p {
  color: var(--theme-primary-color);
  padding: 7px 20px;
  font-weight: 600;
  margin-bottom: 0;
}
@media only screen and (max-width: 1279px) {
  .estimated-delivery p {
    font-size: 14px;
  }
}

.menu-content-sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.menu-hori-main {
  background-color: #ffffff;
  box-shadow: 0px 20px 20px -10px rgba(0, 0, 0, 0.05);
  position: relative;
}
.menu-hori-main .nav-tabs {
  border: none;
  padding: 0px 50px;
  flex-wrap: nowrap;
  align-items: center;
  width: calc(100% - 500px);
  overflow: hidden;
  padding-right: 0;
}
@media only screen and (max-width: 1279px) {
  .menu-hori-main .nav-tabs {
    padding: 0px 20px;
    overflow: hidden;
  }
}
@media only screen and (max-width: 767px) {
  .menu-hori-main .nav-tabs {
    width: calc(100% - 200px);
  }
}
.menu-hori-main .nav-tabs li {
  margin-bottom: 0;
  white-space: nowrap;
}
.menu-hori-main .nav-tabs .nav-link {
  border: none;
  font-size: 18px;
  font-weight: 600;
  color: var(--theme-secondary-color);
  position: relative;
  padding: 0.5rem 0;
  margin: 0 22px;
}
@media only screen and (max-width: 1279px) {
  .menu-hori-main .nav-tabs .nav-link {
    font-size: 16px;
    margin: 0 15px;
  }
}
@media only screen and (max-width: 767px) {
  .menu-hori-main .nav-tabs .nav-link {
    margin: 0 10px;
  }
}
.menu-hori-main .nav-tabs .nav-link.active {
  background: none;
  color: var(--theme-primary-color);
}
.menu-hori-main .nav-tabs .nav-link.active::before {
  display: block;
}
.menu-hori-main .nav-tabs .nav-link::before {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 0;
  height: 3px;
  width: 100%;
  background-color: var(--theme-primary-color);
  display: none;
}
@media only screen and (max-width: 1279px) {
  .menu-hori-main .nav-tabs .nav-link::before {
    bottom: -3px;
  }
}

.menu-plus {
  border-right: 2px solid #F4F4F4;
  padding-right: 25px;
  margin-right: 25px;
  position: relative;
}
@media only screen and (max-width: 767px) {
  .menu-plus {
    padding-right: 0;
    margin-right: 0;
    border-right: none;
  }
}
.menu-plus .primary-btn {
  background-color: #ffffff;
  color: var(--theme-primary-color);
  box-shadow: 0px 0px 32px -10px rgba(0, 0, 0, 0.15);
}
.menu-plus .primary-btn svg {
  stroke: var(--theme-primary-color);
  fill: #ffffff;
}
.menu-plus ul {
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  box-shadow: 0px 2px 50px -10px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  width: 207px;
  max-height: 303px;
  position: absolute;
  right: 20px;
  top: 120%;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 999;
  animation: growDown 300ms ease-in-out forwards;
  transform-origin: top center;
}
.menu-plus ul li {
  margin-bottom: 0;
  border-top: 2px solid var(--theme-third-color);
}
.menu-plus ul li:first-child {
  border-top: none;
}
.menu-plus ul li a {
  font-size: 16px;
  font-weight: 600;
  color: var(--theme-primary-color) !important;
  padding: 11px 15px;
  display: block;
}
@media only screen and (max-width: 1279px) {
  .menu-plus ul li a {
    font-size: 14px;
    padding: 8px 15px;
  }
}
.menu-plus ul li a:hover {
  background-color: var(--theme-third-color);
}
.menu-plus.active .primary-btn svg {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
}

@media only screen and (max-width: 767px) {
  .menu-cart-btn {
    position: fixed;
    bottom: 20px;
    left: 0;
    width: 100%;
    padding: 0 10px;
    z-index: 999;
  }
}
.menu-cart-btn .primary-btn {
  padding: 0;
}
.menu-cart-btn .primary-btn.btn-mob {
  display: none;
}
@media only screen and (max-width: 767px) {
  .menu-cart-btn .primary-btn {
    width: 100%;
  }
  .menu-cart-btn .primary-btn.btn-desktop {
    display: none;
  }
  .menu-cart-btn .primary-btn.btn-mob {
    display: flex;
    justify-content: space-between;
    min-height: 74px;
    background-color: #000000;
    color: #ffffff;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    padding: 10px 20px;
    transition: none;
  }
  .menu-cart-btn .primary-btn.btn-mob label {
    line-height: 1.6;
    padding: 0;
    width: 100%;
  }
  .menu-cart-btn .primary-btn.btn-mob span {
    border: none;
    padding: 0;
    display: block;
  }
  .menu-cart-btn .primary-btn.btn-mob svg {
    height: 26px;
    width: 26px;
  }
  .menu-cart-btn .primary-btn.btn-mob:hover svg {
    fill: #ffffff;
  }
}
.menu-cart-btn .primary-btn label {
  padding: 13px 18px;
  margin-bottom: 0;
  cursor: pointer;
  position: relative;
}
@media only screen and (max-width: 1279px) {
  .menu-cart-btn .primary-btn label {
    padding: 10px 22px;
  }
}
.menu-cart-btn .primary-btn span {
  border-left: 2px solid rgba(255, 255, 255, 0.1);
  padding: 16px 22px;
  font-size: 14px;
}
@media only screen and (max-width: 1279px) {
  .menu-cart-btn .primary-btn span {
    padding: 12px 22px;
  }
}
.menu-cart-btn .primary-btn .count-pill {
  background-color: #ffffff;
  color: var(--theme-primary-color);
  font-size: 10px;
  height: 16px;
  width: 16px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  padding: 0;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 13px;
  top: 6px;
}
@media only screen and (max-width: 1279px) {
  .menu-cart-btn .primary-btn .count-pill {
    top: 3px;
  }
}
.menu-cart-btn .primary-btn:hover .count-pill {
  color: #ffffff;
  background-color: var(--theme-primary-color);
}

.menu-list-right {
  padding: 10px 60px 10px 20px;
  justify-content: flex-end;
  width: 640px;
}
@media only screen and (max-width: 1279px) {
  .menu-list-right {
    padding: 10px 20px;
  }
}
@media only screen and (max-width: 767px) {
  .menu-list-right {
    width: auto;
  }
}

.re-order-btn {
  padding: 20px 60px;
  margin-bottom: 10px;
}
@media only screen and (max-width: 1279px) {
  .re-order-btn {
    padding: 10px 20px;
  }
}
@media only screen and (max-width: 767px) {
  .re-order-btn {
    display: none;
  }
}
.re-order-btn .primary-btn {
  background-color: transparent;
  font-size: 14px;
}

.product-item-list {
  padding: 0 60px;
}
@media only screen and (max-width: 1279px) {
  .product-item-list {
    padding: 0 20px;
  }
}
.product-item-list h2 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0;
}
@media only screen and (max-width: 1279px) {
  .product-item-list h2 {
    font-size: 18px;
  }
}
.product-item-list.unavailable {
  opacity: 0.3;
  pointer-events: none;
}

.p-t-with-btn {
  margin-bottom: 30px;
  flex-wrap: wrap;
}
@media only screen and (max-width: 1279px) {
  .p-t-with-btn {
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .p-t-with-btn {
    padding-top: 15px;
  }
}
.p-t-with-btn .unavailable-btn {
  margin-left: 10px;
}
.p-t-with-btn .unavailable-btn .primary-btn {
  background-color: #969696;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  padding: 5px 10px;
  cursor: auto;
}
.p-t-with-btn .unavailable-btn .primary-btn span {
  margin-left: 5px;
}
@media only screen and (max-width: 767px) {
  .p-t-with-btn .unavailable-btn .primary-btn {
    font-size: 12px;
    padding: 5px 5px;
  }
  .p-t-with-btn .unavailable-btn .primary-btn span {
    display: none;
  }
}
.p-t-with-btn .available-from {
  margin-left: 15px;
  font-size: 14px;
  font-weight: 500;
  color: #969696;
}
@media only screen and (max-width: 767px) {
  .p-t-with-btn .available-from {
    margin-top: 10px;
    margin-left: 0;
  }
}
.p-t-with-btn .available-from label {
  margin-bottom: 0;
}
.p-t-with-btn .available-from span {
  margin-left: 5px;
}

.product-list-block {
  background-color: #ffffff;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  padding: 15px 15px 15px 20px;
  width: 100%;
  cursor: pointer;
}
.product-list-block.unavailable {
  opacity: 0.3;
  pointer-events: none;
}

.indisponible-tag {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -100px;
  margin-top: -15px;
}
.indisponible-tag span {
  background-color: #969696;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  padding: 5px 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  display: block;
}

.product-content h3 {
  font-size: 18px;
  color: #000000;
  margin-bottom: 10px;
}
@media only screen and (max-width: 1279px) {
  .product-content h3 {
    font-size: 16px;
  }
}
.product-content p {
  color: #707070;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  text-transform: lowercase;
  margin-bottom: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  height: 55px;
}
.product-content .p-price {
  font-size: 16px;
  font-weight: 600;
}

.pro-strike-price {
  color: #000000;
  opacity: 0.4;
  position: relative;
  margin-right: 10px;
}
.pro-strike-price::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  background-color: #000000;
  height: 1px;
  width: 100%;
  margin-top: -0.5px;
  transform: rotate(15deg);
}

.pro-strike-price-mob-cart {
  color: #fff;
}

.product-img {
  position: relative;
  padding-left: 15px;
}
.product-img img {
  width: 142px;
  height: 142px;
  max-width: unset;
  object-fit: cover;
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  border-radius: 14px;
}
@media only screen and (max-width: 1279px) {
  .product-img img {
    width: 120px;
    height: 120px;
  }
}
.product-img.only-tag {
  min-width: 152px;
  min-height: 142px;
}
@media only screen and (max-width: 1279px) {
  .product-img.only-tag {
    min-width: 130px;
    min-height: 120px;
  }
}

.pro-tag-list {
  position: absolute;
  bottom: 8px;
  left: auto;
  right: 4px;
  text-align: right;
}

.popular-tag {
  background-color: #82CFFB;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  padding: 6px 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  line-height: normal;
  margin-bottom: 5px;
  display: inline-block;
}
@media only screen and (max-width: 1279px) {
  .popular-tag {
    font-size: 12px;
  }
}
.popular-tag svg {
  margin-left: 5px;
}
.popular-tag.percentage-tag {
  background-color: #FFEBD9;
  color: #AD3E00;
}

.shop-footer {
  text-align: center;
  background-color: var(--theme-primary-color);
  padding: 14px 60px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .shop-footer {
    -webkit-border-radius: 20px 20px 0 0;
    -moz-border-radius: 20px 20px 0 0;
    border-radius: 20px 20px 0 0;
    bottom: 72px;
    width: 96%;
    left: 12px;
    padding: 14px 20px 35px;
    z-index: 99;
  }
}
@media only screen and (max-width: 567px) {
  .shop-footer {
    width: 95%;
  }
}
@media only screen and (max-width: 479px) {
  .shop-footer {
    width: 94%;
  }
}
.shop-footer p {
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 12px;
}
@media only screen and (max-width: 1279px) {
  .shop-footer p {
    font-size: 16px;
  }
}
@media only screen and (max-width: 767px) {
  .shop-footer p {
    font-size: 14px;
  }
}

.shop-footer-cart-open {
  width: calc(100% - 443px);
  transition: all 0.2s ease;
}
@media only screen and (max-width: 767px) {
  .shop-footer-cart-open {
    width: 96%;
  }
}
@media only screen and (max-width: 567px) {
  .shop-footer-cart-open {
    width: 95%;
  }
}
@media only screen and (max-width: 479px) {
  .shop-footer-cart-open {
    width: 94%;
  }
}

.footer-close {
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -17px;
}
@media only screen and (max-width: 767px) {
  .footer-close {
    display: none;
  }
}
.footer-close span {
  cursor: pointer;
}
.footer-close span svg {
  fill: #ffffff;
}

.cart-dropdown-open .cart-dropdown {
  right: 0;
  transition: right 0.2s;
}

.cart-dropdown {
  background-color: #ffffff;
  width: 443px;
  position: fixed;
  right: -999px;
  bottom: 0;
  z-index: 99;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0px 2px 60px -10px rgba(0, 0, 0, 0.15);
  transition: all 0.2s ease;
}
@media only screen and (max-width: 767px) {
  .cart-dropdown {
    top: 0;
    width: 100%;
    height: 100vh !important;
  }
}

.livraison-offerte-mob {
  display: none;
}
@media only screen and (max-width: 767px) {
  .livraison-offerte-mob {
    display: block;
  }
  .livraison-offerte-mob p {
    background-color: #FFEBD9;
    color: #AD3E00;
    font-size: 14px;
    text-align: center;
    padding: 8px 10px 5px;
    font-weight: 600;
    margin-bottom: 0;
  }
}

.moa-mobile {
  display: none;
}
@media only screen and (max-width: 767px) {
  .moa-mobile {
    display: block;
  }
  .moa-mobile .moa {
    color: #FF000F;
    background-color: #FFD0D0;
    padding: 10px 10px;
    margin-bottom: 0;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
  }
}

.cart-dropdown-wrp {
  padding: 15px;
  margin-bottom: 135px;
}
@media only screen and (max-width: 767px) {
  .cart-dropdown-wrp {
    padding: 0;
  }
  .cart-dropdown-wrp .close-shopping-cart {
    padding-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-product-list {
    padding: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .cart-dropdown-wrp-offer {
    margin-bottom: 185px;
  }
}

.close-shopping-cart {
  margin-bottom: 20px;
}
@media only screen and (max-width: 767px) {
  .close-shopping-cart {
    display: flex;
    justify-content: center;
  }
}
.close-shopping-cart .primary-btn {
  font-size: 16px;
  padding: 5px 9px;
}
@media only screen and (max-width: 1279px) {
  .close-shopping-cart .primary-btn {
    font-size: 14px;
  }
  .close-shopping-cart .primary-btn svg {
    width: 20px;
    height: 20px;
  }
}

.c-p-list-box {
  border-top: 2px solid #F6F6F6;
  padding-top: 20px;
  margin-top: 20px;
}
@media only screen and (max-width: 767px) {
  .c-p-list-box {
    padding-top: 10px;
    margin-top: 10px;
  }
}
.c-p-list-box:first-child {
  border-top: none;
  padding-top: 0;
  margin-top: 0;
}

.c-product-title {
  margin-bottom: 10px;
}
.c-product-title h3 {
  font-size: 16px;
  font-weight: 700;
  color: #000000;
  margin-bottom: 0;
}

.qty-box .primary-btn {
  background-color: #F6F6F6;
  padding: 0;
  color: #000000;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  height: 22px;
  width: 22px;
  font-size: 26px;
  line-height: 8px;
}
.qty-box .form-control {
  width: 25px;
  height: 25px;
  padding: 0;
  margin: 0 10px;
  font-size: 16px;
  color: #000000;
  background-color: transparent;
  min-height: auto;
  text-align: center;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.qty-box .form-control:focus, .qty-box .form-control:active {
  box-shadow: none;
  border-color: transparent;
}

.c-pro-dec {
  max-height: 74px;
  overflow: hidden;
}
.c-pro-dec p {
  color: #828282;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 5px;
}

.c-pro-dec-all {
  max-height: unset;
}

.c-pro-tag {
  margin-top: 18px;
}

.product-tag {
  flex-wrap: wrap;
}
.product-tag span {
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;
  color: #000000;
  background-color: #F6F6F6;
  padding: 3px 10px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px 10px;
}
@media only screen and (max-width: 767px) {
  .product-tag span {
    font-size: 14px;
    padding: 3px 8px;
    margin: 0 3px 10px;
  }
}
.product-tag span:first-child {
  margin-left: 0;
}

.c-product-price {
  width: 200px;
  text-align: right;
}
@media only screen and (max-width: 767px) {
  .c-product-price {
    width: 150px;
  }
}
.c-product-price span {
  font-weight: 500;
  font-size: 17px;
}

.c-drop-footer {
  background-color: #ffffff;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  padding: 18px 0 0 0;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: auto;
  width: 443px;
  display: flex;
  flex-direction: column;
  flex-flow: wrap;
  justify-content: center;
  z-index: 999;
}
.c-drop-footer p {
  color: #AD3E00;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 10px;
  padding: 0 44px 0;
}
.c-drop-footer .moa {
  color: #FF000F;
  background-color: #FFD0D0;
  padding: 10px 10px;
  font-size: 14px;
  width: 100%;
}
.c-drop-footer .commander-btn-p {
  padding: 0 44px 18px;
  width: 100%;
}
.c-drop-footer .primary-btn {
  width: 100%;
}
.c-drop-footer .primary-btn span {
  margin-left: 10px;
}
.c-drop-footer .primary-btn.disabled {
  cursor: not-allowed;
  background: #D1D1D1;
}
.c-drop-footer .primary-btn.disabled:hover {
  background: #D1D1D1;
  color: #fff;
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .c-drop-footer {
      position: sticky;
      top: 100%;
    }
  }
}
.product-item-list-main {
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .product-item-list-main {
    margin-bottom: 100px;
  }
}
@media only screen and (max-width: 767px) {
  .product-item-list-main .c-drop-footer {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .product-item-list-main-offer {
    margin-bottom: 180px;
  }
}

.product-item-list-main-cart-open {
  width: calc(100% - 443px);
  transition: all 0.2s ease;
}
@media only screen and (max-width: 767px) {
  .product-item-list-main-cart-open {
    width: 100%;
  }
}
@media only screen and (max-width: 1599px) {
  .product-item-list-main-cart-open .col-lg-4 {
    max-width: 50%;
    flex: 0 0 50%;
  }
}
@media only screen and (max-width: 962px) {
  .product-item-list-main-cart-open .col-sm-6 {
    max-width: 100%;
    flex: 0 0 100%;
  }
}

.re-order-popup {
  max-width: 855px;
}
@media only screen and (max-width: 1023px) {
  .re-order-popup {
    max-width: 100%;
    padding: 0 10px;
  }
}
.re-order-popup .modal-content {
  padding: 30px;
  max-height: 730px;
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.re-order-popup .modal-content::-webkit-scrollbar {
  display: none;
}
.re-order-popup .modal-header {
  margin-top: 50px;
  margin-bottom: 30px;
}
@media only screen and (max-width: 767px) {
  .re-order-popup .modal-header {
    margin-top: 30px;
    margin-bottom: 20px;
  }
}
.re-order-popup .modal-header h2 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
}
@media only screen and (max-width: 767px) {
  .re-order-popup .modal-header h2 {
    font-size: 18px;
  }
}
.re-order-popup .modal-body-wrp {
  margin-top: 0;
}

.r-o-list-box {
  background-color: #F6F6F6;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  padding: 20px;
  margin-top: 15px;
}
.r-o-list-box:first-child {
  margin-top: 0;
}

.order-create-detail {
  margin-bottom: 20px;
}
@media only screen and (max-width: 1023px) {
  .order-create-detail {
    display: block !important;
  }
}

.o-c-d-left span {
  color: #B8B8B8;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
  display: block;
}

.o-c-d-right .primary-btn {
  font-size: 16px;
}

.alert-box p {
  background-color: #FFECEE;
  color: #F24E61;
  font-size: 14px;
  font-weight: 500;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  padding: 5px;
  margin-bottom: 10px;
  display: inline-block;
}
.alert-box p svg {
  fill: #F24E61;
}

.r-o-product-name {
  margin-bottom: 10px;
}
.r-o-product-name h3 {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 5px;
}
.r-o-product-name.red h3 {
  color: #FF000F;
}

.r-o-desc span {
  color: #959595;
  font-size: 14px;
  font-weight: 600;
  margin-left: 3px;
}
.r-o-desc span:first-child {
  margin-left: 0;
}
.r-o-desc span.red {
  color: var(--theme-primary-color);
}

.r-o-total {
  text-align: right;
}
.r-o-total span {
  color: #000000;
  font-size: 18px;
  font-weight: 600;
}
@media only screen and (max-width: 767px) {
  .r-o-total span {
    font-size: 16px;
  }
}

.product-item-popup .modal-content {
  padding: 45px 0 0 0;
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.product-item-popup .modal-content::-webkit-scrollbar {
  display: none;
}
.product-item-popup .modal-content::-webkit-scrollbar {
  display: none;
}
.product-item-popup .modal-footer {
  box-shadow: 0px -6px 14px rgba(0, 0, 0, 0.05);
  padding: 25px;
  position: sticky;
  bottom: 0;
  background-color: #ffffff;
  -webkit-border-radius: 0 0 40px 40px;
  -moz-border-radius: 0 0 40px 40px;
  border-radius: 0 0 40px 40px;
}
.product-item-popup .modal-footer-wrp {
  width: 100%;
  margin: 0;
}
.product-item-popup .popup-footer-btn {
  width: 100%;
}
.product-item-popup .popup-footer-btn span {
  color: #FF000F;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 767px) {
  .product-item-popup .popup-footer-btn span {
    font-size: 14px;
  }
}
.product-item-popup .popup-footer-btn label {
  height: 24px;
  width: 24px;
  background-color: #ffffff;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  margin-left: 10px;
}
@media only screen and (max-width: 767px) {
  .product-item-popup .popup-footer-btn label {
    height: 20px;
    width: 20px;
  }
}
.product-item-popup .popup-footer-btn label svg {
  fill: var(--theme-primary-color);
  margin: 0;
}
@media only screen and (max-width: 767px) {
  .product-item-popup .popup-footer-btn label svg {
    height: 10px;
    width: 10px;
  }
}
.product-item-popup .popup-footer-btn .primary-btn {
  width: 100%;
  font-size: 20px;
  font-weight: 700;
  min-height: 58px;
}
@media only screen and (max-width: 767px) {
  .product-item-popup .popup-footer-btn .primary-btn {
    font-size: 16px;
    min-height: 48px;
  }
}
.product-item-popup .popup-footer-btn .primary-btn:hover {
  background-color: var(--theme-primary-color);
}
.product-item-popup .popup-footer-btn .primary-btn.disabled {
  background: #969696;
  opacity: 0.3;
}
.product-item-popup .popup-footer-btn .primary-btn.disabled:hover {
  background-color: #969696;
}
.product-item-popup .popup-footer-btn .primary-btn.disabled label svg {
  fill: #000000;
}
.product-item-popup .popup-footer-btn .primary-btn span {
  color: #ffffff;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 700;
}
@media only screen and (max-width: 767px) {
  .product-item-popup .popup-footer-btn .primary-btn span {
    font-size: 16px;
  }
}
.product-item-popup .popup-footer-btn .qty-box {
  justify-content: center;
  margin-bottom: 15px;
}
.product-item-popup .popup-footer-btn .qty-box .primary-btn {
  min-height: auto;
  height: 24px;
  width: 24px;
  font-size: 26px;
  line-height: 8px;
  padding: 0;
}
.product-item-popup .popup-footer-btn .qty-box .primary-btn:hover {
  background-color: #F6F6F6;
}

.p-i-content {
  text-align: center;
  padding: 25px;
  border-bottom: 2px solid #F4F4F4;
}

.p-i-img {
  margin-bottom: 25px;
}
.p-i-img img {
  -webkit-border-radius: 29px;
  -moz-border-radius: 29px;
  border-radius: 29px;
  width: 100%;
  width: 425px;
  height: 215px;
  object-fit: cover;
}

.p-i-name h3 {
  font-size: 20px;
  font-weight: 700;
  color: #000000;
  margin-bottom: 15px;
}
@media only screen and (max-width: 767px) {
  .p-i-name h3 {
    font-size: 18px;
  }
}
.p-i-name p {
  color: #969696;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
  line-height: 24px;
  text-transform: lowercase;
}
@media only screen and (max-width: 767px) {
  .p-i-name p {
    font-size: 14px;
    line-height: 20px;
  }
}
.p-i-name span {
  color: #000000;
  font-size: 16px;
  font-weight: 600;
}

.product-options-list {
  padding: 25px;
  border-top: 2px solid #F4F4F4;
}
.product-options-list:first-child {
  border-top: none;
}

.p-options-box h4 {
  font-size: 16px;
  font-weight: 700;
  color: #000000;
  margin-bottom: 5px;
}
.p-options-box h4.red {
  color: #FF000F;
}
.p-options-box span {
  font-size: 14px;
  font-weight: 400;
  color: #828282;
}
.p-options-box span.red {
  color: #FF000F;
}

.p-variant-list {
  margin-top: 10px;
}
.p-variant-list .check_lb {
  padding-left: 46px;
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  max-width: 75%;
}
@media only screen and (max-width: 767px) {
  .p-variant-list .check_lb {
    font-size: 14px;
  }
}
.p-variant-list .checkmark.sign_in_checkmak {
  height: 30px;
  width: 30px;
  margin-top: -15px;
  background-color: #EAEAEA !important;
  box-shadow: none;
}
.p-variant-list .sign_in_checkmak::after {
  left: 3px !important;
  top: 3px !important;
  width: 24px !important;
  height: 24px !important;
  border-width: 0 !important;
  color: #000000 !important;
  background-color: #000000;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  transform: rotate(0deg);
}
.p-variant-list .p-v-b {
  border-top: 2px solid #F4F4F4;
  margin-top: 10px;
  padding-top: 10px;
}
.p-variant-list .p-v-b:first-child {
  border-top: none;
  margin-top: 0;
  padding-top: 0;
}
.p-variant-list .p-v-price {
  font-size: 16px;
  font-weight: 500;
}
@media only screen and (max-width: 767px) {
  .p-variant-list .p-v-price {
    font-size: 14px;
  }
}
.p-variant-list.input-checkbox .checkmark.sign_in_checkmak {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  height: 30px;
  width: 30px;
  background-color: #EAEAEA !important;
  border: 3px solid #EAEAEA;
  margin-top: -12.5px;
}
.p-variant-list.input-checkbox .checkmark.sign_in_checkmak.checked {
  background-color: #000000 !important;
}
.p-variant-list.input-checkbox .sign_in_checkmak::after {
  left: 7px !important;
  top: 0px !important;
  width: 10px !important;
  height: 19px !important;
  border-width: 0 3px 3px 0 !important;
  color: #ffffff !important;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  transform: rotate(45deg);
  background-color: transparent;
}

.comment-trade {
  background-color: #F6F6F6;
  -webkit-border-radius: 19px;
  -moz-border-radius: 19px;
  border-radius: 19px;
  padding: 15px;
}
.comment-trade h4 {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 10px;
}
.comment-trade h4 span {
  font-size: 12px;
  font-weight: 400;
}
.comment-trade .form-control {
  background-color: #ffffff;
}

.checkout-page .shop-page-header {
  background-color: #ffffff;
}
@media only screen and (max-width: 1023px) {
  .checkout-page .page-content-wrp {
    background-color: #ffffff;
    margin-bottom: 0;
  }
}

.page-title {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}
.page-title h2 {
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 700;
  position: relative;
  z-index: 2;
  padding: 0 10px;
}
@media only screen and (max-width: 1279px) {
  .page-title h2 {
    font-size: 20px;
  }
}
.page-title h2::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  background-color: #ffffff;
  height: 46px;
  width: 100%;
  margin-top: -23px;
  border-radius: 10px;
  z-index: -1;
  transform: rotate(-1.23deg);
  box-shadow: 0px 3.42803px 44.85px -15px rgba(15, 81, 118, 0.3);
}

.page-heading-section {
  margin-bottom: 35px;
  position: relative;
}
@media only screen and (max-width: 1023px) {
  .page-heading-section {
    display: none !important;
  }
}
.page-heading-section .primary-btn {
  background-color: transparent;
  position: absolute;
  left: 0;
}
.page-heading-section .primary-btn svg {
  stroke: var(--theme-primary-color);
  fill: transparent;
}
.page-heading-section .primary-btn:hover svg {
  stroke: #ffffff;
  fill: transparent;
}

.checkout-page-wrp {
  padding: 35px 60px;
}
@media only screen and (max-width: 1279px) {
  .checkout-page-wrp {
    padding: 20px 20px;
  }
}

.cart-content {
  max-width: 700px;
  margin: 0 auto;
}
@media only screen and (max-width: 1023px) {
  .cart-content {
    max-width: 100%;
  }
}

.mob-close-btn {
  display: none;
}

@media only screen and (max-width: 1023px) {
  .c-c-mob-popup {
    display: none;
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #ffffff;
    z-index: 999;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 30px;
  }
  .c-c-mob-popup .mob-close-btn {
    display: inline-flex;
  }
  .c-c-mob-popup .mob-close-btn.modal-content {
    padding: 20px 0;
    flex-direction: inherit;
    justify-content: center;
    border-bottom: 2px solid #F4F4F4;
    margin-bottom: 20px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }
  .c-c-mob-popup .mob-close-btn.modal-content .close {
    position: inherit;
    right: auto;
    left: auto;
    top: auto;
  }

  .c-c-mob-popup-show {
    display: block;
  }
  .c-c-mob-popup-show .box-heading {
    margin-bottom: 0;
  }
  .c-c-mob-popup-show .box-heading h3 {
    display: none;
  }
  .c-c-mob-popup-show .box-heading h4 {
    color: #000000;
    font-size: 14px;
    display: block;
    margin-bottom: 0;
  }
  .c-c-mob-popup-show .view-basket-section {
    display: none !important;
  }
  .c-c-mob-popup-show .summary-content-list:first-child {
    border-top: none;
  }
  .c-c-mob-popup-show .summary-total {
    border-top: 2px solid #F4F4F4;
    padding-top: 20px;
  }
}
.box-heading {
  margin-bottom: 20px;
}
@media only screen and (max-width: 1023px) {
  .box-heading {
    text-align: center;
  }
}
.box-heading h3 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0;
}
@media only screen and (max-width: 767px) {
  .box-heading h3 {
    font-size: 18px;
  }
}
.box-heading h4 {
  display: none;
}

.cart-content-wrp {
  background-color: #ffffff;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  border-radius: 40px;
  padding: 30px;
}
@media only screen and (max-width: 1023px) {
  .cart-content-wrp {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    padding: 0;
  }
}

.cart-content-wrp.checkout-part {
  padding: 0;
}

.view-basket-section {
  margin-bottom: 25px;
}
.view-basket-section .primary-btn {
  background-color: #F6F6F6;
  color: #000000;
}
.view-basket-section .primary-btn svg {
  fill: #000000;
}

.summary-content-list {
  padding: 20px 0;
  border-top: 2px solid #F6F6F6;
}
.summary-content-list .primary-btn.link-btn {
  color: #000000;
  margin-left: 15px;
}

.s-total {
  margin-top: 5px;
}
.s-total:first-child {
  margin-top: 0;
}
.s-total h4, .s-total span {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 0;
  color: #000000;
}
@media only screen and (max-width: 767px) {
  .s-total h4, .s-total span {
    font-size: 14px;
  }
}
.s-total span {
  width: 200px;
  text-align: right;
}
.s-total span.strike-price {
  color: #B8B8B8;
  text-decoration: line-through;
}
.s-total.code-promo h4, .s-total.code-promo span {
  color: #AD3E00;
}

.tooltip-title {
  position: relative;
}

.tooltip-svg {
  margin-left: 10px;
}
.tooltip-svg:hover .tooltip-content {
  display: inline-flex;
}
.tooltip-svg svg {
  fill: #000000;
  cursor: pointer;
}

.tooltip-content {
  display: none;
  position: absolute;
  top: -50px;
  z-index: 9;
}
.tooltip-content span {
  background-color: #EAEAEA;
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  padding: 16px 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  width: 100%;
  text-align: left;
}
@media only screen and (max-width: 767px) {
  .tooltip-content span {
    font-size: 14px;
    padding: 10px 10px;
  }
}

.promo-code {
  position: relative;
  width: 300px;
}
.promo-code .form-control {
  min-height: 50px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  padding: 6px 24px;
  color: #000000;
}
.promo-code .primary-btn {
  position: absolute;
  right: 10px;
  top: 50%;
  font-size: 16px;
  color: #ffffff;
  background-color: #000000;
  padding: 4px 15px;
  margin-top: -15px;
}
@media only screen and (max-width: 767px) {
  .promo-code .primary-btn {
    font-size: 14px;
  }
}
.promo-code.red .form-control {
  color: #FF000F;
}
.promo-code.deactive .primary-btn {
  background-color: #DCDCDC;
}

.no-valid-promo {
  margin-top: 15px;
}
.no-valid-promo p {
  margin-bottom: 0;
  color: #FF000F;
  font-weight: 600;
}

.no-login-promo {
  margin-top: 5px;
}
.no-login-promo p {
  margin-bottom: 0;
  color: #000000;
  font-weight: 600;
}

.summary-total-wrp {
  background-color: #F6F6F6;
  border: 2px solid rgba(0, 0, 0, 0.19);
  -webkit-border-radius: 11px;
  -moz-border-radius: 11px;
  border-radius: 11px;
  min-height: 75px;
  font-size: 28px;
  font-weight: 700;
  padding: 10px 20px;
}
@media only screen and (max-width: 767px) {
  .summary-total-wrp {
    font-size: 18px;
    min-height: 55px;
  }
}
.summary-total-wrp label {
  margin-bottom: 0;
}

.order-ready-msg {
  background-color: #FFEBD9;
  padding: 10px 10px;
  text-align: center;
  -webkit-border-radius: 11px;
  -moz-border-radius: 11px;
  border-radius: 11px;
  margin-top: 25px;
}
.order-ready-msg p {
  margin-bottom: 0;
  color: #AD3E00;
  font-size: 18px;
  font-weight: 700;
}
@media only screen and (max-width: 767px) {
  .order-ready-msg p {
    font-size: 14px;
    line-height: 20px;
  }
}

.checkout-form-inn .t-l-b-wrp {
  margin-bottom: 35px;
}
.checkout-form-inn .t-b-l .primary-btn {
  font-size: 18px;
  padding: 6px 5px;
}
@media only screen and (max-width: 767px) {
  .checkout-form-inn .t-b-l .primary-btn {
    font-size: 16px;
  }
}

.edit-cart-popup {
  max-width: 525px;
}
@media only screen and (max-width: 767px) {
  .edit-cart-popup .modal-main {
    margin-bottom: 190px;
  }
}
.edit-cart-popup .modal-content {
  padding: 45px 0 0;
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
@media only screen and (max-width: 767px) {
  .edit-cart-popup .modal-content {
    padding: 20px 0 0 0;
    height: 100vh;
  }
}
.edit-cart-popup .modal-content::-webkit-scrollbar {
  display: none;
}
@media only screen and (max-width: 767px) {
  .edit-cart-popup.modal-dialog-scrollable {
    max-height: 100%;
  }
}
.edit-cart-popup .modal-body {
  padding: 0 56px;
}
@media only screen and (max-width: 767px) {
  .edit-cart-popup .modal-body {
    padding: 0 15px;
  }
}
.edit-cart-popup .modal-body-wrp {
  margin-top: 70px;
}
@media only screen and (max-width: 767px) {
  .edit-cart-popup .modal-body-wrp {
    margin-top: 30px;
  }
}
.edit-cart-popup .cart-dropdown {
  background-color: inherit;
  position: inherit;
  width: auto;
  overflow: auto;
  box-shadow: none;
}
@media only screen and (max-width: 767px) {
  .edit-cart-popup .cart-dropdown {
    height: auto !important;
  }
}
.edit-cart-popup .cart-dropdown-wrp {
  padding: 0;
  margin-bottom: 0;
}
.edit-cart-popup .c-drop-footer {
  position: inherit;
  padding: 15px 25px 36px;
  width: 100%;
}
.edit-cart-popup .c-drop-footer .summary-total {
  width: 100%;
}
.edit-cart-popup .modal-footer {
  position: sticky;
  bottom: 0;
  margin: 0;
  padding: 0;
}
@media only screen and (max-width: 767px) {
  .edit-cart-popup .modal-footer {
    display: none;
  }
}
.edit-cart-popup .modal-footer > * {
  margin: 0;
}
.edit-cart-popup .discount-offer {
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  border-radius: 18px;
  margin-bottom: 20px;
  padding: 15px;
}
.edit-cart-popup .discount-offer p {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 0;
}
@media only screen and (max-width: 767px) {
  .edit-cart-popup .cart-product-list {
    padding: 0;
  }
}
.edit-cart-popup .shop-footer, .edit-cart-popup .menu-cart-btn {
  display: none;
}
@media only screen and (max-width: 767px) {
  .edit-cart-popup .shop-footer, .edit-cart-popup .menu-cart-btn {
    display: block;
  }
}
.edit-cart-popup .add-new-pro-btn .primary-btn:hover {
  color: var(--theme-primary-color);
  background-color: var(--theme-third-color);
}

.product-item-popup .add-new-pro-btn {
  padding: 13px 56px;
  border-top: 2px solid #F4F4F4;
}
@media only screen and (max-width: 767px) {
  .product-item-popup .add-new-pro-btn {
    padding: 13px 15px;
  }
}
.product-item-popup .add-new-pro-btn .primary-btn {
  min-width: 221px;
  min-height: 40px;
  width: auto;
  font-size: 16px;
  font-weight: 500;
  padding: 5px 15px;
}
.product-item-popup .add-new-pro-btn .primary-btn label svg {
  height: 12px;
  width: 12px;
}

@keyframes loader4 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader4 {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--theme-fourth-color);
  height: 100%;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
}
.loader::before {
  content: "";
  width: 45px;
  height: 45px;
  display: inline-block;
  padding: 0px;
  border-radius: 100%;
  border: 5px solid;
  border-top-color: var(--theme-primary-color);
  border-bottom-color: rgba(255, 255, 255, 0.3);
  border-left-color: var(--theme-primary-color);
  border-right-color: rgba(255, 255, 255, 0.3);
  -webkit-animation: loader4 1s ease-in-out infinite;
  animation: loader4 1s ease-in-out infinite;
}

.main_loader {
  background-color: #82cffb;
  opacity: 1;
}
.main_loader::before {
  content: "";
  width: 45px;
  height: 45px;
  display: inline-block;
  padding: 0px;
  border-radius: 100%;
  border: 5px solid;
  border-top-color: #0F5176;
  border-bottom-color: rgba(255, 255, 255, 0.3);
  border-left-color: #0F5176;
  border-right-color: rgba(255, 255, 255, 0.3);
  -webkit-animation: loader4 1s ease-in-out infinite;
  animation: loader4 1s ease-in-out infinite;
}

.missing_choice {
  cursor: pointer;
  color: #FF000F !important;
}

.c-type-delivery {
  padding: 40px 40px 25px 40px;
}
@media only screen and (max-width: 1023px) {
  .c-type-delivery {
    padding: 0 0 20px 0;
  }
}
.c-type-delivery h4 {
  color: #000000;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
}
@media only screen and (max-width: 767px) {
  .c-type-delivery h4 {
    font-size: 16px;
  }
}
.c-type-delivery .date-dropdown label {
  color: #000000;
}
.c-type-delivery .date-dropdown .d-dropdown {
  min-width: 197px;
}
.c-type-delivery .date-dropdown.hour-dropdown {
  margin-top: 0;
  margin-left: 20px;
}
.c-type-delivery .date-dropdown.hour-dropdown .d-dropdown {
  min-width: 110px;
}

.c-drop-deli-btn {
  position: relative;
}
@media only screen and (max-width: 767px) {
  .c-drop-deli-btn {
    min-width: 155px;
  }
}
.c-drop-deli-btn .primary-btn {
  background-color: #000000;
  color: #ffffff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  padding: 5px 10px;
  min-width: 207px;
  min-height: 46px;
  font-size: 16px;
}
@media only screen and (max-width: 1279px) {
  .c-drop-deli-btn .primary-btn {
    min-width: auto;
  }
}
@media only screen and (max-width: 767px) {
  .c-drop-deli-btn .primary-btn {
    font-size: 14px;
    padding: 5px 1px;
    min-width: 100%;
  }
}
.c-drop-deli-btn .primary-btn svg {
  fill: #ffffff;
}
@media only screen and (max-width: 767px) {
  .c-drop-deli-btn .primary-btn svg {
    margin-right: 0;
  }
  .c-drop-deli-btn .primary-btn svg.svg1 {
    width: 12px;
    height: 14px;
  }
  .c-drop-deli-btn .primary-btn svg.svg2 {
    width: 20px;
    height: 20px;
  }
  .c-drop-deli-btn .primary-btn svg.svg3 {
    width: 12px;
    height: 16px;
  }
  .c-drop-deli-btn .primary-btn svg.svg4 {
    width: 20px;
    height: 20px;
  }
}
.c-drop-deli-btn .primary-btn span {
  margin-left: 10px;
}
@media only screen and (max-width: 767px) {
  .c-drop-deli-btn .primary-btn span {
    margin-left: 5px;
  }
}
.c-drop-deli-btn .primary-btn span svg {
  stroke: #ffffff;
  fill: #000000;
  margin-right: 0;
}
.c-drop-deli-btn ul {
  z-index: 1;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  animation: growDown 300ms ease-in-out forwards;
  transform-origin: top center;
}
.c-drop-deli-btn ul li {
  color: #000000;
  font-size: 16px;
  margin-bottom: 0;
}
.c-drop-deli-btn.active .primary-btn {
  -webkit-border-radius: 10px 10px 0 0;
  -moz-border-radius: 10px 10px 0 0;
  border-radius: 10px 10px 0 0;
}
.c-drop-deli-btn.active .primary-btn span svg {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
}
.c-drop-deli-btn.active ul li .primary-btn {
  -webkit-border-radius: 0 0 10px 10px;
  -moz-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
  border-top: 2px solid rgba(255, 255, 255, 0.1);
  min-width: 100%;
}

.confirm-voter-add h4 {
  color: #000000;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
}
@media only screen and (max-width: 767px) {
  .confirm-voter-add h4 {
    font-size: 16px;
  }
}

.confirm-voter-main {
  padding: 0px 10px 10px 10px;
  border-top: 2px solid #F4F4F4;
}
@media only screen and (max-width: 1023px) {
  .confirm-voter-main {
    padding: 0;
  }
}

.confirm-voter-wrp {
  padding: 30px 30px 0 30px;
}
@media only screen and (max-width: 1023px) {
  .confirm-voter-wrp {
    padding: 20px 0 0 0;
  }
}
.confirm-voter-wrp .form-label {
  color: #000000;
  font-size: 16px;
}
@media only screen and (max-width: 1279px) {
  .confirm-voter-wrp .form-label {
    font-size: 14px;
  }
}

.paymetn-means {
  background-color: #F0F0F0;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  padding: 20px 20px 10px 20px;
  margin-top: 30px;
}
.paymetn-means h3 {
  font-size: 18px;
  color: #000000;
}
@media only screen and (max-width: 767px) {
  .paymetn-means h3 {
    font-size: 16px;
  }
}
.paymetn-means .deli-type-btn .primary-btn {
  background-color: #ffffff;
  min-height: 70px;
  width: 100%;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}
.paymetn-means .deli-type-btn .primary-btn .btn-mark {
  background-color: #F1F1F1;
  height: 42px;
  width: 42px;
  margin-right: 20px;
}
@media only screen and (max-width: 767px) {
  .paymetn-means .deli-type-btn .primary-btn .btn-mark {
    height: 24px;
    width: 24px;
    margin-right: 10px;
  }
}
.paymetn-means .deli-type-btn .primary-btn .btn-mark .blue-tick {
  height: 28px;
  width: 28px;
  margin: 0;
}
@media only screen and (max-width: 767px) {
  .paymetn-means .deli-type-btn .primary-btn .btn-mark .blue-tick {
    height: 16px;
    width: 16px;
  }
  .paymetn-means .deli-type-btn .primary-btn .btn-mark .blue-tick svg {
    width: 10px;
    height: 9px;
  }
}
.paymetn-means .deli-type-btn .primary-btn svg {
  margin: 0;
}
.paymetn-means .deli-type-btn .primary-btn label {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 600;
  margin-left: 15px;
}
@media only screen and (max-width: 1023px) {
  .paymetn-means .deli-type-btn .primary-btn label {
    font-size: 16px;
  }
}
@media only screen and (max-width: 1023px) {
  .paymetn-means .deli-type-btn .primary-btn label {
    font-size: 14px;
    margin-left: 10px;
  }
}
.paymetn-means .deli-type-btn .primary-btn.active {
  background-color: #e1f0ff;
}
.paymetn-means .deli-type-btn .primary-btn.active .btn-mark {
  background-color: #ffffff;
}
.paymetn-means .another-card {
  position: absolute;
  right: 12px;
  top: 50%;
  margin-top: -19px;
}
.paymetn-means .another-card .primary-btn {
  background-color: #000000;
  color: #ffffff;
  min-height: auto;
  width: auto;
  font-size: 14px;
  margin-bottom: 0;
  min-width: auto;
}
@media only screen and (max-width: 767px) {
  .paymetn-means .another-card .primary-btn {
    padding: 8px 2px;
  }
}
.paymetn-means .another-card .primary-btn svg {
  fill: #ffffff;
  margin-right: 10px;
}
@media only screen and (max-width: 767px) {
  .paymetn-means .another-card .primary-btn svg {
    margin-right: 5px;
  }
}

.payment-card-wrp {
  position: relative;
  cursor: pointer;
  margin-top: 10px;
}

.paymetn-means .deli-type-btn .add-payment-card {
  display: flex;
  justify-content: center;
}
.paymetn-means .deli-type-btn .add-payment-card .primary-btn {
  width: auto;
  background-color: #ffffff;
  color: #000000;
  justify-content: center;
  margin-bottom: 0;
  font-size: 18px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  min-height: 52px;
}
@media only screen and (max-width: 767px) {
  .paymetn-means .deli-type-btn .add-payment-card .primary-btn {
    font-size: 15px;
  }
}
.paymetn-means .deli-type-btn .add-payment-card .primary-btn span {
  height: 32px;
  width: 32px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  margin-left: 20px;
}
.paymetn-means .deli-type-btn .add-payment-card .primary-btn span svg {
  fill: #ffffff;
}

.use-wallet {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 20px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  margin-bottom: 25px;
}

.to-order-section {
  border-top: 2px solid #DFDFDF;
  padding-top: 20px;
  margin-top: 20px;
}

.use-wallet-text .u-w-p {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  font-weight: 700;
}
@media only screen and (max-width: 767px) {
  .use-wallet-text .u-w-p {
    font-weight: 700;
  }
}
@media only screen and (max-width: 567px) {
  .use-wallet-text .u-w-p {
    position: relative;
    display: inline-block;
  }
}
.use-wallet-text .u-w-p .tooltip-svg {
  display: flex;
  order: 3;
}
@media only screen and (max-width: 567px) {
  .use-wallet-text .u-w-p .tooltip-svg {
    display: inline-block;
  }
}
.use-wallet-text .u-w-p .tooltip-svg svg {
  margin-left: 0;
}
.use-wallet-text .u-w-p .tooltip-content {
  left: 0;
}
@media only screen and (max-width: 767px) {
  .use-wallet-text .u-w-p .tooltip-content {
    top: -30px;
  }
}
@media only screen and (max-width: 567px) {
  .use-wallet-text .u-w-p .tooltip-content {
    top: -90px;
  }
}
.use-wallet-text .u-w-p svg {
  fill: #000000;
  margin-left: 5px;
}
.use-wallet-text .u-w-p span {
  margin-left: 5px;
  font-weight: 400;
}
@media only screen and (max-width: 567px) {
  .use-wallet-text .u-w-p span {
    display: block;
    margin-top: 5px;
    margin-left: 0;
  }
}

.to-order-btn .primary-btn {
  background-color: #000000;
  min-height: 102px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  color: #ffffff;
  width: 100%;
  padding: 11px 35px;
}
@media only screen and (max-width: 1023px) {
  .to-order-btn .primary-btn {
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    min-height: 70px;
  }
}
@media only screen and (max-width: 767px) {
  .to-order-btn .primary-btn {
    min-height: 60px;
    padding: 11px 10px;
  }
}
.to-order-btn .primary-btn label {
  margin-bottom: 0;
  font-size: 23px;
  cursor: pointer;
}
@media only screen and (max-width: 1279px) {
  .to-order-btn .primary-btn label {
    font-size: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .to-order-btn .primary-btn label {
    font-size: 16px;
  }
}
.to-order-btn .primary-btn span {
  font-size: 18px;
}
@media only screen and (max-width: 1279px) {
  .to-order-btn .primary-btn span {
    font-size: 16px;
  }
}

.card-error-popup {
  max-width: 525px;
}
.card-error-popup .modal-content {
  padding: 45px 20px 30px 20px;
  text-align: center;
}

.card-error-title {
  border-bottom: 2px solid #F4F4F4;
  padding-bottom: 30px;
}
.card-error-title p {
  color: #FF000F;
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 0;
}
.card-error-title p span {
  display: block;
}

.change-card-section {
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.change-card-section .primary-btn {
  min-width: 274px;
  min-height: 60px;
}
.change-card-section .primary-btn.secondary-btn {
  margin-top: 15px;
}

.card-f-support {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 2px solid #F4F4F4;
  padding-top: 30px;
}
.card-f-support span {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #0F5176;
}
.card-f-support .primary-btn {
  background-color: #0F5176;
  color: #ffffff;
  font-size: 16px;
  padding: 5px 11px;
}
.card-f-support .primary-btn svg {
  fill: #ffffff;
}

.card-edit-popup .modal-content {
  padding: 45px 0 0 0;
  text-align: center;
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.card-edit-popup .modal-content::-webkit-scrollbar {
  display: none;
}
.card-edit-popup .c-drop-footer {
  position: inherit;
  padding: 20px;
  width: 100%;
}
.card-edit-popup .c-drop-footer .primary-btn label {
  height: 30px;
  width: 30px;
  background-color: #ffffff;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  margin-left: 10px;
}
.card-edit-popup .c-drop-footer .primary-btn label svg {
  fill: var(--theme-primary-color);
  margin: 0;
}
.card-edit-popup .c-drop-footer .primary-btn:hover label {
  background-color: var(--theme-primary-color);
}
.card-edit-popup .c-drop-footer .primary-btn:hover label svg {
  fill: #ffffff;
}
.card-edit-popup .modal-footer {
  position: sticky;
  bottom: 0;
  margin: 0;
  padding: 0;
}
.card-edit-popup .modal-footer > * {
  margin: 0;
}
.card-edit-popup .modal-body-wrp {
  padding: 30px 20px;
}
@media only screen and (max-width: 767px) {
  .card-edit-popup .modal-body-wrp {
    margin-top: 0;
  }
}
.card-edit-popup .modal-footer-wrp {
  width: 100%;
}

.c-card-list ul {
  margin: 0;
  padding: 0;
}
.c-card-list li .primary-btn {
  background-color: #f2f2f2;
  color: #000000;
  font-size: 20px;
  width: 100%;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  justify-content: flex-start;
  min-height: 80px;
}
@media only screen and (max-width: 767px) {
  .c-card-list li .primary-btn {
    min-height: 60px;
    font-size: 16px;
  }
}
.c-card-list li .primary-btn svg {
  fill: #000000;
  margin-right: 20px;
  margin-left: 0;
}
@media only screen and (max-width: 767px) {
  .c-card-list li .primary-btn svg {
    width: 30px;
    height: 23px;
  }
}
.c-card-list li .primary-btn.active {
  background-color: #e1f0ff;
  border-color: #0A89FF;
}

.card-edit-popup .form-label {
  color: #000000;
}
@media only screen and (max-width: 767px) {
  .card-edit-popup .form-label {
    font-size: 14px;
  }
}
.card-edit-popup .form-label.error {
  color: #FF000F;
}
.card-edit-popup .form-control.error {
  background-color: #FFEBEB;
  color: #FF000F;
}
.card-edit-popup .c-drop-footer .primary-btn {
  width: auto;
  min-height: 60px;
}
@media only screen and (max-width: 767px) {
  .card-edit-popup .c-drop-footer .primary-btn {
    font-size: 14px;
    min-height: 43px;
  }
}
.card-edit-popup .c-drop-footer .primary-btn.back-btn-popup {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  height: 60px;
  width: 60px;
  border: 5px solid rgba(0, 0, 0, 0.1);
  padding: 0;
  background-color: #ffffff;
  margin-right: 20px;
  min-height: auto;
}
@media only screen and (max-width: 767px) {
  .card-edit-popup .c-drop-footer .primary-btn.back-btn-popup {
    height: 43px;
    width: 43px;
  }
  .card-edit-popup .c-drop-footer .primary-btn.back-btn-popup svg {
    width: 10px;
    height: 17px;
  }
}
.card-edit-popup .c-drop-footer .primary-btn.back-btn-popup svg {
  margin: 0;
  fill: #000000;
}
.card-edit-popup .form-control.StripeElement {
  padding: 15px 14px;
}

.error-msg {
  color: #FF000F;
  font-size: 14px;
  font-weight: 400;
  margin-top: 10px;
}

.cart-total-btn-mob {
  display: none;
}

@media only screen and (max-width: 1023px) {
  .cart-total-btn-mob {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid #F4F4F4;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

  .cart-count-btn {
    background-color: #F6F6F6;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    padding: 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    min-width: 165px;
    min-height: 100px;
    margin: 0 5px;
  }
  .cart-count-btn svg {
    fill: #000000;
    margin-bottom: 15px;
  }
  .cart-count-btn span {
    font-size: 14px;
  }
  .cart-count-btn span.price {
    font-size: 18px;
    font-weight: bold;
  }
  .cart-count-btn label {
    font-size: 14px;
    margin-bottom: 0;
    color: #959595;
  }
}
.cart-total-btn-mob1 {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid #F4F4F4;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.cart-count-btn1 {
  background-color: #F6F6F6;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  cursor: pointer;
  padding: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-width: 165px;
  min-height: 100px;
  margin: 0 5px;
}
.cart-count-btn1 svg {
  fill: #000000;
  margin-bottom: 15px;
}
.cart-count-btn1 span {
  font-size: 14px;
}
.cart-count-btn1 span.price {
  font-size: 18px;
  font-weight: bold;
}
.cart-count-btn1 label {
  font-size: 14px;
  margin-bottom: 0;
  color: #959595;
}

.address-page-wrp {
  width: 100%;
  padding: 50px 40px;
}
@media only screen and (max-width: 1279px) {
  .address-page-wrp {
    padding: 50px 20px;
  }
}
@media only screen and (max-width: 1023px) {
  .address-page-wrp {
    padding: 20px 20px;
  }
}

.address-page-inn {
  width: 100%;
}

.account-page-container {
  max-width: 1400px;
  margin: 0 auto;
}

.pro-img {
  height: 128px;
  width: 128px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
@media only screen and (max-width: 1023px) {
  .pro-img {
    height: 98px;
    width: 98px;
  }
}
.pro-img img {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

.pro-name {
  margin-left: 30px;
}
.pro-name span {
  font-size: 30px;
  font-weight: bold;
  color: #000000;
  text-transform: capitalize;
}
@media only screen and (max-width: 1023px) {
  .pro-name span {
    font-size: 18px;
  }
}

.account-header-top {
  margin-bottom: 30px;
}

.pro-content {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .profile-detail {
    flex-direction: column;
  }
}

.pro-list {
  width: 250px;
  margin-right: 30px;
}
@media only screen and (max-width: 767px) {
  .pro-list {
    width: 100%;
    margin-right: 0;
    border-bottom: 2px solid var(--theme-secondary-color);
    margin-bottom: 30px;
    display: none;
  }
}
.pro-list ul {
  padding: 0;
  margin: 0;
}
.pro-list li {
  margin-bottom: 25px;
}
.pro-list li a {
  display: block;
  font-weight: bold;
  font-size: 20px;
  text-transform: capitalize;
  color: var(--theme-primary-color);
}
.pro-list li a:hover {
  color: #000000;
}
@media only screen and (max-width: 1023px) {
  .pro-list li a {
    font-size: 18px;
  }
}
.pro-list li.active a {
  color: #000000;
}

.pro-cont-title {
  margin-bottom: 30px;
}
.pro-cont-title h5 {
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 0;
}
@media only screen and (max-width: 1023px) {
  .pro-cont-title h5 {
    font-size: 18px;
  }
}
.pro-cont-title.add-map {
  margin-top: 30px;
}
.pro-cont-title.add-map h5 {
  text-transform: none;
  margin-bottom: 0;
}

.modi-btn a {
  font-weight: bold;
  font-size: 20px;
  color: var(--theme-primary-color);
}
@media only screen and (max-width: 1023px) {
  .modi-btn a {
    font-size: 18px;
  }
}
.modi-btn a:hover {
  color: var(--theme-secondary-color);
}
.modi-btn .close {
  opacity: 1;
  font-size: 38px;
  font-weight: 500;
  color: var(--theme-primary-color);
}
.modi-btn .close:hover {
  color: var(--theme-secondary-color);
}

.pro-cont-detail .form-block {
  margin-bottom: 20px;
  position: relative;
}
.pro-cont-detail .form-block .form-label {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  text-transform: capitalize;
}
.pro-cont-detail .form-block .form-control {
  border: 2px solid transparent;
  background-color: #ffffff;
  padding-right: 50px;
}
.pro-cont-detail .form-block.modifier .form-control {
  border-color: var(--theme-secondary-color);
}
.pro-cont-detail .form-block label {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
}
@media only screen and (max-width: 1023px) {
  .pro-cont-detail .form-block label {
    font-size: 16px;
  }
}
.pro-cont-detail h5 {
  font-size: 22px;
  margin-bottom: 15px;
  color: #000000;
}
@media only screen and (max-width: 1023px) {
  .pro-cont-detail h5 {
    font-size: 18px;
  }
}
.pro-cont-detail h5 a {
  color: var(--theme-primary-color);
}
.pro-cont-detail .pro-cont-title h5 {
  margin-bottom: 0;
}

.account-page .address-page-wrp {
  min-height: 100vh;
  align-items: start;
}

.checkbox-block {
  position: absolute;
  right: 40px;
  top: 50%;
}
.checkbox-block.right-icons {
  margin-top: -20px;
  right: 10px;
}
.checkbox-block.right-icons .close {
  margin: 0 15px;
}
@media only screen and (max-width: 767px) {
  .checkbox-block.right-icons .close {
    margin: 0 8px;
  }
}
.checkbox-block.right-icons .close.correct span {
  display: block;
  width: 12px;
  height: 21px;
  border: solid var(--theme-primary-color);
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  color: var(--theme-primary-color);
}
.checkbox-block.right-icons .close.correct span:hover {
  border-color: var(--theme-secondary-color);
}
.checkbox-block.payment-page {
  top: 50%;
}
.checkbox-block .check_lb {
  position: inherit;
}

.order-page-tab .nav-tabs {
  border: none;
  margin: 0 -1rem;
}
.order-page-tab .nav-item {
  margin-bottom: 0;
}
.order-page-tab .nav-link {
  font-size: 20px;
  font-weight: bold;
  border: none;
}
@media only screen and (max-width: 767px) {
  .order-page-tab .nav-link {
    font-size: 18px;
  }
}
.order-page-tab .nav-link:hover {
  border: none;
  color: #000000;
}
.order-page-tab .nav-link.active {
  background: none;
  border: none;
  color: #000000;
}

.order-page-list {
  margin-top: 50px;
}
@media only screen and (max-width: 767px) {
  .order-page-list {
    margin-top: 30px;
  }
}
.order-page-list ul {
  padding: 0;
  margin: 0;
}
.order-page-list li {
  margin-top: 25px;
  padding-top: 25px;
  margin-bottom: 0;
  border-top: 1px solid var(--theme-secondary-color);
}
.order-page-list li:first-child {
  border-top: none;
  margin-top: 0;
  padding-top: 0;
}
.order-page-list li h5 {
  font-size: 20px;
  color: #959595;
}
@media only screen and (max-width: 767px) {
  .order-page-list li h5 {
    font-size: 16px;
  }
}
.order-page-list .o-images {
  height: 90px;
  width: 90px;
  display: flex;
  align-items: center;
}
.order-page-list .o-images img {
  max-width: 90px;
  width: 90px;
  height: 90px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

.o-name {
  cursor: pointer;
}
.o-name h4 {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 500;
  color: #000000;
}
@media only screen and (max-width: 767px) {
  .o-name h4 {
    font-size: 18px;
  }
}
.o-name .o-number {
  color: #C4C4C4;
  font-weight: 500;
}

.o-content {
  margin-left: 30px;
  width: 100%;
}

.o-price {
  font-size: 20px;
  font-weight: bold;
  display: block;
}
@media only screen and (max-width: 767px) {
  .o-price {
    font-size: 18px;
  }
}

.o-time {
  margin-top: 15px;
}

.o-p-time {
  font-size: 20px;
}
@media only screen and (max-width: 767px) {
  .o-p-time {
    font-size: 18px;
  }
}

.form-no-input .form-block {
  margin-bottom: 30px;
}
.form-no-input .form-block .form-control {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid #919191;
  background-color: transparent;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  padding: 10px 50px 10px 0;
  color: #808080;
  height: auto;
}
.form-no-input .form-block label {
  margin-bottom: 0;
}

.order-map-popup .modal-body-wrp {
  margin-top: 0;
  text-align: center;
}
.order-map-popup .modal-content {
  padding: 30px;
}
@media only screen and (max-width: 767px) {
  .order-map-popup .modal-content {
    padding: 20px;
  }
}

.profile_button {
  background: none;
  border: none;
  font-weight: bold;
  color: unset;
}

#carddetail {
  border-radius: 4px;
  padding: 12px;
  border: 1px solid var(--dark-theme-color);
  height: 44px;
  width: 100%;
  background: white;
  max-width: unset !important;
}

input[name=cardnumber] {
  color: blue;
}

#stripe-cvc-element {
  background: #ffffff !important;
  margin: 5px 0px !important;
  padding: 10px 5px !important;
}

.form-control.StripeElement {
  padding: 20px 24px;
}

.forgot-popup {
  max-width: 450px;
  padding: 0 10px;
  margin: 0 auto;
}
@media screen and (max-width: 567px) {
  .forgot-popup {
    max-width: 100%;
  }
}
.forgot-popup .modal-content {
  padding: 60px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .forgot-popup .modal-content {
    padding: 30px;
  }
}
.forgot-popup .popup-title h2 {
  font-size: 20px;
}
.forgot-popup .popup-title p {
  color: #959595;
}

@media screen and (max-width: 767px) {
  .mob-full-popup {
    max-width: 100%;
    padding: 0;
    margin: 0;
  }
  .mob-full-popup .modal-content {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }
  .mob-full-popup .modal-content .close.with-name {
    position: inherit;
    left: auto;
    right: auto;
    top: auto;
    display: flex !important;
    float: none;
    justify-content: center;
    border-bottom: 2px solid #F4F4F4;
    padding-bottom: 20px;
    width: 100%;
  }
}

.order-tracking-page .home-wrp {
  background: rgba(0, 0, 0, 0.8);
  min-height: auto;
  padding: 20px;
}
.order-tracking-page.home-main {
  min-height: calc(100vh - 88px);
  height: auto;
}
@media screen and (max-width: 767px) {
  .order-tracking-page .home-content {
    justify-content: center;
  }
}

.tracking-profile {
  background-color: #ffffff;
  -webkit-border-radius: 45px;
  -moz-border-radius: 45px;
  border-radius: 45px;
  padding: 40px 47px;
  min-width: 600px;
}
@media screen and (max-width: 1439px) {
  .tracking-profile {
    padding: 32px 27px;
  }
}
@media screen and (max-width: 767px) {
  .tracking-profile {
    padding: 22px 17px 40px;
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    border-radius: 35px;
    min-width: 100%;
  }
}

.t-profile-img {
  margin-right: 35px;
}
@media screen and (max-width: 1439px) {
  .t-profile-img {
    margin-right: 20px;
  }
}
.t-profile-img span img {
  height: 70px;
  width: 70px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
@media screen and (max-width: 1439px) {
  .t-profile-img span img {
    height: 50px;
    width: 50px;
  }
}

.t-p-thanku-msg {
  display: flex;
  flex: 1;
}
.t-p-thanku-msg h3 {
  margin-bottom: 0;
  font-size: 40px;
  font-weight: 700;
  word-break: break-all;
}
@media screen and (max-width: 1439px) {
  .t-p-thanku-msg h3 {
    font-size: 30px;
  }
}
@media screen and (max-width: 767px) {
  .t-p-thanku-msg h3 {
    font-size: 16px;
  }
}

.order-tracking-detail {
  margin-top: 30px;
}

.o-t-btn {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}
.o-t-btn .primary-btn {
  min-height: 70px;
  padding: 11px 40px;
}
@media screen and (max-width: 1439px) {
  .o-t-btn .primary-btn {
    min-height: 50px;
  }
}
@media screen and (max-width: 767px) {
  .o-t-btn .primary-btn {
    min-height: auto;
  }
}
.o-t-btn .primary-btn:hover {
  background-color: var(--theme-primary-color);
  color: #ffffff;
}

.order-number {
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .order-number {
    font-size: 16px;
  }
}
.order-number label {
  margin-bottom: 0;
}
.order-number span {
  display: block;
}

.order-time {
  text-align: center;
  margin-top: 50px;
}
@media screen and (max-width: 767px) {
  .order-time {
    margin-top: 30px;
  }
}
.order-time span {
  color: #ffffff;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 15px;
  display: block;
}
@media screen and (max-width: 767px) {
  .order-time span {
    font-size: 16px;
  }
}

.time-show {
  background-color: #ffffff;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  padding: 15px 30px;
  margin: 0 auto;
}
@media screen and (max-width: 1439px) {
  .time-show {
    padding: 10px 30px;
  }
}
.time-show label {
  display: block;
  font-size: 20px;
  font-weight: 700;
  color: var(--theme-primary-color);
  margin-bottom: 0;
}
@media screen and (max-width: 1439px) {
  .time-show label {
    font-size: 22px;
  }
}
@media screen and (max-width: 767px) {
  .time-show label {
    font-size: 20px;
  }
}

.empty-cart {
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  max-width: 250px;
  margin: 0 auto;
}

.e-c-icon svg {
  fill: #000000;
  opacity: 0.2;
}

.e-c-text {
  text-align: center;
  margin-top: 10px;
}
.e-c-text span {
  color: #000000;
  opacity: 0.2;
  font-size: 20px;
  font-weight: 500;
}

.alert-popup {
  max-width: 525px;
}
.alert-popup .modal-body-wrp {
  text-align: center;
}

.alert-icon svg {
  fill: var(--theme-primary-color);
}

.alert-text {
  margin-top: 35px;
}
.alert-text p {
  color: var(--theme-primary-color);
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.back-button {
  display: none;
}
@media screen and (max-width: 1023px) {
  .back-button {
    display: block;
  }
}

.checkout-logo {
  display: block;
}
@media screen and (max-width: 1023px) {
  .checkout-logo {
    display: none;
  }
}

.checkout-heder {
  display: none;
}
@media screen and (max-width: 1023px) {
  .checkout-heder {
    display: block;
  }
}

.order-number-section {
  background-color: var(--theme-primary-color);
  padding: 40px;
}
@media screen and (max-width: 767px) {
  .order-number-section {
    padding: 20px;
  }
}

.o-n-section-wrp {
  flex-direction: column;
  flex: 1;
}
.o-n-section-wrp h3 {
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 15px;
}
@media screen and (max-width: 767px) {
  .o-n-section-wrp h3 {
    font-size: 20px;
  }
}
.o-n-section-wrp .link-btn {
  color: #ffffff;
  font-weight: 500;
}

.oder-no {
  background-color: #ffffff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  padding: 5px 15px;
  font-size: 28px;
  font-weight: 700;
  color: var(--theme-primary-color);
  margin-bottom: 30px;
}
@media screen and (max-width: 767px) {
  .oder-no {
    font-size: 20px;
  }
}

.order-time-section {
  padding: 60px 20px;
  background-color: #ffffff;
}
@media screen and (max-width: 767px) {
  .order-time-section {
    padding: 20px;
  }
}

.o-t-section-wrp {
  flex-direction: column;
  flex: 1;
  max-width: 750px;
  margin: 0 auto;
}
.o-t-section-wrp h3 {
  font-size: 28px;
  font-weight: 700;
}
@media screen and (max-width: 767px) {
  .o-t-section-wrp h3 {
    font-size: 18px;
  }
}

.order-add-time {
  position: relative;
  margin-top: 30px;
}
@media screen and (max-width: 767px) {
  .order-add-time {
    display: block !important;
    margin-top: 10px;
  }
}

.order-per {
  margin-right: 50px;
}
@media screen and (max-width: 767px) {
  .order-per {
    margin-right: 0;
  }
}
.order-per.order-a {
  margin-left: 50px;
  margin-right: 0;
}
@media screen and (max-width: 767px) {
  .order-per.order-a {
    margin-left: 0;
    margin-top: 50px;
  }
}
.order-per.order-a::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  background-color: var(--theme-third-color);
  width: 3px;
  height: 100%;
}
@media screen and (max-width: 767px) {
  .order-per.order-a::before {
    height: 3px;
    width: 140px;
    top: 50%;
    left: 24%;
  }
}
.order-per.order-a.o-only-address {
  margin-left: 0;
}
.order-per.order-a.o-only-address::before {
  display: none;
}
.order-per label {
  margin-bottom: 15px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: var(--theme-primary-color);
  display: flex;
  justify-content: center;
}

.number-box {
  background-color: #ffffff;
  box-shadow: 0px 4px 34px -10px rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  padding: 15px 20px;
  width: 272px;
  min-height: 77px;
}

.n-box-icon {
  margin-right: 15px;
}
.n-box-icon span {
  background-color: var(--theme-primary-color);
  height: 27px;
  width: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.n-box-icon span svg {
  fill: #ffffff;
}

.n-box-details {
  color: var(--theme-primary-color);
}
.n-box-details span {
  display: block;
  font-size: 18px;
  font-weight: 700;
  color: var(--theme-primary-color);
}
@media screen and (max-width: 767px) {
  .n-box-details span {
    font-size: 16px;
  }
}
.n-box-details a {
  color: var(--theme-primary-color);
  font-size: 16px;
  font-weight: 500;
}
.n-box-details p {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
  line-height: 20px;
}

.order-a .number-box {
  max-width: 290px;
}

.status-order-command {
  background-color: var(--theme-third-color);
  padding: 30px;
}
.status-order-command .primary-btn {
  margin-top: 25px;
}

.s-o-c-wrp h3 {
  font-size: 16px;
  font-weight: 500;
}

.s-o-c-wrp {
  flex: 1;
  flex-direction: column;
}

.order-status-box {
  background-color: #ffffff;
  box-shadow: 0px 4px 34px -10px rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 28px;
  -moz-border-radius: 28px;
  border-radius: 28px;
  padding: 25px 45px;
  min-height: 132px;
  max-width: 500px;
  position: relative;
}
@media screen and (max-width: 767px) {
  .order-status-box {
    padding: 15px 15px;
    min-height: auto;
  }
}
.order-status-box svg {
  fill: var(--theme-primary-color);
}
.order-status-box span {
  font-size: 20px;
  font-weight: 500;
  color: var(--theme-primary-color);
  margin-left: 20px;
}
@media screen and (max-width: 767px) {
  .order-status-box span {
    font-size: 18px;
  }
}
.order-status-box span p {
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 0;
  margin-top: 10px;
  line-height: 22px;
}
@media screen and (max-width: 767px) {
  .order-status-box span p {
    font-size: 18px;
  }
}
.order-status-box .time {
  position: absolute;
  right: 20px;
  bottom: 10px;
  font-size: 14px;
  font-weight: 400;
  color: var(--theme-primary-color);
}
@media screen and (max-width: 767px) {
  .order-status-box .time {
    bottom: 0;
  }
}
.order-status-box.opacity-down {
  opacity: 0.4;
}

.remain-disposal {
  padding: 80px 20px;
  background-color: #ffffff;
}
@media screen and (max-width: 767px) {
  .remain-disposal {
    padding: 20px 20px;
  }
}

.r-d-wrp {
  flex: 1;
  flex-direction: column;
}
.r-d-wrp h3 {
  font-size: 18px;
}

.da-gabriele {
  border: 3px solid var(--theme-third-color);
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  padding: 17px 30px;
  text-align: center;
}
.da-gabriele h3 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}
@media screen and (max-width: 767px) {
  .da-gabriele h3 {
    font-size: 18px;
  }
}
.da-gabriele p {
  color: var(--theme-primary-color);
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 20px;
}
@media screen and (max-width: 767px) {
  .da-gabriele p {
    font-size: 16px;
  }
}
.da-gabriele .da-phone {
  font-size: 24px;
  font-weight: 500;
  color: var(--theme-primary-color);
}
@media screen and (max-width: 767px) {
  .da-gabriele .da-phone {
    font-size: 20px;
  }
}
.da-gabriele .da-phone span {
  height: 37px;
  width: 37px;
  background-color: var(--theme-third-color);
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  margin-right: 10px;
}
.da-gabriele .da-phone span svg {
  fill: var(--theme-primary-color);
}

.powered-by-hop {
  text-align: center;
  background-color: #F6F6F6;
  padding: 12px 20px;
}
.powered-by-hop span {
  color: #9B9B9B;
  font-size: 16px;
  font-weight: 500;
}
.powered-by-hop span svg {
  margin-left: 10px;
}

.oder-detail-page .page-content-wrp {
  margin-bottom: 0;
}

.a-o-s-wrp {
  flex: 1;
  flex-direction: column;
}
.a-o-s-wrp h3 {
  font-size: 16px;
  font-weight: 500;
}

.alert-order-status {
  background-color: var(--theme-third-color);
  padding: 40px 20px;
}

.alert-msg-box {
  max-width: 560px;
  background-color: #ffffff;
  -webkit-border-radius: 28px;
  -moz-border-radius: 28px;
  border-radius: 28px;
  text-align: center;
  padding: 30px;
  color: var(--theme-primary-color);
}
.alert-msg-box svg {
  fill: var(--theme-primary-color);
}
.alert-msg-box p {
  font-size: 22px;
  font-weight: 500;
  margin: 26px 0 20px;
}
@media screen and (max-width: 767px) {
  .alert-msg-box p {
    font-size: 18px;
  }
}
.alert-msg-box span {
  font-size: 14px;
  font-weight: 400;
}

.order-reason-section {
  background-color: #ffffff;
  padding: 40px 20px 20px;
  text-align: center;
  border-bottom: 2px solid var(--theme-third-color);
}

.o-r-wrp {
  flex: 1;
  flex-direction: column;
}

.reason-alert-box {
  border: 2px solid var(--theme-third-color);
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  border-radius: 18px;
  margin-bottom: 40px;
  padding: 15px 35px;
  max-width: 320px;
  text-align: center;
}
.reason-alert-box p {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  color: var(--theme-primary-color);
  line-height: 22px;
}

.reason-content {
  max-width: 600px;
}
.reason-content p {
  line-height: 24px;
  color: var(--theme-primary-color);
  font-size: 18px;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .reason-content p {
    font-size: 16px;
    line-height: 22px;
  }
}

.cancel-order-form ul {
  padding: 0;
  margin: 0;
}
.cancel-order-form li {
  margin-top: 10px;
  padding: 18px 10px;
  background-color: var(--theme-third-color);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  text-align: left;
  border: 2px solid transparent;
}
.cancel-order-form li:first-child {
  margin-top: 0;
}
.cancel-order-form li.active {
  border-color: var(--theme-primary-color);
}
.cancel-order-form li .check_lb {
  padding-left: 50px;
}
.cancel-order-form li .check_lb p {
  margin-bottom: 0;
  color: var(--theme-primary-color);
  font-size: 15px;
  font-weight: 500;
}
.cancel-order-form li .checkmark.sign_in_checkmak {
  height: 36px;
  width: 36px;
  margin-top: -18px;
}
.cancel-order-form li .checkmark.sign_in_checkmak::after {
  border-width: 0 3px 3px 0 !important;
  left: 12px !important;
  top: 6px !important;
  width: 12px !important;
  height: 20px !important;
}
.cancel-order-form li textarea {
  background-color: #ffffff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  margin-top: 10px;
  font-size: 12px;
  margin-left: 50px;
  max-width: 290px;
  padding: 10px 10px;
}
@media screen and (max-width: 767px) {
  .cancel-order-form li textarea {
    max-width: 210px;
  }
}
.cancel-order-form li textarea::-webkit-input-placeholder {
  color: #C4C4C4;
}
.cancel-order-form li textarea::-moz-placeholder {
  color: #C4C4C4;
}
.cancel-order-form li textarea::-ms-input-placeholder {
  color: #C4C4C4;
}

.cancel-order-popup h3 {
  font-size: 18px;
  text-align: center;
}
.cancel-order-popup .modal-body-wrp {
  margin-top: 50px;
}

.o-d-e-cart-popup .c-pro-tag {
  margin-top: 0;
}
.o-d-e-cart-popup .cart-product-list {
  margin-bottom: 30px;
}

.moa-popup .modal-body-wrp {
  text-align: center;
}
.moa-popup .popup-title h2 {
  font-size: 22px;
  font-weight: 700;
  margin-top: 15px;
  line-height: 30px;
}
@media screen and (max-width: 767px) {
  .moa-popup .popup-title h2 {
    font-size: 18px;
    line-height: normal;
  }
}
.moa-popup .popup-title h2 label {
  margin-bottom: 0;
  display: block;
}
.moa-popup .title-text {
  font-size: 16px;
  font-weight: 600;
  color: var(--theme-primary-color);
}

.new-user-signup-popup .modal-body-wrp {
  text-align: center;
  padding: 45px;
}
@media screen and (max-width: 767px) {
  .new-user-signup-popup .modal-body-wrp {
    padding: 25px;
  }
}
.new-user-signup-popup .modal-content {
  padding: 0;
}
.new-user-signup-popup .popup-title h2 {
  font-size: 22px;
  font-weight: 700;
  margin-top: 15px;
}
@media screen and (max-width: 767px) {
  .new-user-signup-popup .popup-title h2 {
    font-size: 18px;
    line-height: normal;
  }
}
.new-user-signup-popup .title-text {
  color: var(--theme-primary-color);
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
}
@media screen and (max-width: 767px) {
  .new-user-signup-popup .title-text {
    font-size: 14px;
  }
}

.powered-by-popup {
  text-align: center;
  border-top: 2px solid #F4F4F4;
  padding: 15px;
}
.powered-by-popup p {
  color: #000000;
  opacity: 0.3;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0;
}
.powered-by-popup p svg {
  margin-left: 5px;
}

.user-already-account-popup .primary-btn {
  width: 70%;
}
@media screen and (max-width: 767px) {
  .user-already-account-popup .primary-btn {
    width: 100%;
  }
}
.user-already-account-popup .powered-by-popup {
  text-align: left;
  padding: 25px 14px;
}
.user-already-account-popup .powered-by-popup p {
  font-weight: 400;
  font-size: 14px;
  color: var(--theme-primary-color);
  opacity: 1;
  line-height: 18px;
}
.user-already-account-popup .powered-by-popup p span {
  margin-right: 5px;
}
@media screen and (max-width: 767px) {
  .user-already-account-popup .powered-by-popup p span svg {
    height: 24px;
    width: 24px;
  }
}

.send-new-pass .title-text {
  margin-top: 30px;
  line-height: 24px;
}

.alert-msg {
  color: red;
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px;
  display: block;
}

.store-close span {
  margin-bottom: 15px;
  display: block;
}
.store-close h4 {
  color: #C1C1C1;
  font-size: 26px;
  margin-bottom: 0;
}
@media screen and (max-width: 767px) {
  .store-close h4 {
    font-size: 22px;
  }
}
.store-close .home-title {
  border-bottom: none;
}
.store-close .home-cont-mid {
  flex-direction: column;
}
.store-close .home-cont-mid h3 {
  color: #82CFFB;
  font-size: 18px;
  font-weight: 600;
  max-width: 280px;
  margin: 0 auto;
  margin-bottom: 20px;
}
@media screen and (max-width: 767px) {
  .store-close .home-cont-mid h3 {
    font-size: 16px;
  }
}

.command-not-available {
  padding: 0 20px 30px;
  border-bottom: 2px solid #F4F4F4;
}
.command-not-available p {
  background-color: #FFECEE;
  color: #F24E61;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
  padding: 10px 20px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

.stop_preorder {
  max-width: 313px;
  margin: 0 auto;
}
.stop_preorder p {
  font-weight: 600;
  font-size: 18px;
  color: #888888;
  margin-top: 30px;
  margin-bottom: 0;
  line-height: 1.4;
}

.stop_order_p {
  color: #888888;
  /* margin-top: 30px; */
  margin-bottom: 0;
  line-height: 1.4;
  font-weight: 600;
  font-size: 14px;
}

.p-i-img {
  position: relative;
}
.p-i-img:hover .agrandir-img-btn {
  opacity: 1;
  overflow: visible;
  bottom: 10px;
  transform: none;
}

.agrandir-img-btn {
  position: absolute;
  bottom: -20px;
  right: 10px;
  opacity: 0;
  overflow: hidden;
  transition: 0.3s;
}
.agrandir-img-btn .primary-btn {
  background-color: #ffffff;
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  padding: 6px 22px;
}
.agrandir-img-btn .primary-btn svg {
  fill: #000000;
}

.product-image-preview {
  max-width: auto;
}
.product-image-preview .modal-content {
  padding: 0;
  text-align: center;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.product-image-preview .modal-body-wrp {
  margin-top: 0;
}
.product-image-preview .modal-content .close.with-name span {
  background-color: var(--theme-primary-color);
}
.product-image-preview .modal-content .close.with-name span label {
  color: #ffffff;
}
.product-image-preview .modal-content .close.with-name span svg {
  fill: #ffffff;
}
.product-image-preview .p-item-preview-img img {
  width: 100%;
}
@media screen and (max-width: 767px) {
  .product-image-preview .p-item-preview-img {
    padding: 0 20px;
  }
}

.custom-text {
  max-width: 340px;
  margin: 0 auto;
  margin-bottom: 0;
  color: #6F6F6F;
  line-height: 22px;
}

.pay {
  text-align: center;
  font-size: 16px !important;
  font-weight: 600;
  margin-top: 22px;
  margin-bottom: 10px;
}

.table_text1 {
  background: var(--theme-third-color);
  color: var(--theme-primary-color);
  padding: 10px;
  border-radius: 12px;
  margin-bottom: 20px;
}

.rtl {
  direction: rtl;
}
.rtl .ml-auto, .rtl .mx-auto {
  margin-left: 0 !important;
  margin-right: auto !important;
}

.rtl .primary-btn.svg-left svg {
  margin-right: 0;
  margin-left: 10px;
}
@media only screen and (max-width: 767px) {
  .rtl .primary-btn.svg-left svg {
    margin-left: 5px;
  }
}
.rtl .primary-btn svg {
  margin-left: 0;
  margin-right: 10px;
}
@media only screen and (max-width: 767px) {
  .rtl .primary-btn svg {
    margin-right: 5px;
  }
}
.rtl .add-control .form-control-svg {
  right: auto;
  left: 26px;
}
.rtl .deli-add-bottom {
  text-align: right;
}
.rtl .date-dropdown.hour-dropdown {
  margin-left: 0;
  margin-right: 20px;
}
.rtl .open-t-b-w .open-heading {
  margin-left: 20px;
  margin-right: 10px;
}
@media only screen and (max-width: 767px) {
  .rtl .open-t-b-w .open-heading {
    margin-left: 10px;
  }
}
.rtl .email-btn .primary-btn span svg {
  margin: 0;
}
.rtl .email-btn .primary-btn span {
  margin-right: 0;
  margin-left: 10px;
}
@media only screen and (max-width: 767px) {
  .rtl .email-btn .primary-btn span {
    margin-left: 5px;
  }
}
.rtl .add-control .form-control {
  padding-left: 60px;
  padding-right: 20px;
}
.rtl .modal-content .close {
  right: auto;
  left: 30px;
}
.rtl .form-label {
  text-align: right;
}
.rtl .pass-show-icon {
  right: auto;
  left: 30px;
}
.rtl .forgot-pass {
  text-align: right;
}
.rtl .check_lb {
  padding-left: 0;
  padding-right: 100px;
  text-align: right;
}
@media only screen and (max-width: 1279px) {
  .rtl .check_lb {
    padding-right: 60px;
  }
}
.rtl .checkmark {
  left: auto;
  right: 0;
}
.rtl .r-o-total {
  text-align: left;
}
.rtl .r-oder-details, .rtl .o-c-d-left {
  text-align: right;
}
.rtl .modal-content .close.with-name span svg {
  margin-left: 0;
  margin-right: 10px;
}
.rtl .product-options-list {
  text-align: right;
}
.rtl .p-variant-list .check_lb {
  padding-right: 46px;
}
.rtl .product-item-popup .popup-footer-btn label {
  margin-left: 0;
  margin-right: 10px;
}

.rtl .delivery-options-list {
  margin-left: 0;
  margin-right: 88px;
}
@media only screen and (max-width: 1279px) {
  .rtl .delivery-options-list {
    margin-left: 20px;
    margin-right: 20px;
  }
}
@media only screen and (max-width: 567px) {
  .rtl .delivery-options-list {
    margin-left: 10px;
    margin-right: 10px;
  }
}
.rtl .menu-plus {
  border-right: none;
  padding-right: 0;
  margin-right: 0;
  border-left: 2px solid #F4F4F4;
  padding-left: 25px;
  margin-left: 25px;
}
@media only screen and (max-width: 767px) {
  .rtl .menu-plus {
    padding-left: 0;
    margin-left: 0;
    border-left: none;
  }
}
.rtl .menu-cart-btn .primary-btn span {
  border-left: none;
  border-right: 2px solid #DF0043;
}
@media only screen and (max-width: 767px) {
  .rtl .menu-cart-btn .primary-btn span {
    border: none;
  }
}
.rtl .menu-hori-main .nav-tabs {
  padding-right: 50px;
  padding-left: 0;
}
@media only screen and (max-width: 1279px) {
  .rtl .menu-hori-main .nav-tabs {
    padding: 13px 20px;
  }
}
.rtl .product-item-list h2 {
  text-align: right;
}
.rtl .product-img {
  padding-left: 0;
  padding-right: 15px;
}
.rtl .pro-tag-list {
  text-align: left;
  left: 4px;
  right: auto;
}
.rtl .product-content {
  text-align: right;
}
.rtl .footer-close {
  left: 20px;
  right: auto;
}
.rtl .deli-optiond-wrp li {
  border-right: 2px solid #F7F7F7;
  border-left: none;
}
.rtl .deli-optiond-wrp li:first-child {
  border-right: none;
}
.rtl .deli-list-dropdown {
  left: auto;
  right: 0;
}
.rtl .deli-optiond-wrp li svg {
  margin-right: 0;
  margin-left: 15px;
}
@media only screen and (max-width: 1279px) {
  .rtl .deli-optiond-wrp li svg {
    margin-left: 10px;
  }
}
@media only screen and (max-width: 1023px) {
  .rtl .deli-optiond-wrp li svg {
    margin-left: 0;
  }
}
.rtl .close {
  float: left;
}
.rtl .d-l-block {
  border-right: 2px solid #F2F2F2;
  border-left: none;
}
.rtl .d-l-block:first-child {
  border-right: none;
}
.rtl .deli-type-btn .primary-btn svg {
  margin-right: 10px;
  margin-left: 10px;
}
.rtl .add-control.shop-add-control .form-control.error {
  padding-right: 40px;
  padding-left: 60px;
}
.rtl .add-control.shop-add-control .form-control-svg.location {
  right: 20px;
  left: auto;
}
.rtl .add-con-dropdown-list h3, .rtl .block-title h3, .rtl .d-alert-msg p {
  text-align: right;
}
.rtl .shop-d-h .date-dropdown .d-dropdown svg {
  margin-right: auto;
  margin-left: 10px;
}
.rtl .menu-plus ul {
  right: auto;
  left: 20px;
}
.rtl .menu-plus ul li {
  text-align: right;
}
.rtl .c-drop-footer {
  right: auto;
}
.rtl .cart-dropdown {
  right: auto;
  left: -999px;
}
.rtl .cart-dropdown-open .cart-dropdown {
  left: 0;
}
.rtl .c-product-price {
  text-align: left;
}
.rtl .menu-cart-btn .primary-btn .count-pill {
  right: auto;
  left: 13px;
}
.rtl .c-pro-dec p {
  text-align: right;
}
.rtl .c-p-list-box .link-btn {
  display: block;
  text-align: right;
}
.rtl .c-drop-footer .primary-btn span {
  margin-right: 10px;
  margin-left: 0;
}

.rtl .login-btn .primary-btn.only-svg svg {
  margin-left: 0;
}
.rtl .t-b-l .primary-btn.second {
  margin-left: 0;
  margin-right: 10px;
}
.rtl .account-dropdown ul {
  text-align: right;
}
.rtl .account-dropdown li a svg {
  margin-right: 0;
  margin-left: 15px;
}
.rtl .home-content .login-btn {
  right: auto;
  left: 30px;
}
@media only screen and (max-width: 767px) {
  .rtl .home-content .login-btn {
    left: auto;
  }
}
.rtl .account-dropdown {
  right: auto;
  left: 0;
}
.rtl .shop-footer {
  left: auto;
  right: 0;
}
@media only screen and (max-width: 767px) {
  .rtl .shop-footer {
    left: 12px;
    right: auto;
  }
}
.rtl .p-t-with-btn .available-from {
  margin-left: 0;
  margin-right: 15px;
}
.rtl .pro-strike-price {
  margin-left: 10px;
  margin-right: 0;
}
.rtl .m-l-15 {
  margin-left: 0;
  margin-right: 15px;
}
.rtl .paymetn-means .another-card {
  right: auto;
  left: 12px;
}
.rtl .paymetn-means .deli-type-btn .primary-btn .btn-mark {
  margin-right: 0;
  margin-left: 20px;
}
@media only screen and (max-width: 767px) {
  .rtl .paymetn-means .deli-type-btn .primary-btn .btn-mark {
    margin-left: 10px;
  }
}
.rtl .c-type-delivery h4, .rtl .confirm-voter-add h4, .rtl .paymetn-means h3 {
  text-align: right;
}
.rtl .tooltip-svg {
  margin-left: 0;
  margin-right: 10px;
}
.rtl .use-wallet-text .u-w-p span {
  margin-left: 0;
  margin-right: 5px;
}
.rtl .promo-code .primary-btn {
  right: auto;
  left: 10px;
}
.rtl .c-drop-deli-btn .primary-btn span svg {
  margin: 0;
}
.rtl .product-tag span {
  margin-left: 0;
  margin-right: 5px;
}
.rtl .product-tag span:first-child {
  margin-right: 0;
}

.rtl .pro-name {
  margin-left: 0px;
  margin-right: 30px;
}
.rtl .pro-list {
  margin-right: 0;
  margin-left: 30px;
}
.rtl .pro-list ul {
  text-align: right;
}
.rtl .o-content {
  margin-left: 0;
  margin-right: 30px;
}
.rtl .o-name h4, .rtl .order-page-list li h5, .rtl .o-name, .rtl .pro-cont-detail h5 {
  text-align: right;
}
.rtl .pro-cont-detail .form-block label {
  display: block;
  text-align: right;
}
.rtl .pro-cont-detail .form-block .form-control {
  padding-right: 24px;
  padding-left: 50px;
}
.rtl .checkbox-block.right-icons {
  right: auto;
  left: 10px;
}
.rtl .checkbox-block {
  right: auto;
  left: 40px;
}
.rtl .form-no-input .form-block .form-control {
  padding: 10px 0px 10px 50px;
}